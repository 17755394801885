import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Typography, colors } from "@mui/material";
import PageHeader from "../../../components/PageHeader";
import Preloader from "../../../components/Preloader";
import BreadCrumbs from "../../../components/BreadCrumbs";
import Filter from "../../../components/Filter";
import UserLinkCard from "../../../components/UserLinkCard";
import StatusTag from "../../../components/StatusTag";
import Action from "../../../components/Action";
import { updateTotalAction } from "../../../redux/reducers/paginationControllerReducer";
import { translate } from "../../../translations/translate";
import { url as genURL } from "../../../utils/generalizeURL";
import useApiHook from "../../../hooks/useApiHook";
import noImage from "../../../assets/images/no-image.png";
import config from "../../../config";
import "./styles.sass";
import {setWarehouseStatus} from "../../../redux/actions/warehouseAction";
import TextArea from "../../../components/TextArea/TextArea";
import axios from "axios";
import { successToast } from "../../../utils/toast";

const PageBusinessView = ({ props }) => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { id } = useParams();
    const url = `${config.API_BASE_URL}/businesses/${state?.id || state?.business?.id || state?.business?._id || id
        }`;
    const lang = useSelector((state) => state.languageReducer.lang);
    const __ = (key) => translate(lang, key);
    const {role} = useSelector(state => state.authReducer);
    const isUser = role === "user";

    const [productsUpdating, setProductsUpdating] = useState(false);

    // BRANCHES LOGIC
    const branchUrl = `${config.API_BASE_URL}/branches?businessID=${state?.id || state?.business?.id || state?.business?._id || id}`;
    const [branchBaseUrl, setBranchBaseUrl] = useState(branchUrl);
    const { data: branchData } = useApiHook(branchBaseUrl);
    // Business data fetching
    const { data, loading, error } = useApiHook(url);
    const business = data?.data?.docs[0];
    const dealerFullName = business?.dealerID ?  business.dealerID.name.first + " " + business.dealerID.name.last : "";

    const dispatch = useDispatch();
    const { token } = useSelector((state) => state.authReducer);
    const controller = useSelector(
        (state) => state.paginationControllerReducer,
    );
    const branches = branchData?.data?.docs;

    useEffect(() => {
        if (branchData) {
            dispatch(updateTotalAction(parseInt(branchData?.data?.totalDocs) || 0));
        }
    }, [branchData]);

    useEffect(() => {
        setBranchBaseUrl(
            `${branchUrl}&page=${controller.page + 1}&limit=${controller.pageSize
            }`,
        );
    }, [controller.page, controller.pageSize]);

    const [searchInputValue, setSearchInputValue] = useState("");

    useEffect(() => {
        if (searchInputValue)
            setBranchBaseUrl(`${branchUrl}/search/branch?query=${searchInputValue}`);
        else setBranchBaseUrl(branchUrl);
    }, [searchInputValue]);

    useEffect(() => {
        const warehouseStatus = business?.warehouseSystem;
        dispatch(setWarehouseStatus(warehouseStatus));
    }, [data]);

    let branchList = [];

    branches?.map((item) => {
        let branchSingle = {
            name: item.name || "—",
            phone: item.phones || "—",
            responsiblePersonFirst:
                item.contacts?.responsiblePerson?.clientID?.displayName
                    ?.first ||
                item.contacts?.responsiblePerson?.clientID?.name?.first ||
                item.contacts?.responsiblePerson?.name?.first ||
                "—",
            responsiblePersonLast:
                item.contacts?.responsiblePerson?.clientID?.displayName?.last ||
                item.contacts?.responsiblePerson?.clientID?.name?.last ||
                item.contacts?.responsiblePerson?.name?.last ||
                "—",
            responsiblePersonAvatar:
                item.contacts?.responsiblePerson?.clientID?.avatar || "",
            managerFirst:
                item.contacts?.manager?.clientID?.displayName?.first ||
                item.contacts?.manager?.clientID?.name?.first ||
                item.contacts?.manager?.name?.first ||
                "—",
            managerLast:
                item.contacts?.manager?.clientID?.displayName?.last ||
                item.contacts?.manager?.clientID?.name?.last ||
                item.contacts?.manager?.name?.last ||
                "—",
            managerAvatar:
                item.contacts?.responsiblePerson?.clientID?.avatar || "",
            businessID: item.businessID?._id || "—",
            businessSlug: item.businessID?.slug || "",
            businessName: item.businessID?.name || "—",
            id: item._id,
            slug: item.slug || "",
            status: item.status,
        };
        branchList.push(branchSingle);
    });

    const columns = [
        {
            field: "branchName",
            headerName: __("Branch"),
            flex: 1,
            cellClassName: "mane-column--cell",
            renderCell: ({ row }) => {
                return (
                    <Box
                        display="flex"
                        justifyContent="flex-start"
                        onClick={() => {
                            navigate(`/branches/${row.slug || row.id}`, {
                                state: {
                                    branch: {
                                        id: row.id,
                                        slug: row.slug,
                                    },
                                },
                            });
                        }}
                        width={"100%"}
                    >
                        <Typography
                            fontFamily="Manrope, sans-serif"
                            fontWeight={600}
                            fontSize={14}
                            color={colors.common["black"]}
                            textOverflow={"ellipsis"}
                            overflow={"hidden"}
                            whiteSpace={"nowrap"}
                        >
                            {row.name}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: "branchPhone",
            headerName: __("Phone"),
            className: "table-phone",
            flex: 1,
            renderCell: ({ row: { phone } }) => {
                return (
                    <Box
                        display="flex"
                        justifyContent="flex-start"
                        width={"100%"}
                    >
                        <Typography
                            fontFamily="Manrope, sans-serif"
                            fontWeight={400}
                            fontSize={14}
                            color={colors.grey[500]}
                            textOverflow={"ellipsis"}
                            overflow={"hidden"}
                            whiteSpace={"nowrap"}
                        >
                            {phone.at(-1)}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: "responsiblePerson",
            headerName: __("Responsible Person"),
            flex: 1,
            cellClassName: "mane-column--cell",
            renderCell: ({ row }) => {
                return (
                    <Box display="flex" justifyContent="center">
                        <UserLinkCard
                            userLink="/clients/"
                            avatar={row.responsiblePersonAvatar}
                            name={`${row?.responsiblePersonFirst} ${row?.responsiblePersonLast}`}
                        />
                    </Box>
                );
            },
        },
        {
            field: "managerName",
            headerName: __("Manager"),
            flex: 1,
            renderCell: ({ row }) => {
                return (
                    <Box display="flex" justifyContent="center">
                        <UserLinkCard
                            userLink="../"
                            avatar={row?.managerAvatar}
                            name={`${row?.managerFirst} ${row?.managerLast}`}
                        />
                    </Box>
                );
            },
        },
        {
            field: "businessName",
            headerName: __("Business"),
            flex: 1,
            renderCell: ({ row }) => {
                return (
                    <Box
                        display="flex"
                        justifyContent="flex-start"
                        width={"100%"}
                    >
                        <Link
                            to={`/businesses/${row.businessSlug || row.businessID
                                }`}
                            state={{
                                business: {
                                    id: row.businessID,
                                    slug: row.businessSlug,
                                },
                            }}
                            className="link"
                        >
                            <Typography
                                fontFamily="Manrope, sans-serif"
                                fontWeight={400}
                                fontSize={14}
                                color={colors.common["black"]}
                                textOverflow={"ellipsis"}
                                overflow={"hidden"}
                                whiteSpace={"nowrap"}
                            >
                                {row.businessName}
                            </Typography>
                        </Link>
                    </Box>
                );
            },
        },
        {
            field: "status",
            headerName: __("Status"),
            type: "string",
            flex: 1,
            renderCell: (props) => {
                return (
                    <Box display="flex" justifyContent="center">
                        <StatusTag className={"sm"} text={props.value} />
                    </Box>
                );
            },
        },
        {
            field: "action",
            headerName: __("Action"),
            type: "string",
            flex: 0,
            renderCell: ({ row }) => {
                return (
                    <Box display="flex" justifyContent="center">
                        <Action
                            edit={false}
                            deleteAction={false}
                            onClick={() =>
                                navigate(
                                    `/branches/${row.slug || row.id}/edit`,
                                    {
                                        state: {
                                            branch: {
                                                id: row.id,
                                                slug: row.slug,
                                            },
                                        },
                                    },
                                )
                            }
                            onClickView={() =>
                                navigate(`/branches/${row.slug || row.id}`, {
                                    state: {
                                        branch: {
                                            id: row.id,
                                            slug: row.slug,
                                        },
                                        business: {
                                            id: state?.business?._id,
                                            slug: state?.business?.slug,
                                        },
                                    },
                                })
                            }
                        />
                    </Box>
                );
            },
        },
    ];

    if (loading)
        return (
            <div>
                <Preloader />
            </div>
        );

    if (error) return <div>{error}</div>;

    const buttons = [
        {
            name: "Back",
            link: "/businesses",
            state: {},
            className: "outline"
        },
        {
            name: "Edit",
            link: `/businesses/${state?.id || state?.business?.id || id}/edit`,
            state: {
                business: {
                    id: state?.id || state?.business?.id || id
                }
            }
        },
        {
            name: __("Add Branch"),
            link: "/branches/create",
            state:
            {
                business: {
                    slug: business?.slug,
                    _id: business?._id
                }
            }
        }
    ];

    return (
        <div>
            <BreadCrumbs
                breadcrumbs={[
                    { label: "Businesses", link: "/businesses" },
                    { label: business?.name },
                ]}
            />
            <div {...props} className="page component-page-business-view">
                <form className="page-inner">
                    <PageHeader
                        name={business?.name}
                        buttons={buttons}
                    />
                    <div className="cards">
                        <div className="left-side-cards">
                            {data?.data?.docs?.map((item, i) => (
                                <div key={i}>
                                    {/* Business Information */}
                                    <div className="component-box-card">
                                        <div className="card-header">
                                            <div className="card-info">
                                                <div className="card-icon">
                                                    <i className="icon-information" />
                                                </div>
                                                <div className="card-text">
                                                    <p className="card-title">
                                                        {__(
                                                            "Business Information",
                                                        )}
                                                    </p>
                                                    <p className="card-subtitle" />
                                                </div>
                                            </div>
                                        </div>
                                        {/* 1 */}
                                        <div className="input-column">
                                            <div className="card-avatar">
                                                <img
                                                    className="avatar-img"
                                                    src={business?.avatar?.fileInfo?.url
                                                        ? config.API_HOST + "/" + business?.avatar?.fileInfo?.url
                                                        : noImage}
                                                    alt="avatar-img"
                                                />
                                            </div>
                                            <div className="input-row">
                                                <div className="input-item">
                                                    {/* Business Name */}
                                                    <div className="component-input">
                                                        <label
                                                            className="input-label"
                                                        >
                                                            <input
                                                                defaultValue={item?.name}
                                                                className="input input-disabled"
                                                                type="text"
                                                                disabled
                                                            />
                                                            <span className="input-text">
                                                                {__("Business Name")}
                                                            </span>
                                                        </label>
                                                    </div>
                                                    {/* TIN */}
                                                    <div className="component-input">
                                                        <label
                                                            className="input-label"
                                                        >
                                                            <input
                                                                defaultValue={item?.tin}
                                                                className="input input-disabled"
                                                                type="text"
                                                                disabled
                                                            />
                                                            <span className="input-text">
                                                                {__("TIN / PINFL")}
                                                            </span>
                                                        </label>
                                                    </div>
                                                </div>
                                                {/* 2 */}
                                                <div className="input-item">
                                                    {/* Business Type */}
                                                    <div className="component-input">
                                                        <label
                                                            className="input-label"
                                                        >
                                                            <input
                                                                value={
                                                                    __(item?.businessType)
                                                                }
                                                                className="input input-disabled"
                                                                type="text"
                                                                disabled
                                                            />
                                                            <span className="input-text">
                                                                {__("Business Type")}
                                                            </span>
                                                        </label>
                                                    </div>
                                                    {/* Owner */}
                                                    <div className="component-input">
                                                        <label
                                                            className="input-label"
                                                        >
                                                            <input
                                                                defaultValue={item?.owner?.name?.first + " " + item?.owner?.name?.last}
                                                                className="input input-disabled"
                                                                type="text"
                                                                disabled
                                                            />
                                                            <span className="input-text">
                                                                {__("Owner")}
                                                            </span>
                                                        </label>
                                                    </div>
                                                </div>
                                                {/* 3 */}
                                                <div className="input-item">
                                                    {/* Phone */}
                                                    <div className="component-input">
                                                        <label
                                                            className="input-label"
                                                        >
                                                            <input
                                                                defaultValue={
                                                                    item?.phone
                                                                }
                                                                className="input input-disabled"
                                                                type="text"
                                                                disabled
                                                            />
                                                            <span className="input-text">
                                                                {__("Phone")}
                                                            </span>
                                                        </label>
                                                    </div>
                                                    {dealerFullName && (
                                                      <div className="component-input">
                                                          <label
                                                            className="input-label"
                                                          >
                                                              <input
                                                                value={dealerFullName}
                                                                className="input"
                                                                type="text"
                                                                disabled
                                                              />
                                                              <span className="input-text">
                                                        {__("Dealer")}
                                                    </span>
                                                          </label>
                                                      </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {business.documents
                                        && Array.isArray(business.documents)
                                        && business.documents.length > 0 &&
                                        // Documents
                                        <div className="component-box-card">
                                            <div className="card-header">
                                                <div className="card-info">
                                                    <div className="card-icon">
                                                        <i className="icon-information" />
                                                    </div>
                                                    <div className="card-text">
                                                        <p className="card-title">
                                                            {__(
                                                                "Documents",
                                                            )}
                                                        </p>
                                                        <p className="card-subtitle" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="input-item input-item-wrap">
                                                {
                                                    business.documents.map((document, index) => {
                                                        if (document.id)
                                                            return (

                                                                <div key={index} className="component-input">
                                                                    <div
                                                                        className="input-label"
                                                                    >
                                                                        <span className="input-text">
                                                                            {__(document?.documentType?.description) || "Custom document"}
                                                                        </span>
                                                                        <div className="input input-disabled input-flex">
                                                                            <div className="file">
                                                                                <img
                                                                                  src={`/fileExtensions/${document?.id?.fileInfo?.MIMEType.split("/")[1]}.svg`}
                                                                                  alt="" className="icon"
                                                                                  onError={(e) => e.target.src = "/fileExtensions/default.svg"} />
                                                                                <p className="file__text">{document?.id?.fileInfo?.originalName}</p>
                                                                            </div>
                                                                            {genURL(document?.id?.fileInfo?.url) &&
                                                                                <div className="action">
                                                                                    <a className="icon"
                                                                                        href={genURL(document?.id?.fileInfo?.url)}
                                                                                        target="_blank"
                                                                                        rel="noreferrer"
                                                                                    >
                                                                                        <i className="icon-view" />
                                                                                    </a>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                    })
                                                }
                                            </div>
                                        </div>
                                    }
                                    {/* Business Address */}
                                    <div className="component-box-card">
                                        <div className="card-header">
                                            <div className="card-info">
                                                <div className="card-icon">
                                                    <i className="icon-business-building-location" />
                                                </div>
                                                <div className="card-text">
                                                    <p className="card-title">
                                                        {__("Legal Address")}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ marginTop: 24 }}>
                                            <TextArea
                                                label={__("Address")}
                                                defaultValue={item.address?.legal?.region}
                                                disabled
                                            />
                                        </div>
                                    </div>

                                    {/* Branches Table */}
                                    <div className="page page-table-branch table branches-table">
                                        <Box>
                                            <Filter
                                                description={__("Branches")}
                                                filters={{
                                                    isBusiness: true,
                                                    isStatus: true,
                                                }}
                                                page={"branches"}
                                                search={searchInputValue}
                                                setSearch={setSearchInputValue}
                                                buttons={
                                                    state?.id
                                                        ? [
                                                            {
                                                                link: "/branches/create",
                                                                text: __("Add Branch"),
                                                                state: state?.id
                                                                    ? {
                                                                        business: {
                                                                            slug: state?.id,
                                                                            _id: state?.id,
                                                                        },
                                                                    }
                                                                    : null,
                                                            },
                                                        ]
                                                        : []
                                                }
                                            />
                                            <Box
                                                sx={{
                                                    "&": {
                                                        margin: "0",
                                                    },
                                                    "& .name-column--cell": {
                                                        color: colors.green[300],
                                                    },
                                                    "& .MuiDataGrid-footerContainer": {
                                                        borderTop: "none",
                                                    },
                                                }}
                                            >
                                                <DataGrid
                                                    rows={branchList}
                                                    rowCount={controller.total}
                                                    page={controller.page}
                                                    pageSize={controller.pageSize}
                                                    columns={columns}
                                                    {...config.TABLE_CONFIG}
                                                />
                                            </Box>
                                        </Box>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default PageBusinessView;
