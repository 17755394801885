import CustomInput from "../CustomInput";
import BasicDatePicker from "../DatePicker";
import SelectComponent from "../Select";
import {NumericFormat} from "react-number-format";
import "./styles.sass";
import {useSelector} from "react-redux";
import {translate} from "../../translations/translate";
import {useEffect} from "react";

const TableHeader = ({inputValues, branches, onChangeHandler, readOnly, transferPage}) => {
    // Lang selector
    const lang = useSelector((state) => state.languageReducer.lang);
    const __ = (key) => translate(lang, key);

    useEffect(() => {
        if (inputValues.branch && branches.length > 0) {
            const matchedBranch = branches.find((branch) => branch.label === inputValues.branch);

            if (matchedBranch) {
                onChangeHandler("branch", matchedBranch.value?._id);
            }
        }
    }, [inputValues.branch]);
    return (
      <div className="table-header">
          <CustomInput
            value={inputValues.invoice}
            label={__("Invoice Number")}
            disabled
          />
          {readOnly ? (
            <CustomInput
              value={inputValues.date}
              label={__("Date")}
              disabled
            />
          ) : (
            <label className="input-label">
                <span className="input-text date-picker">{__("Date")}</span>
                <BasicDatePicker
                  value={inputValues.date}
                  className="small"
                  onChange={(e) => onChangeHandler("date", e?.$d)}
                />
            </label>
          )}
          {!transferPage && (
            <NumericFormat
              customInput={CustomInput}
              value={inputValues.inn}
              label={__("Supplier's INN")}
              onChange={(e) => onChangeHandler("inn", e.target.value)}
              disabled={readOnly}
            />
          )}
          <CustomInput
            value={inputValues.resPerson}
            label={__("Responsible Person")}
            disabled
          />
          <CustomInput
            value={inputValues.totalCount}
            label={__("Total Number of Items")}
            disabled
          />
          {!transferPage && (
            <>
                {readOnly ? (
                  <CustomInput
                    value={inputValues.branch}
                    label={__("Branch")}
                    disabled
                  />
                ) : (
                  <label className="input-label">
                      <span className="input-text">{__("Branch")}</span>
                      <SelectComponent
                        value={branches?.find((branch) => inputValues.branch === branch.value?._id)}
                        onChange={(e) => {onChangeHandler("branch", e.value?._id);}}
                        options={branches}
                      />
                  </label>
                )}
            </>
          )}
          <NumericFormat
            label={__("Total Sum")}
            value={inputValues.totalSum}
            customInput={CustomInput}
            thousandSeparator=" "
            decimalSeparator="."
            decimalScale={2}
            fixedDecimalScale
            disabled
          />
      </div>
    );
};

export default TableHeader;
