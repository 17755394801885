import { translate } from "../../../translations/translate";
import { useSelector } from "react-redux";
import { NumericFormat } from "react-number-format";
import { useLocation, useParams } from "react-router";
import PageHeader from "../../../components/PageHeader";
import BreadCrumbs from "../../../components/BreadCrumbs";
import Preloader from "../../../components/Preloader";
import useApiHook from "../../../hooks/useApiHook";
import { getParsedDate } from "../../../utils/getParsedDate";
import { formatDate } from "../../../utils/formatDate";
import { capitalize } from "../../../utils/capitalize";
import config from "../../../config";
import "./styles.sass";
import { months } from "../../../utils/consts";
import FieldContainer from "../../../components/FieldContainer";
import StatusTag from "../../../components/StatusTag";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const PageCashdeskView = () => {
    const lang = useSelector((state) => state.languageReducer.lang);
    const __ = (key) => translate(lang, key);
    const navigate = useNavigate();

    const { state } = useLocation();
    const { id } = useParams();
    const url = `${config.API_BASE_URL}/cashdesks/${state?.cashdesk?.id || id}`;
    const { data, loading, error } = useApiHook(url);
    const paymentsUrl = `${config.API_BASE_URL}/cashdesks/payments/${state?.cashdesk?.id || id}`;
    const {data: payments} = useApiHook(paymentsUrl);
    const cashdesk = data?.data?.docs?.[0];

    useEffect(() => {
        const lastReloadedState = localStorage.getItem("lastReloadedState");

        if (lastReloadedState !== JSON.stringify(state)) {
            localStorage.setItem("lastReloadedState", JSON.stringify(state));
            window.location.reload();
        }
    }, [state]);

    const epsData = cashdesk?.EPS;

    const buttons = [
        {
            name: "Back",
            link: "/cashdesks",
            state: {},
            className: "outline",
        },
        {
            name: "Edit",
            link: `/cashdesks/${state?.cashdesk?.id || id}/edit`,
            state: {
                cashdesk: {
                    id: state?.cashdesk?.id || id,
                },
            },
            className: "",
        },
        {
            name: "EPS",
            link: `/cashdesks/${cashdesk?.uniqueID || cashdesk?._id || id}/eps`,
            state: {
                cashdesk: {
                    uniqueID: cashdesk?.uniqueID,
                    _id: cashdesk?._id,
                },
                eps: epsData,
            },
            className: "outline",
        },
    ];

    if (loading)
        return (
            <div>
                <Preloader />
            </div>
        );

    if (error) return <div>{error}</div>;

    return (
        <div>
            <BreadCrumbs
                breadcrumbs={[
                    { label: "Cashdesks", link: "/cashdesks" },
                    { label: "View" },
                ]}
            />
            <div className="page component-page-cashdesk-view">
                <form className="page-inner">
                    <PageHeader name={__("View Cashdesk")} buttons={buttons} />
                    <div className="cards">
                        <div className="left-side-cards">
                            <div className="component-box-card">
                                <div className="card-header">
                                    <div className="card-info">
                                        <div className="card-icon">
                                            <i className="icon-information" />
                                        </div>
                                        <div className="card-text">
                                            <p className="card-title">
                                                {__("Cashdesk Information")}
                                            </p>
                                            <p className="card-subtitle" />
                                        </div>
                                    </div>
                                </div>
                                <div className="input-item">
                                    <div className="component-input">
                                        <label
                                          className="input-label"
                                        >
                                            <input
                                              defaultValue={cashdesk?.fiscalModule?.fiscalID || "-"}
                                              className="input input-disabled"
                                              type="text"
                                              disabled
                                            />
                                            <span className="input-text">
                                                {__("Fiscal ID")}
                                            </span>
                                            {cashdesk?.fiscalModule?.fiscalID && (
                                                <button
                                                    className="input-label__delete-btn"
                                                    type="button"
                                                    onClick={() => navigate(`/fiscal/${cashdesk?.fiscalModule?._id}`)}
                                                >
                                                    <i className="icon-view"></i>
                                                </button>
                                            )}
                                        </label>
                                    </div>
                                    <div className="component-input">
                                        <label
                                          className="input-label"
                                        >
                                            <input
                                              value={cashdesk?.applicationType.length > 0 ?
                                                __(cashdesk?.applicationType[0]?.toUpperCase() + cashdesk?.applicationType.slice(1)) : "-"}
                                              className="input input-disabled"
                                              type="text"
                                              disabled
                                            />
                                            <span className="input-text">
                                                {__("Type")}
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                <div className="input-item">
                                    {/* uniqueID */}
                                    <div className="component-input">
                                        <label
                                          className="input-label"
                                        >
                                            <input
                                              defaultValue={cashdesk?.uniqueID}
                                              className="input input-disabled"
                                              type="text"
                                              disabled
                                            />
                                            <span className="input-text">
                                                {__("Cashdesk ID")}
                                            </span>
                                        </label>
                                    </div>
                                    <div className="component-input">
                                        <label
                                          className="input-label"
                                        >
                                            <input
                                              defaultValue={cashdesk?.fiscalModule?.virtualID || "-"}
                                              className="input input-disabled"
                                              type="text"
                                              disabled
                                            />
                                            <span className="input-text">
                                                {__("Virtual ID")}
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="component-box-card">
                                <div className="card-header">
                                    <div className="card-info">
                                        <div className="card-icon">
                                            <i className="icon-information" />
                                        </div>
                                        <div className="card-text">
                                            <p className="card-title">
                                                {__("Cashdesk License")}
                                            </p>
                                            <p className="card-subtitle" />
                                        </div>
                                    </div>
                                </div>
                                <div className="input-item">
                                    <div className="component-input">
                                        <label
                                          className="input-label"
                                        >
                                            <input
                                              defaultValue={cashdesk?.licence?.key || "-"}
                                              className="input input-disabled"
                                              type="text"
                                              disabled
                                            />
                                            <span className="input-text">
                                                {__("Key")}
                                            </span>
                                        </label>
                                    </div>
                                    <div className="component-input">
                                        <label
                                          className="input-label"
                                        >
                                            <input
                                              defaultValue={cashdesk?.licence?.expiresAt
                                                ? formatDate(cashdesk?.licence?.expiresAt)
                                                : "-"}
                                              className="input input-disabled"
                                              type="text"
                                              disabled
                                            />
                                            <span className="input-text">
                                                {__("Expires At")}
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                <div className="input-item">
                                    <div className="component-input">
                                        <label
                                          className="input-label"
                                        >
                                            <input
                                              defaultValue={cashdesk?.licence?.blockDate
                                                ? formatDate(cashdesk?.licence?.blockDate)
                                                : "-"}
                                              className="input input-disabled"
                                              type="text"
                                              disabled
                                            />
                                            <span className="input-text">
                                                {__("Block Date")}
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                <div className="input-item">
                                    <div className="component-input">
                                        <label
                                          className="input-label"
                                        >
                                            <input
                                              value={capitalize(cashdesk?.status)}
                                              className="input input-disabled"
                                              type="text"
                                              disabled
                                            />
                                            <span className="input-text">
                                                {__("Status")}
                                            </span>
                                        </label>
                                    </div>
                                    <div className="component-input">
                                        <label
                                          className="input-label"
                                        >
                                            <input
                                              value={cashdesk?.approved.isApproved ? __("Approved") : __("Not Approved")}
                                              className="input input-disabled"
                                              type="text"
                                              disabled
                                            />
                                            <span className="input-text">
                                                {__("Approved")}
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="component-box-card">
                                <div className="card-header">
                                    <div className="card-info">
                                        <div className="card-icon">
                                            <i className="icon-information" />
                                        </div>
                                        <div className="card-text">
                                            <p className="card-title">
                                                {__("Device Information")}
                                            </p>
                                            <p className="card-subtitle" />
                                        </div>
                                    </div>
                                </div>
                                <div className="input-item">
                                    <div className="component-input">
                                        <label
                                          className="input-label"
                                        >
                                            <input
                                              defaultValue={cashdesk?.deviceInfo?.OSVersion}
                                              className="input input-disabled"
                                              type="text"
                                              disabled
                                            />
                                            <span className="input-text">
                                                {__("OS Version")}
                                            </span>
                                        </label>
                                    </div>
                                    <div className="component-input">
                                        <label
                                          className="input-label"
                                        >
                                            <input
                                              defaultValue={cashdesk?.deviceInfo?.bitDepth}
                                              className="input input-disabled"
                                              type="Card"
                                              disabled
                                            />
                                            <span className="input-text">
                                                {__("Bit Depth")}
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                <div className="input-item">
                                    <div className="component-input">
                                        <label
                                          className="input-label"
                                        >
                                            <input
                                              defaultValue={cashdesk?.deviceInfo?.MACAddress}
                                              className="input input-disabled"
                                              type="text"
                                              disabled
                                            />
                                            <span className="input-text">
                                                {__("MAC Address")}
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className="component-box-card">
                                <div className="card-header">
                                    <div className="card-info">
                                        <div className="card-icon">
                                            <i className="icon-information" />
                                        </div>
                                        <div className="card-text">
                                            <p className="card-title">
                                                {__("Applet Information")}
                                            </p>
                                            <p className="card-subtitle" />
                                        </div>
                                    </div>
                                </div>
                                <div className="input-item">
                                    <div className="component-input">
                                        <label
                                          className="input-label"
                                        >
                                            <input
                                              defaultValue={cashdesk?.appletInfo?.appVersion}
                                              className="input input-disabled"
                                              type="text"
                                              disabled
                                            />
                                            <span className="input-text">
                                                {__("App Version")}
                                            </span>
                                        </label>
                                    </div>

                                </div>
                                <div className="input-item">
                                    <div className="component-input">
                                        <label
                                          className="input-label"
                                        >
                                            <input
                                              defaultValue={getParsedDate(
                                                cashdesk?.appletInfo
                                                  ?.versionCheckedAt,
                                              )}
                                              className="input input-disabled"
                                              type="text"
                                              disabled
                                            />
                                            <span className="input-text">
                                                {__("Version Checked At")}
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className="component-box-card">
                                <div className="card-header">
                                    <div className="card-info">
                                        <div className="card-icon">
                                            <i className="icon-information" />
                                        </div>
                                        <div className="card-text">
                                            <p className="card-title">
                                                {__("Fiscal Information")}
                                            </p>
                                            <p className="card-subtitle" />
                                        </div>
                                    </div>
                                </div>
                                <div className="input-item">
                                    <div className="component-input">
                                        <label
                                          className="input-label"
                                        >
                                            <input
                                              defaultValue={cashdesk?.businessID?.name}
                                              className="input input-disabled"
                                              type="text"
                                              disabled
                                            />
                                            <span className="input-text">
                                                {__("Business Name")}
                                            </span>
                                            {cashdesk?.businessID?._id && (
                                                <button
                                                    className="input-label__delete-btn"
                                                    type="button"
                                                    onClick={() => navigate(`/businesses/${cashdesk?.businessID?._id}`)}
                                                >
                                                    <i className="icon-view"></i>
                                                </button>
                                            )}
                                        </label>
                                    </div>
                                    <div className="component-input">
                                        <label
                                          className="input-label"
                                        >
                                            <input
                                              defaultValue={cashdesk?.branchID?.name}
                                              className="input input-disabled"
                                              type="Card"
                                              disabled
                                            />
                                            <span className="input-text">
                                                {__("Branch Name")}
                                            </span>

                                            {cashdesk?.branchID?._id && (
                                                <button
                                                    className="input-label__delete-btn"
                                                    type="button"
                                                    onClick={() =>
                                                        navigate(`/businesses/${cashdesk?.businessID?._id}/branches/${cashdesk?.branchID?._id}`,
                                                            {
                                                                state: {
                                                                    business: cashdesk.businessID,
                                                                    branch: cashdesk.branchID
                                                                },
                                                            },
                                                        )}
                                                >
                                                    <i className="icon-view"></i>
                                                </button>
                                            )}
                                        </label>
                                    </div>
                                </div>
                                <div className="input-item">
                                    <div className="component-input">
                                        <label
                                          className="input-label"
                                        >
                                            <input
                                              defaultValue={cashdesk?.fiscalModule?.fiscalID}
                                              className="input input-disabled"
                                              type="text"
                                              disabled
                                            />
                                            <span className="input-text">
                                                {__("Fiscal Module")}
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className="component-box-card">
                                <div className="card-header">
                                    <div className="card-info">
                                        <div className="card-icon">
                                            <i className="icon-information"></i>
                                        </div>
                                        <div className="card-text">
                                            <p className="card-title">
                                                {__("Payment Information")}
                                            </p>
                                            <p className="card-subtitle"></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-margin">
                                    <label className="input-label">
                                        <NumericFormat
                                          defaultValue={cashdesk?.purchasePrice / 100 || 0}
                                          className="input"
                                          thousandSeparator={" "}
                                          decimalSeparator={"."}
                                          inputMode={"numeric"}
                                          decimalScale={2}
                                          fixedDecimalScale={true}
                                          disabled
                                        />
                                        <span className="input-text">
                                            {__("Purchase Price")}
                                        </span>
                                    </label>
                                </div>
                                <div className="card-margin">
                                    <label className="input-label">
                                        <NumericFormat
                                          className={"input"}
                                          defaultValue={cashdesk.monthlyPayment / 100 || 0}
                                          thousandSeparator={" "}
                                          decimalSeparator={"."}
                                          inputMode={"numeric"}
                                          decimalScale={2}
                                          fixedDecimalScale={true}
                                          disabled
                                        />
                                        <span className="input-text">
                                            {__("Monthly Subscription Price")}
                                        </span>
                                    </label>
                                </div>

                                {/* Payments List */}
                                <div className="flex-wrap">
                                    {payments?.data?.map(({ isPaid, month, _id }) => (
                                      <FieldContainer
                                        key={_id}
                                        className="card-margin flex-three-col"
                                        title={months[month]}
                                      >
                                          <StatusTag text={isPaid ? "paid" : "not paid"} />
                                      </FieldContainer>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default PageCashdeskView;
