import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { translate } from "../../../translations/translate";
import PageHeader from "../../../components/PageHeader";
import useApiHook from "../../../hooks/useApiHook";
import config from "../../../config";
import Preloader from "../../../components/Preloader";
import BreadCrumbs from "../../../components/BreadCrumbs";
import "./styles.sass";
import { Box, Typography, colors } from "@mui/material";
import StatusTag from "../../../components/StatusTag";
import Action from "../../../components/Action";
import Filter from "../../../components/Filter";
import { DataGrid } from "@mui/x-data-grid";
import { updateTotalAction } from "../../../redux/reducers/paginationControllerReducer";
import { getParsedDate } from "../../../utils/getParsedDate";
import { url as genURL } from "../../../utils/generalizeURL";
import TextArea from "../../../components/TextArea/TextArea";

const PageBranchView = ({ ...props }) => {
    const { state } = useLocation();
    const { id } = useParams();
    const url = `${config.API_BASE_URL}/branches/${state?.branch?.id || state?.branch?._id || id}`;
    const { data, loading, error } = useApiHook(url);
    useEffect(() => {
        if (data?.data?.docs[0].name) {
            setCashdeskUrl(`${config.API_BASE_URL}/cashdesks?businessID=${data?.data?.docs[0]?.businessID._id}&branchID=${state?.branch?._id || state?.branch?.id || id}`);
        }
    }, [state]);
    const lang = useSelector((state) => state.languageReducer.lang);
    const __ = (key) => translate(lang, key);
    const branch = data?.data?.docs[0];

    const buttons = [
        {
            name: "Back",
            link: "/branches",
            state: {},
            className: "outline"
        },
        {
            name: "Edit",
            link: `/branches/${state?.branch?.id}/edit`,
            state: {
                branch: {
                    id: state?.branch?.id
                }
            },
            className: ""
        },
        {
            name: __("Add Cashdesk"),
            link: "/cashdesks/create",
            state: {
                business: {
                    slug: state?.business?.slug,
                    _id: branch?.businessID?._id,
                },
                branch: {
                    slug: branch?._id,
                    _id: branch?._id,
                }

            }
        }
    ];
    // CASHDESKS LOGIC
    const businessID = data?.data?.docs[0]?.businessID._id || state?.business?._id;
    let cashdeskUrlBase = `${config.API_BASE_URL}/cashdesks?${businessID ? `businessID=${businessID}&` : ""}branchID=${state?.branch?._id || state?.branch?.id || id}`;
    const [cashdeskUrl, setCashdeskUrl] = useState(cashdeskUrlBase);
    const { data: cashdeskData } = useApiHook(cashdeskUrl);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchInputValue, setSearchInputValue] = useState("");
    const controller = useSelector(
        (state) => state.paginationControllerReducer,
    );
    const cashdesks = cashdeskData?.data?.docs;
    useEffect(() => {
        dispatch(updateTotalAction(parseInt(cashdeskData?.data?.totalDocs) || 0));
    }, [cashdeskData]);

    useEffect(() => {
        setCashdeskUrl(
            `${cashdeskUrlBase}&page=${controller.page + 1}&limit=${controller.pageSize
            }`,
        );
    }, [controller.page, controller.pageSize]);
    useEffect(() => {
        if (searchInputValue)
            setCashdeskUrl(`${cashdeskUrlBase}/search/cashdesk?query=${searchInputValue}`);
        else setCashdeskUrl(cashdeskUrlBase);
    }, [searchInputValue]);

    let cashdesksList = [];

    cashdesks?.map((item) => {
        let cashdesk = {
            uniqueID: item.uniqueID || "—",
            branchID: item.branchID?._id || "—",
            branchSlug: item.branchID?.slug || "",
            branchName: item.branchID?.name || "—",
            businessID: item.businessID?._id,
            businessSlug: item.businessID?.slug || "",
            businessName: item.businessID?.name || "—",
            expiresAt: getParsedDate(item.licence?.expiresAt) || "—",
            approved: item.approved?.isApproved ? "Approved" : "Not approved",
            id: item._id,
            status: item.status || "—",
        };
        cashdesksList.push(cashdesk);
    });
    const columns = [
        {
            field: "uniqueID",
            headerName: __("Cashdesk ID"),
            flex: 1,
            cellClassName: "mane-column--cell",
            renderCell: ({ row }) => {
                return (
                    <Box
                        display="flex"
                        justifyContent="flex-start"
                        width={"100%"}
                    >
                        <Link
                            to={`/cashdesks/${row.id}`}
                            state={{
                                cashdesk: {
                                    id: row.id,
                                },
                            }}
                            className="link"
                        >
                            <Typography
                                fontFamily="Manrope, sans-serif"
                                fontWeight={600}
                                fontSize={14}
                                color={colors.common["black"]}
                                textOverflow={"ellipsis"}
                                overflow={"hidden"}
                                whiteSpace={"nowrap"}
                            >
                                {row.uniqueID}
                            </Typography>
                        </Link>
                    </Box>
                );
            },
        },
        {
            field: "branchName",
            headerName: __("Branch"),
            flex: 1,
            renderCell: ({ row }) => {
                return (
                    <Box
                        display="flex"
                        justifyContent="flex-start"
                        width={"100%"}
                        onClick={() => {
                            navigate(
                                `/branches/${row.branchID}`,
                                {
                                    state: {
                                        branch: {
                                            id: row.branchID,
                                            slug: row.branchSlug,
                                        },
                                    },
                                },
                            );
                        }}
                    >
                        <Typography
                            fontFamily="Manrope, sans-serif"
                            fontWeight={500}
                            fontSize={14}
                            color={colors.common["black"]}
                            textOverflow={"ellipsis"}
                            overflow={"hidden"}
                            whiteSpace={"nowrap"}
                        >
                            {row.branchName}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: "businessName",
            headerName: __("Business"),
            flex: 1,
            renderCell: ({ row }) => {
                return (
                    <Box
                        display="flex"
                        justifyContent="flex-start"
                        width={"100%"}
                        onClick={() => {
                            navigate(
                                `/businesses/${ row.businessID
                                }`,
                                {
                                    state: {
                                        business: {
                                            id: row.businessID,
                                            slug: row.businessSlug,
                                        },
                                    },
                                },
                            );
                        }}
                    >
                        <Typography
                            fontFamily="Manrope, sans-serif"
                            fontWeight={400}
                            fontSize={14}
                            color={colors.common["black"]}
                            textOverflow={"ellipsis"}
                            overflow={"hidden"}
                            whiteSpace={"nowrap"}
                        >
                            {row.businessName}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: "expiresAt",
            headerName: __("Expires"),
            flex: 1,
            renderCell: ({ row: { expiresAt } }) => {
                return (
                    <Box
                        display="flex"
                        justifyContent="flex-start"
                        width={"100%"}
                    >
                        <Typography
                            fontFamily="Manrope, sans-serif"
                            fontWeight={400}
                            fontSize={14}
                            color={colors.common["black"]}
                            textOverflow={"ellipsis"}
                            overflow={"hidden"}
                            whiteSpace={"nowrap"}
                        >
                            {expiresAt}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: "approved",
            headerName: __("Approved"),
            flex: 1,
            renderCell: ({ row: { approved } }) => {
                return (
                    <Box display="flex" justifyContent="flex-start">
                        <Typography
                            fontFamily="Manrope, sans-serif"
                            fontWeight={400}
                            fontSize={14}
                            color={colors.common["black"]}
                        >
                            {__(approved)}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: "status",
            headerName: __("Status"),
            type: "string",
            flex: 1,
            renderCell: (props) => {
                return (
                    <Box display="flex" justifyContent="center">
                        <StatusTag className={"sm"} text={props.value} />
                    </Box>
                );
            },
        },
        {
            field: "action",
            headerName: __("Action"),
            type: "string",
            renderCell: ({ row, ...props }) => {
                return (
                    <Box display="flex" justifyContent="center">
                        <Action
                            edit={false}
                            deleteAction={false}
                            onClick={() =>
                                navigate(
                                    `/cashdesks/${row.slug || row.id}/edit`,
                                    {
                                        state: {
                                            cashdesk: {
                                                id: row.id,
                                                slug: row.slug,
                                            },
                                        },
                                    },
                                )
                            }
                            onClickView={() =>
                                navigate(`/cashdesks/${row.slug || row.id}`, {
                                    state: {
                                        ...state,
                                        cashdesk: {
                                            id: row.id,
                                            slug: row.slug,
                                        },
                                    },
                                })
                            }
                            text={props.value}
                            viewLink={`/cashdesks/${props?.row?.uniqueID}`}
                        />
                    </Box>
                );
            },
        },
    ];

    if (loading)
        return (
            <div>
                <Preloader />
            </div>
        );
    if (error) return <div>{error}</div>;
    return (
        <div>
            <BreadCrumbs
                breadcrumbs={[
                    { label: "Branches", link: "/branches" },
                    { label: branch?.name },
                ]}
            />
            <div {...props} className="page component-page-branch-view">
                <form className="page-inner">
                    <PageHeader
                        name={__("View Branch")}
                        buttons={buttons}
                    />
                    <div className="cards">
                        <div className="left-side-cards">
                            {data?.data?.docs?.map((item, i) => (
                                <div key={i}>
                                    <div className="component-box-card">
                                        <div className="card-header">
                                            <div className="card-info">
                                                <div className="card-icon">
                                                    <i className="icon-information" />
                                                </div>
                                                <div className="card-text">
                                                    <p className="card-title">
                                                        {__(
                                                            "Branch Information",
                                                        )}
                                                    </p>
                                                    <p className="card-subtitle" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="input-item">
                                            <div className="component-input">
                                                <label
                                                    className="input-label"
                                                >
                                                    <input
                                                        defaultValue={item?.name}
                                                        className="input input-disabled"
                                                        type="text"
                                                        disabled
                                                    />
                                                    <span className="input-text">
                                                        {__("Branch Name")}
                                                    </span>
                                                </label>
                                            </div>
                                            <div className="component-input">
                                                <label
                                                    className="input-label"
                                                >
                                                    <input
                                                        defaultValue={
                                                            item?.phones
                                                        }
                                                        className="input input-disabled"
                                                        type="text"
                                                        disabled
                                                    />
                                                    <span className="input-text">
                                                        {__("Phone")}
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="input-item">
                                            <div className="component-input">
                                                <label
                                                    className="input-label"
                                                >
                                                    <input
                                                        defaultValue={item.businessID.name}
                                                        className="input input-disabled"
                                                        type="text"
                                                        disabled
                                                    />
                                                    <span className="input-text">
                                                        {__("Business")}
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    {item?.documents
                                        && Array.isArray(item?.documents)
                                        && item?.documents.length > 0 &&
                                        // Documents
                                        <div className="component-box-card">
                                            <div className="card-header">
                                                <div className="card-info">
                                                    <div className="card-icon">
                                                        <i className="icon-information" />
                                                    </div>
                                                    <div className="card-text">
                                                        <p className="card-title">
                                                            {__(
                                                                "Documents",
                                                            )}
                                                        </p>
                                                        <p className="card-subtitle" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="input-item input-item-wrap">
                                                {
                                                    item.documents.map((document, index) => {
                                                        if (document.id)
                                                            return (

                                                                <div key={index} className="component-input">
                                                                    <div
                                                                        className="input-label"
                                                                    >
                                                                        <span className="input-text">
                                                                            {__(document?.documentType?.description) || "Custom document"}
                                                                        </span>
                                                                        <div
                                                                            className="input input-disabled input-flex">
                                                                            <div className="file">
                                                                                <img src={
                                                                                    `/fileExtensions/${document?.id?.fileInfo?.MIMEType.split("/")[1]}.svg`
                                                                                } alt="" className="icon"
                                                                                    onError={(e) => e.target.src = "/fileExtensions/default.svg"} />
                                                                                <p className="file__text">{document?.id?.fileInfo?.originalName}</p>
                                                                            </div>
                                                                            {genURL(document?.id?.fileInfo?.url) &&
                                                                                <div className="action">
                                                                                    <a className="icon"
                                                                                        href={genURL(document?.id?.fileInfo?.url)}
                                                                                        target="_blank"
                                                                                        rel="noreferrer"
                                                                                    >
                                                                                        <i className="icon-view" />
                                                                                    </a>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                    })
                                                }
                                            </div>
                                        </div>
                                    }
                                    <div className="component-box-card">
                                        <div className="card-header">
                                            <div className="card-info">
                                                <div className="card-icon">
                                                    <i className="icon-business-building-location" />
                                                </div>
                                                <div className="card-text">
                                                    <p className="card-title">
                                                        {__("Branch Address")}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ marginTop: 24 }}>
                                            <TextArea
                                                label={__("Address")}
                                                defaultValue={
                                                    item?.address?.region
                                                }
                                                disabled
                                            />
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <div
                                className="page page-cashdesk-table cashdesks-table"
                            >
                                <Box>
                                    <Filter
                                        description={__("Cashdesks")}
                                        filters={{
                                            isCashdeskBranch: true,
                                            isBusiness: true,
                                            isExpiresAt: true,
                                            isStatus: true,
                                        }}
                                        page="cashdesks"
                                        search={searchInputValue}
                                        setSearch={setSearchInputValue}
                                        buttons={
                                            state?.business?._id && state?.branch?._id
                                                ? [
                                                    {
                                                        link: "/cashdesks/create",
                                                        text: __("Add Cashdesk"),
                                                        state:
                                                            state?.business && state?.branch
                                                                ? {
                                                                    business: {
                                                                        slug: state.business
                                                                            ?._id,
                                                                        _id: state?.business
                                                                            ?._id,
                                                                    },
                                                                    branch: {
                                                                        ...state.branch,
                                                                    },
                                                                }
                                                                : null,
                                                    },
                                                ]
                                                : []
                                        }
                                    />
                                    <Box
                                        sx={{
                                            "&": {
                                                margin: "0",
                                                height: "90vh",
                                                minHeight: "350px",
                                            },
                                            "& .name-column--cell": {
                                                color: colors.green[300],
                                            },
                                            "& .MuiDataGrid-footerContainer": {
                                                borderTop: "none",
                                            },
                                        }}
                                    >
                                        <DataGrid
                                            rows={cashdesksList}
                                            columns={columns}
                                            rowCount={controller.total}
                                            page={controller.page}
                                            pageSize={controller.pageSize}
                                            {...config.TABLE_CONFIG(__)}
                                        />
                                    </Box>
                                </Box>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default PageBranchView;
