import { SET_CLOSED, SET_OPEN } from "../actionTypes/sidebarActionTypes";

const initialState = {
    isOpen: true,
    businessIdSidebar: undefined,
    branchIdSidebar: undefined,
    cashdeskIdSidebar: undefined
};
const sidebarReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_OPEN:
            return {...state, isOpen: true};
        case SET_CLOSED:
            return {...state, isOpen: false};
        case "SET_BUSINESS_ID":
            return { ...state, businessIdSidebar: action.payload };
        case "SET_BRANCH_ID":
            return { ...state, branchIdSidebar: action.payload };
        case "SET_CASHDESK_ID":
            return { ...state, cashdeskIdSidebar: action.payload };
        default:
            return state;
    }
};
export default sidebarReducer;