import { useEffect, useState } from "react";
import Input from "../LayoutBasic/Input";
import { useSelector } from "react-redux";
import { translate } from "../../translations/translate";
import { stringWithoutSpaces } from "../../utils/removeStrSpaces";
import "./styles.sass";

const SearchBar = ({ setSearch, search, searchWithoutSpaces, isOpen: initialIsOpen = false, savedSearch }) => {
    const lang = useSelector((state) => state.languageReducer.lang);
    const __ = (key) => translate(lang, key);
    const [isOpen, setIsOpen] = useState(initialIsOpen);
    const [style, setStyle] = useState("");
    const [debounce, setDebounce] = useState(search);

    useEffect(() => {
        setIsOpen(initialIsOpen);
        setStyle(initialIsOpen ? "with_margin" : "");
    }, [initialIsOpen]);

    const onIconClick = () => {
        setIsOpen((prev) => !prev);
        setStyle((prev) => (prev === "with_margin" ? "" : "with_margin"));
        sessionStorage.removeItem(`searchValue_${window.location.pathname}`);
    };

    useEffect(() => {
        const handler = setTimeout(() => {
            setSearch(debounce);
        }, 300);
        return () => clearTimeout(handler);
    }, [debounce, setSearch]);

    useEffect(() => {
        setDebounce(savedSearch || search);
    }, [savedSearch, search]);


    return (
        <div className={`component-search ${style}`}>
            {!isOpen && (
                <div className="search-inner">
                    <div className="search-icon" onClick={onIconClick}>
                        <i className="icon-search" />
                    </div>
                </div>
            )}
            {isOpen && (
                <div className="search-inner">
                    <div className="search-icon">
                        <i className="icon-search" />
                    </div>
                    <Input
                        className="card-input sm card-input_search"
                        type="text"
                        placeholder={__("Search")}
                        icon="true"
                        relative="true"
                        onClick={onIconClick}
                        onChange={(e) => {
                            const value = searchWithoutSpaces ? stringWithoutSpaces(e.target.value) : e.target.value;
                            setDebounce(value);
                        }}
                        value={debounce}
                    />
                </div>
            )}
        </div>
    );
};

export default SearchBar;