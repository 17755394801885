import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useParams} from "react-router";

import {DataGrid, useGridApiRef} from "@mui/x-data-grid";
import {NumericFormat} from "react-number-format";
import {Box, colors, Typography} from "@mui/material";
import BreadCrumbs from "../../../components/BreadCrumbs";
import Preloader from "../../../components/Preloader";
import Filter from "../../../components/Filter";

import {updateTotalAction} from "../../../redux/reducers/paginationControllerReducer";
import {translate} from "../../../translations/translate";
import {getParsedDate} from "../../../utils/getParsedDate";
import useApiHook from "../../../hooks/useApiHook";
import config from "../../../config";
import { resetFilters } from "../../../redux/actions/filtersAction";

const breadCrumbsArray = [
    { label: "Transfers" },
];

const PageTransfersTable = () => {
    const dispatch = useDispatch();
    // Lang selector
    const lang = useSelector((state) => state.languageReducer.lang);
    // Pagination controller
    const controller = useSelector((state) => state.paginationControllerReducer);
    const filterState = useSelector((state) => state.filter);

    const { from, to } = filterState.expiresAt;
    const filterByDate = `${from ? `&fromDate=${from}` : ""}${to ? `&toDate=${to}` : ""}`;

    // state from react-router-dom
    const { state, pathname } = useLocation();
    const { businessID } = useParams();

    const baseUrl = `${config.API_BASE_URL}/businesses/${
        businessID || state?.business?._id}/invoice/get?operationType=Transfer`;

    // States
    const [url, setUrl] = useState(baseUrl);

    const { data, loading } = useApiHook(url);
    // functions / handlers
    const __ = (key) => translate(lang, key);

    const formatTransfersArr = (transfers) => {
        return transfers?.map((arrival) => ({
            ...arrival,
            id: arrival._id
        }));
    };

    // Effects
    useEffect(() => {
        setUrl(
            `${baseUrl}&page=${controller.page + 1}&limit=${controller.pageSize}${filterByDate}`
        );
    }, [controller.page, controller.pageSize]);

    useEffect(() => {
        if (data) {
            dispatch(updateTotalAction(parseInt(data?.data?.totalDocs) || 0));
        }
    }, [data]);

    useEffect(() => {
        setUrl(baseUrl + filterByDate);
    }, [filterByDate]);

    useEffect(() => {
        return () => {
            dispatch(resetFilters());
        };
    }, [pathname]);

    const productColumn = [
        {
            field: "invoiceNumber",
            headerName: __("Invoice Number"),
            flex: 1,
                          
            renderCell: ({row}) => {
                return (
                  <Typography
                    fontFamily="Manrope, sans-serif"
                    fontWeight={600}
                    fontSize={14}
                    color={colors.common["black"]}
                    textOverflow={"ellipsis"}
                    overflow={"hidden"}
                    whiteSpace={"nowrap"}
                  >
                      <Link
                        to={`/businesses/${state?.business?._id}/transfers/${row?.id}`}
                        state={{
                            business: {
                                id: state?.business._id || businessID,
                                slug: state?.business.slug
                            }
                        }}
                      >
                          {row?.invoiceNumber}
                      </Link>
                  </Typography>
                );
            }
        },
        {
            field: "recieptDate",
            headerName: __("Date"),
            flex: 1,
            renderCell: ({row}) => {
                return (
                  <Typography
                    fontFamily="Manrope, sans-serif"
                    fontWeight={400}
                    fontSize={14}
                    color={colors.common["black"]}
                    textOverflow={"ellipsis"}
                    overflow={"hidden"}
                    whiteSpace={"nowrap"}
                  >
                      {getParsedDate(row?.recieptDate)}
                  </Typography>
                );
            }
        },
        {
            field: "totalCount",
            headerName: __("Number of Items"),
            flex: 1,
            renderCell: ({row}) => {
                return (
                  <Typography
                    fontFamily="Manrope, sans-serif"
                    fontWeight={400}
                    fontSize={14}
                    color={colors.common["black"]}
                    textOverflow={"ellipsis"}
                    overflow={"hidden"}
                    whiteSpace={"nowrap"}
                  >
                      {row?.totalCount}
                  </Typography>
                );
            }
        },
        {
            field: "totalPrice",
            headerName: __("Total Sum"),
            flex: 1,
            renderCell: ({row}) => {
                return (
                  <Typography
                    fontFamily="Manrope, sans-serif"
                    fontWeight={400}
                    fontSize={14}
                    color={colors.common["black"]}
                    textOverflow={"ellipsis"}
                    overflow={"hidden"}
                    whiteSpace={"nowrap"}
                  >
                      <NumericFormat
                        displayType="text"
                        value={row?.totalPrice}
                        thousandSeparator={" "}
                        decimalSeparator={","}
                        decimalScale={2}
                        fixedDecimalScale
                      />
                  </Typography>
                );
            }
        },
        {
            field: "fromBranchID",
            headerName: __("Source Branch"),
            valueGetter: (params) => params.row.toBranchID?.name || "", // Extract branch name
            flex: 1,
            renderCell: ({row}) => {
                return (
                  <Typography
                    fontFamily="Manrope, sans-serif"
                    fontWeight={400}
                    fontSize={14}
                    color={colors.common["black"]}
                    textOverflow={"ellipsis"}
                    overflow={"hidden"}
                    whiteSpace={"nowrap"}
                  >
                      {row?.fromBranchID?.name || __("Warehouse")}
                  </Typography>
                );
            }
        },
        {
            field: "toBranchID",
            headerName: __("Receiver Branch"),
            valueGetter: (params) => params.row.toBranchID?.name || "", // Extract branch name
            flex: 1,
            renderCell: ({row}) => {
                return (
                  <Typography
                    fontFamily="Manrope, sans-serif"
                    fontWeight={400}
                    fontSize={14}
                    color={colors.common["black"]}
                    textOverflow={"ellipsis"}
                    overflow={"hidden"}
                    whiteSpace={"nowrap"}
                  >
                      {row?.toBranchID?.name}
                  </Typography>
                );
            }
        },
        {
            field: "responsiblePerson",
            headerName: __("Responsible Person"),
            flex: 1,
            renderCell: ({row}) => {
                return (
                  <Typography
                    fontFamily="Manrope, sans-serif"
                    fontWeight={400}
                    fontSize={14}
                    color={colors.common["black"]}
                    textOverflow={"ellipsis"}
                    overflow={"hidden"}
                    whiteSpace={"nowrap"}
                  >
                      {row?.responsiblePerson}
                  </Typography>
                );
            }
        }
    ];
    const tableRow = formatTransfersArr(data?.data?.docs) || [];

    const apiRef = useGridApiRef();

    const storageKey = `datagrid_filters_${pathname}`;
    const columnVisibilityKey = `datagrid_visible_columns_${pathname}`;
    const requiredColumns = ["title", "action"]; // Columns that must always be visible

    const [filterModel, setFilterModel] = useState(() => {
        const savedFilters = sessionStorage.getItem(storageKey);
        return savedFilters ? JSON.parse(savedFilters) : { items: [] };
    });

    const [columnVisibilityModel, setColumnVisibilityModel] = useState(() => {
        const savedColumns = sessionStorage.getItem(columnVisibilityKey);
        return savedColumns ? JSON.parse(savedColumns) : {};  // Default to all columns visible
    });

    useEffect(() => {
        sessionStorage.setItem(storageKey, JSON.stringify(filterModel));
    }, [filterModel]);


    const handleColumnVisibilityChange = (newModel) => {
        requiredColumns.forEach((col) => {
            newModel[col] = true;
        });

        setColumnVisibilityModel((prevModel) => {
            const updatedModel = { ...prevModel, ...newModel };
            sessionStorage.setItem(columnVisibilityKey, JSON.stringify(updatedModel));
            return updatedModel;
        });
    };

    if (loading) return <Preloader />;

    return (
      <div className="page-withdrawal-table">
          <BreadCrumbs breadcrumbs={breadCrumbsArray}/>
          <div className="page-table-branch">
              <Box>
                  <Filter
                    description={__("Transfers")}
                    createLink="create"
                    text={__("Transfer")}
                    state={state}
                    filters={{isExpiresAt: true, dateHeading: "Date"}}
                    // page={page}
                    // search={searchInputValue}
                    // setSearch={setSearchInputValue}
                  />
                  <Box
                    sx={{
                        "&": {
                            margin: "0",
                            width: "100%"
                        },
                        "& .name-column--cell": {
                            color: colors.green[300],
                        },
                        "& .MuiDataGrid-footerContainer": {
                            borderTop: "none",
                        },
                    }}
                  >
                      <DataGrid
                        rows={tableRow}
                        rowCount={controller.total}
                        page={controller.page}
                        pageSize={controller.pageSize}
                        columns={productColumn}
                        {...config.TABLE_CONFIG(__)}
                        apiRef={apiRef}
                        filterModel={filterModel}
                        onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
                        columnVisibilityModel={columnVisibilityModel}
                        onColumnVisibilityModelChange={handleColumnVisibilityChange}
                        componentsProps={{
                            columnsPanel: {
                                sx: {
                                    "& .MuiDataGrid-panelFooter button:first-child":{
                                        display: "none"
                                    },
                                    "& .MuiDataGrid-panelFooter button:last-child": {
                                        display: "none"
                                    }
                                }
                            }
                        }}
                      />
                  </Box>
              </Box>
          </div>
      </div>
    );
};

export default PageTransfersTable;
