export const statusesArr = {
  active: "green",
  free: "grey",
  completed: "cyan ",
  blocked: "red",
  deleted: "red",
  expired: "red",
  defective: "red",
  "in progress": "yellow",
  reserved: "yellow",
  resolved: "blue",
  paid: "green",
  "not paid": "red",
};

export const getStatusClass = (status) => {
  return statusesArr[status] || "grey";
};
