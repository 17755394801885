
// REACT PACKAGES
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";

// UTILS
import { translate } from "../../../translations/translate";
import config from "../../../config";
import useApiHook from "../../../hooks/useApiHook";
import getStaffRoles from "../../../utils/globalAPIRequests/getStaffRoles";
import { ApiService } from "../../../utils/ApiService";
import { successToast } from "../../../utils/toast";

// COMPONENTS
import Button from "../../../components/global/Button";
import PageHeader from "../../../components/PageHeader";
import BreadCrumbs from "../../../components/BreadCrumbs";
import UserLinkCard from "../../../components/UserLinkCard";
import SelectComponent from "../../../components/Select";

// STYLES
import "./styles.sass";

const PageStaffEdit = () => {

    // CALLBACK FUNCTIONS
    const onSubmit = function(e) {
        e.preventDefault();
        const body = {
            id: staff?.clientID,
            title: roles.find(role => role?.value === staff?.role).label,
        };
        let url = `/businesses/${state?.business?.id}`;
        if(state?.branch?.slug || state?.branch?.id) {
            url += `/branches/${state?.branch?.id}`;
        }
        if(state?.cashdesk?.slug || state?.cashdesk?.id) {
            url += `/cashdesks/${state?.cashdesk?.id}`;
        }
        url += "/staff/update";
        ApiService.patchEvent(url, token, body)
        .then(
            (response) => {
                if (response.status === 200) {
                    successToast(__("Successfully updated"));
                }
            }
        )
        .catch(response => {
            if(response.status === 400) {
                successToast(__("Successfully updated"));
                navigate(`/businesses/${state?.business?.slug || state?.business?.id}/staff`, {
                    state: {
                        business: {
                            id: state?.business?.id,
                            slug: state?.business?.slug,
                        },
                        branch: {
                            id: state?.branch?._id,
                            slug: state?.branch?.slug,
                        },
                        cashdesk: {
                            id: state?.cashdesk?._id,
                            slug: state?.cashdesk?.slug,
                        },
                    },
                });
            }
        });
        // .catch((error) => {
        //     errorToast(error.message);
        // });
    };

    // HOOKS

    // USE LOCATION
    const { state } = useLocation();

    // USE NAVIGATE
    const navigate = useNavigate();

    // GET ROLES
    let roles = getStaffRoles(state?.business?.id);
    useEffect(() => {
        roles && roles.forEach(role => role.label = __(role.label));
    }, [roles]);


    // USE STATE
    const [staff, setStaff] = useState({
        clientID: null,
        role: null,
        firstName: null,
        lastName: null,
    });

    // USE SELECTOR
    const { token } = useSelector((state) => state.authReducer);

    // LANGUAGE
    const lang = useSelector((state) => state.languageReducer.lang);
    const __ = (key) => translate(lang, key);

    // API REQUESTS

    // GET STAFF
    const staffUrl = `${config.API_BASE_URL}/businesses/${state?.business?.id}/staff`;
    const {data: staffData, loading: staffLoading, error: staffError} = useApiHook(staffUrl);
    const currentStaff = staffData?.data?.find(staff => staff._id === state?.staff?.id);
    useEffect(() => {
        if(currentStaff) {
            setStaff({
                clientID: currentStaff.id._id,
                role: currentStaff.role,
                firstName: currentStaff?.id?.name?.first,
                lastName: currentStaff?.id?.name?.last,
            });
        }
    }, [currentStaff]);

    // GET BUSINESS
    const businessUrl = `${config.API_BASE_URL}/businesses/${state?.business?.slug || state?.business?.id}`;
    const {data: businessData, loading: businessLoading, error: businessError} = useApiHook(businessUrl);

    // GET BRANCH
    const branchUrl = `${config.API_BASE_URL}/branches/${state?.branch?.slug || state?.branch?.id}`;
    const {data: branchData, loading: branchLoading, error: branchError} = useApiHook(branchUrl);

    // GET CASHDESK
    const cashdeskUrl = `${config.API_BASE_URL}/cashdesks/${state?.cashdesk?.slug || state?.cashdesk?.id}`;
    const {data: cashdeskData, loading: cashdeskLoading, error: cashdeskError} = useApiHook(cashdeskUrl);

    // HEADER BUTTONS
    const buttons = [
        {
            name: "Back",
            link: `/businesses/${state?.business?.slug || state?.business?.id}/staff`,
            state: {
                business: {
                    id: state?.business?.id,
                    slug: state?.business?.slug,
                },
                branch: {
                    id: state?.branch?._id,
                    slug: state?.branch?.slug,
                },
                cashdesk: {
                    id: state?.cashdesk?.uniqueID,
                    slug: state?.cashdesk?.slug,
                },
            },
            className: "outline"
        }
    ];


    // LAYOUT
    return (
        <div>
            <BreadCrumbs
                breadcrumbs={[
                    {label: "Businesses", link: "/businesses"},
                    {label: businessData?.data?.docs[0].name, link: `/businesses/${state?.business?.slug || state?.business?.id}`},
                    { label: "Staff", link: `/businesses/${state?.business?.slug || state?.business?.id}/staff` },
                    { label: "Edit" },
                ]}
            />

            <div className="page component-page-staff-edit">
                <form onSubmit={onSubmit} className="page-inner">
                    <PageHeader
                        name={__("Edit Staff Member")}
                        buttons={buttons}
                    />
                    <div className="cards">
                        <div className="left-side-cards">
                            <div className="component-box-card">
                                <div className="card-header">
                                    <div className="card-info">
                                        <div className="card-icon">
                                            <i className="icon-information" />
                                        </div>
                                        <div className="card-text">
                                            <p className="card-title">
                                                {__("Business Information")}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="input-column">
                                    <div className="input-row">
                                        <div className="input-item">
                                            <div className="component-input smart-search-container">
                                                <label
                                                    className="input-label"
                                                >
                                                    <input
                                                        className="input"
                                                        type="text"
                                                        disabled
                                                    />
                                                    <span className="input-text">
                                                        {__("Client Search")}
                                                    </span>
                                                </label>
                                            </div>
                                            <div className="component-input">
                                                <label className="input-label">
                                                    <UserLinkCard
                                                        avatar=""
                                                        name={`${staff?.firstName} ${staff?.lastName}`}
                                                        classNames={"input input-avatar"}
                                                    />
                                                    <span className="input-text top-aligned">
                                                        {__("Client")}
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="input-item">
                                            {
                                                (state?.business?.slug || state?.business?.id) && (
                                            <div className="component-input">
                                                <label
                                                    className="input-label"
                                                >
                                                    <input
                                                        value={businessData?.data?.docs[0].name || ""}
                                                        className="input"
                                                        type="text"
                                                        disabled
                                                    />
                                                    <span className="input-text">
                                                        {__("Business Name")}
                                                    </span>
                                                </label>
                                            </div>
                                                )
                                            }
                                            {
                                                (state?.branch?.slug || state?.branch?.id) && (
                                            <div className="component-input">
                                                <label
                                                    className="input-label"
                                                >
                                                    <input
                                                        value={branchData?.data?.docs[0]?.name}
                                                        className="input"
                                                        type="text"
                                                        disabled
                                                    />
                                                    <span className="input-text">
                                                        {__("Branch Name")}
                                                    </span>
                                                </label>
                                            </div>
                                                )
                                            }
                                        </div>
                                        <div className="input-item">
                                            <div className="component-input">
                                                <label
                                                    className="input-label"
                                                >
                                                    <span className="input-text">
                                                        {__("Role")}
                                                    </span>
                                                    <SelectComponent
                                                        defaultValue={roles?.find(role => role?.value === currentStaff?.role)?.label}
                                                        options={roles}
                                                        onChange={(e) =>
                                                            setStaff({
                                                                ...staff,
                                                                role: e.value,
                                                            })
                                                        }
                                                    />
                                                </label>
                                            </div>
                                            {
                                                (state?.cashdesk?.slug || state?.cashdesk?.id) && (
                                            <div className="component-input">
                                                <label
                                                    className="input-label"
                                                >
                                                    <input
                                                        value={cashdeskData?.data?.docs[0].uniqueID}
                                                        className="input"
                                                        type="text"
                                                        disabled
                                                    />
                                                    <span className="input-text">
                                                        {__("Cashdesk ID")}
                                                    </span>

                                                </label>
                                            </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="component box-save">
                        <div className="not-save-item">
                            <div className="not-save-icon">
                                <i className="icon-unsaved"></i>
                            </div>
                            <div className="not-save-text">
                                <p className="save-text">
                                    {__("Not Saved Yet")}
                                </p>
                            </div>
                        </div>
                        <div className="not-save-button">
                            <Button
                                className="btn outline sm"
                                text={__("Cancel")}
                                type="button"
                            />
                            <Button
                                className="btn sm"
                                text={__("Save")}
                                type="submit"
                            />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default PageStaffEdit;
