import React from "react";
import { capitalize } from "../../utils/capitalize";
import { translate } from "../../translations/translate";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import "../../assets/fonts/epos-icons/style.css";
import "./styles.sass";
import { getStatusClass } from "../../utils/statuses";

const StatusTag = (props) => {
    const lang = useSelector((state) => state.languageReducer.lang);
    const __ = (key) => translate(lang, key);
    const status = props?.text?.toLowerCase();
    const statusClass = getStatusClass(status);

    return (
        <div className="component-ui-status">
            <div className={`ui-status ${props.className} ${statusClass}`}>
                {/* <div><i className={props.iconClass}></i></div> */}
                {__(capitalize(props?.text))}
            </div>
        </div>
    );
};
StatusTag.propTypes = {
    text: PropTypes.string,
    iconClass: PropTypes.string,
    className: PropTypes.string,
};

export default StatusTag;
