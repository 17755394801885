import { RU } from "./ru";
import { UZ } from "./uz";

export const dictionary = {
    ru: RU,
    uz: UZ,
};

export const translate = (lang, key) => {
    if (lang === "en") return key;
    else {
        if (dictionary[lang])
            if(dictionary[lang][key])
                return dictionary[lang][key];
            else{
                // console.warn(`An error occured while trying to translate key "${key}"`);
                return key;
            }

        else {
            console.error(
                `An error occured while trying to translate using language "${lang}"`,
            );
            return key;
        }
    }
};

export const t = (key, lang) => {
    if (lang === "en") return key;

    return dictionary[lang][key];
};
