import "./styles.sass";

const FieldContainer = ({title, className, children}) => {
  return (
      <div className={`field-container ${className}`}>
        <div className="field-content">
          {children}
        </div>
        <span className="field-title">
          {title}
        </span>
      </div>
  );
};

export default FieldContainer;
