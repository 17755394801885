import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "../global/Button";
import Modal from "react-modal";
import ModalWindow from "../ModalWindow";
import config from "../../config";
import { ApiService } from "../../utils/ApiService";
import { errorToast, successToast } from "../../utils/toast";
import {ReactComponent as PlusIcon} from "../../assets/icons/avatar-plus.svg";
import "./styles.sass";
import { translate } from "../../translations/translate";

const UIAvatar = ({ url, onClick, fileId, onRemoveSuccess }) => {
    const {token} = useSelector((state) => state.authReducer);
    const lang = useSelector((state) => state.languageReducer.lang);
    const __ = (key) => translate(lang, key);

    const [modalIsOpen, setModalIsOpen] = useState(false);

    const onCloseModal = () => {
        setModalIsOpen(false);
    };

    const removePicture = () => {
        if(fileId) {
            ApiService.deleteEvent(`/uploads/${fileId}`, token)
                .then((res) => {
                    if (res.status === 200) {
                        successToast(__("Picture successfully deleted"));
                        onRemoveSuccess();
                    }
                })
                .catch((error) => {
                    errorToast(error);
                }).finally(() => onCloseModal());
        }
    };

    return (
        <div className="avatar-block">
            {!url ? (
                <Button type='button'>
                    <PlusIcon onClick={onClick} />
                </Button>
            ): (
                <>
                    <div className="img-container">
                        <img className="img" src={`${config.API_HOST}/${url}`} alt="" />
                    </div>
                     <Button
                         type='button'
                         className='add-avatar-btn'
                         wrapperClassname='add-avatar-btn-wrapper'
                         onClick={onClick}
                     >
                        <PlusIcon onClick={onClick} />
                    </Button>
                    <Button
                        className='remove-avatar-btn'
                        onClick={() => setModalIsOpen(true)}
                        type='button'
                    >
                        <i className="icon-delete"></i>
                    </Button>
                </>
            )
            }

            <Modal
                className="delete-modal"
                isOpen={modalIsOpen}
                onRequestClose={onCloseModal}
            >
                <div className="modal-bottom">
                    <ModalWindow
                        title="Are you sure?"
                        text="Are you sure you want to permanently delete this picture?"
                        onClick={onCloseModal}
                        onClickDelete={removePicture}
                    />
                </div>
            </Modal>
        </div>
    );
};

export default UIAvatar;
