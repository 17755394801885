import React, {useEffect, useState} from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import {translate} from "../../translations/translate";

const SelectComponent = ({ placeholder, options, value, disabled, ...otherProps }) => {
    const lang = useSelector((state) => state.languageReducer.lang);
    const __ = (key) => translate(lang, key);
    const {theme} = useSelector((state) => state.theme);
    const [translatedValue, setTranslatedValue] = useState(null);
    const [translatedOptions, setTranslatedOptions] = useState([]);
    let colourStyles;

    if(theme === "dark") {
        colourStyles = {
            control: (styles, state) => ({
                ...styles,
                backgroundColor: "#1E1F23",
                border: "2px solid #292929 !important",
                color: "#E1E1E1 !important",
                height: 44,
                boxShadow: "0 !important",
                "&:hover": {
                    borderColor: state.isFocused ? "#388392" : "#292929"
                 }
            }),
            option: (styles, { isFocused }) => {
              return {
                ...styles,
                backgroundColor: isFocused ? "#292929" : "#1E1F23",
                color: "#E1E1E1"
              };
            },
          };
    } else {
        colourStyles = {
            control: (styles) => ({
                ...styles,
                height: 44,
                border: "2px solid #E3E3E3 !important",
                boxShadow: "0 !important",
            }),
            placeholder: (styles, {isDisabled}) => ({
                ...styles,
                color: isDisabled ? "#000" : styles.color,
                fontSize: 14
            }),
        };
    }

    useEffect(() => {
        if (value) {
            setTranslatedValue({
                ...value,
                label: __(value?.label)
            });
        }

        setTranslatedOptions(options?.map(option => {
            return {
              label: __(option?.label),
              value: option?.value
            };
        }));
    }, [value, options, lang]);

    return (
        <Select
            value={translatedValue}
            options={translatedOptions}
            styles={colourStyles}
            placeholder={__(placeholder) || __("Select")}
            isDisabled={disabled}
            {...otherProps}
        />
    );
};

export default SelectComponent;
