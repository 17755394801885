import { combineReducers, legacy_createStore as createStore, applyMiddleware, compose } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import authReducer from "../reducers/authReducer";
import { languageReducer } from "../reducers/languageReducer";
import createUserReducer from "../reducers/createUserReducer";
import themeReducer from "../reducers/themeReducer";
import warehouseReducer from "../reducers/warehouseReducer";
import { paginationControllerReducer } from "../reducers/paginationControllerReducer";
import { filtersReducer } from "../reducers/filtersReducer";
import sidebarReducer from "../reducers/sidebarReducer";
import {dealerReducer} from "../reducers/dealerReducer";

const reducer = combineReducers({
    paginationControllerReducer,
    createUserReducer,
    authReducer,
    theme: themeReducer,
    languageReducer,
    filter: filtersReducer,
    sidebar: sidebarReducer,
    dealer: dealerReducer,
    warehouse: warehouseReducer,
});

const persistConfig = {
    key: "root",
    storage,
    whitelist: ["authReducer", "theme", "sidebar", "warehouse"],
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistedReducer = persistReducer(persistConfig, reducer);
export const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(thunk)));
export const persistor = persistStore(store);
