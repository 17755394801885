import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { translate } from "../../../translations/translate";
import { useSelector } from "react-redux";
import { Box, StyledEngineProvider } from "@mui/material";
import { errorToast, successToast } from "../../../utils/toast";
import { ApiService } from "../../../utils/ApiService";
import { NumericFormat } from "react-number-format";
import { IMaskInput } from "react-imask";
import { searchTasnif } from "../../../utils/globalAPIRequests/searchTasnif";
import config from "../../../config";
import useApiHook from "../../../hooks/useApiHook";
import Preloader from "../../../components/Preloader";
import ProductCard from "../../../components/ProductCard";
import Button from "../../../components/global/Button";
import PageHeader from "../../../components/PageHeader";
import BasicDatePicker from "../../../components/DatePicker";
import { formatDate } from "../../../utils/formatDate";
import SelectComponent from "../../../components/Select";
import SearchResultCard from "../../../components/SearchCard";
import { searchCategories } from "../../../utils/globalAPIRequests/searchCategories";
import {isMarkedValues, ownerTypeValues, productTypes, VATValues} from "../../../utils/consts";

const committentMask = [
    {
        mask: "000 000 000",
    },
    {
        mask: "0000 0000 0000 00"
    }
];

const PageProductEdit = () => {
    const lang = useSelector((state) => state.languageReducer.lang);
    const warehouseStatus = useSelector(state => state.warehouse.warehouseStatus);

    const __ = (key) => translate(lang, key);

    const navigate = useNavigate();

    const { state } = useLocation();
    const { businessID, id: productID } = useParams();
    const url = `${config.API_BASE_URL}/businesses/${state?.business?.id || businessID
        }/products/${state?.product?.id || productID}`;
    const { data, loading, error } = useApiHook(url);
    const [tasnifProduct, setTasnifProduct] = useState(null);
    const [tasnifUnits, setTasnifUnits] = useState([]);
    const [categoriesSearch, setCategoriesSearch] = useState("");
    const [categoriesSearchResults, setCategoriesSearchResults] = useState([]);
    const [tasnifSearch, setTasnifSearch] = useState("");
    const [selectedUnit, setSelectedUnit] = useState(-1);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [tasnifSearchResults, setTasnifSearchResults] = useState([]);
    const token = useSelector((state) => state.authReducer.token);
    const [currentProduct, setCurrentProduct] = useState({});
    const [discountType, setDiscountType] = useState("amount");
    const product = data?.data?.docs[0];

    const handleCategory = (categoryObj) => {
        if (!currentProduct?.categories.find((item) => item._id === categoryObj._id)) {
            setCurrentProduct({
                ...currentProduct,
                categories: [...currentProduct.categories, categoryObj],
            });
            setCategoriesSearch("");
        }
    };

    const deleteCategory = (categoryID) => {
        const index = currentProduct?.categories.findIndex(
            (item) => item._id === categoryID,
        );
        if (index > -1) {
            currentProduct?.categories.splice(index, 1);
            setCurrentProduct({
                ...currentProduct,
                categories: [...currentProduct.categories],
            });
        }
    };

    const handleTasnif = (tasnifObj) => {
        const tasnifUnitArr = tasnifObj?.packages?.map((pack, idx) => {
            return {
                label: pack?.name["lat"] || pack?.name["uz"],
                value: idx
            };
        });

        setTasnifUnits(tasnifUnitArr);
        setTasnifSearch("");
        setTasnifProduct(tasnifObj);
        setCurrentProduct({
            ...currentProduct,
            titleRu: tasnifObj?.name?.ru || "",
            titleUz: tasnifObj?.name?.uz || "",
            titleEn: tasnifObj?.name?.en || "",
            productTasnif: tasnifObj,
        });
        setTasnifSearchResults([]);
    };

    const onIsMarkedChangeHandler = (value) => {
        const labeling = value === "0";
        setCurrentProduct((prevState) => ({
            ...prevState,
            isMarked: labeling
        }));
    };

    const convertPackagesArr = (packages) => {
        return packages?.map((pack, idx) => (
          {
            label: pack?.name["lat"] || pack?.name["uz"],
            value: idx
          }
        ));
    };

    // USE EFFECT
    useEffect(() => {
        if (categoriesSearch.length > 0) {
            (async () => {
                return await searchCategories(
                    state?.business?.id,
                    categoriesSearch,
                    token,
                );
            })().then((result) => {
                setCategoriesSearchResults(result);
            });
        } else {
            setCategoriesSearchResults(null);
        }
    }, [categoriesSearch]);

    useEffect(() => {
        if (tasnifSearch.length > 0) {
            (async () => {
                return await searchTasnif(tasnifSearch, token);
            })().then((result) => {
                setTasnifSearchResults(result);
            });
        } else {
            setTasnifSearchResults(null);
        }
    }, [tasnifSearch]);

    useEffect(() => {
        if (product?.discount?.value?.percentage) {
            setDiscountType("percentage");
        }

        const tasnifUnitsArr = convertPackagesArr(product?.productTasnif?.packages);
        const productUnitValue = tasnifUnitsArr?.find(unit => unit?.label === product?.units)?.value;


        setStartDate(product?.discount?.activeThrough?.startDate || Date.now());
        setEndDate(product?.discount?.activeThrough?.endDate || Date.now());
        setTasnifProduct(product?.productTasnif);
        setTasnifUnits(tasnifUnitsArr);
        setSelectedUnit(productUnitValue);
        setCurrentProduct({
            VAT: product?.VAT,
            ownerType: product?.ownerType,
            units: product?.units,
            article: product?.article,
            categories: product?.categories,
            committentTIN: product?.committentTIN,
            descriptionRu: product?.description?.ru,
            descriptionUz: product?.description?.uz,
            descriptionEn: product?.description?.en,
            discountAmount: product?.discount?.value?.amount,
            discountPercentage: product?.discount?.value?.percentage,
            inStock: product?.inStock,
            isMarked: product?.isMarked,
            price: product?.price / 100,
            purchasePrice: product?.purchasePrice,
            packageCode: product?.packageCode,
            titleRu: product?.title?.ru,
            titleUz: product?.title?.uz,
            titleEn: product?.title?.en,
            productType: product?.productType,
            productTasnif: product?.productTasnif
        });
    }, [data]);

    // CALLBACKS
    const onVATSelected = function(e) {
        setCurrentProduct({
            ...currentProduct,
            VAT: e.value,
        });
    };
    const onOwnerTypeSelect = function(e) {
        setCurrentProduct({
            ...currentProduct,
            ownerType: e.value,
        });
    };

    const onProductTypeChange = (e) => {
        setCurrentProduct({
            ...currentProduct,
            productType: e.value
        });
    };

    const onUnitSelected = function(e) {
        const packageIndex = e.value;
        const pack = tasnifProduct?.packages[packageIndex];
        setSelectedUnit(packageIndex);
        setCurrentProduct({
            ...currentProduct,
            units: pack?.name["lat"] || pack?.name["uz"],
            packageCode: pack?.code
        });
    };
    const onCancelClick = function () {
        navigate(`/businesses/${state?.business?.id || businessID}/products`, {
            state: {
                business: {
                    id: state?.business?.id || businessID,
                    slug: state?.business?.slug,
                    name: state?.business?.name,
                },
            },
        });
    };
    const onStartDateChange = function (e) {
        setStartDate(e?.$d);
    };
    const onEndDateChange = function (e) {
        setEndDate(e?.$d);
    };
    const onSubmit = (e) => {
        e.preventDefault();

        const discountObj =
            discountType === "amount"
                ? { value: { amount: currentProduct?.discountAmount } }
                : {
                      value: {
                          percentage: +currentProduct?.discountPercentage,
                      },
                  };

        const body = {
            VAT: currentProduct.VAT,
            ownerType: currentProduct?.ownerType,
            units: currentProduct.units,
            article: currentProduct.article,
            categories: currentProduct.categories,
            committentTIN: currentProduct.committentTIN,
            description: {
                uz: currentProduct.descriptionUz,
                ru: currentProduct.descriptionUz,
                en: currentProduct.descriptionUz,
            },
            discount: {
                ...discountObj,
                activeThrough: {
                    startDate: formatDate(new Date(startDate)),
                    endDate: formatDate(new Date(endDate)),
                },
            },
            inStock: +currentProduct.inStock,
            isMarked: currentProduct.isMarked,
            price: +currentProduct.price * 100,
            purchasePrice: +currentProduct.purchasePrice * 100,
            title: {
                uz: currentProduct.titleUz,
                ru: currentProduct.titleUz,
                en: currentProduct.titleUz,
            },
            productTasnif: {
                ...tasnifProduct,
                internationalCode: currentProduct?.productTasnif?.internationalCode
            },
            productType: currentProduct?.productType,
            packageCode: currentProduct?.packageCode
        };

        if (!tasnifProduct) {
            errorToast(__("Please, choose a product from Tasnif Database"));
            return false;
        }

        if (!currentProduct?.packageCode) {
            errorToast(__("Please, choose a  unit"));
            return false;
        }

        if (!currentProduct?.categories?.length) {
            errorToast(__("Please, choose at least one category"));
            return false;
        }

        ApiService.patchEvent(
            `/businesses/${state?.business?.id || businessID}/products/${state?.product?.id || productID
            }`,
            token,
            body,
        )
            .then((response) => {
                if (response.status === 200) {
                    successToast(__("Successfully updated"));
                    navigate(-1);
                }
            })
            .catch((error) => {
                errorToast(__(error.message));
            });
    };
    const buttons = [
        {
            name: "Back",
            link: `/businesses/${state?.business?.id || businessID}/products`,
            state: {
                business: {
                    id: state?.business?.id || businessID,
                    slug: state?.business?.slug,
                    name: state?.business?.name,
                },
            },
            className: "outline",
        },
    ];
    if (loading)
        return (
            <div>
                <Preloader />
            </div>
        );
    if (error) return <div>{error}</div>;
    return (
        <div className="page component-page-business-create">
            <form onSubmit={onSubmit} className="page-inner">
                <PageHeader buttons={buttons} name={__("Edit Product")}  />
                <div className="cards">
                    <div className="left-side-cards">
                        {/* Product Information */}
                        <div className="component-box-card">
                            <div className="card-header">
                                <div className="card-info">
                                    <div className="card-icon">
                                        <i className="icon-information" />
                                    </div>
                                    <div className="card-text">
                                        <p className="card-title">
                                            {__("Product Information")}
                                        </p>
                                        <p className="card-subtitle" />
                                    </div>
                                </div>
                            </div>
                            <div className="input-item-container">
                                <div>
                                    <div className="input-item">
                                        <div className="component-input full smart-search-container">
                                            <label
                                                className="input-label"
                                            >
                                                <input
                                                    defaultValue={
                                                        currentProduct?.titleRu
                                                    }
                                                    onChange={(e) => {
                                                        setTasnifSearch(e.target.value);
                                                    }}
                                                    placeholder={__("Search for ICPS, International Code or Title in Russian or Uzbek")}
                                                    className="input"
                                                    type="text"
                                                />
                                                <span className="input-text">
                                            {__("Search")}
                                        </span>
                                            </label>
                                            {tasnifSearchResults?.length > 0 &&
                                                tasnifSearch && (
                                                    <div className="smart-search-list">
                                                        {tasnifSearchResults &&
                                                            tasnifSearchResults?.map(
                                                                (item, i) => (
                                                                    <Box
                                                                        display="flex"
                                                                        justifyContent="center"
                                                                        key={i}
                                                                    >
                                                                        <ProductCard
                                                                            item={item}
                                                                            classNames={
                                                                                "with-padding bordered hoverable"
                                                                            }
                                                                            onClick={() =>
                                                                                handleTasnif(
                                                                                    item,
                                                                                )
                                                                            }
                                                                        />
                                                                    </Box>
                                                                ),
                                                            )}
                                                    </div>
                                                )}
                                        </div>
                                    </div>
                                    <div className="input-item">
                                        {tasnifProduct && tasnifProduct.icps ? (
                                            <div className="info-card w-100">
                                                <p className="card-heading">
                                                    {__("Tasnif Product Info")}
                                                </p>
                                                <div className="card-content card-content-disabled">
                                                    <p className="text">
                                                <span className="bold">
                                                    {__("Title")} :
                                                </span>
                                                    </p>
                                                    <p className="text">
                                                        {tasnifProduct.name?.ru || ""}
                                                    </p>
                                                    <p className="text">
                                                        {tasnifProduct.name?.uz || ""}
                                                    </p>
                                                    <p className="text">
                                                <span className="bold">
                                                    {__("ICPS")} :
                                                </span>{" "}
                                                        {tasnifProduct.icps}
                                                    </p>
                                                    {tasnifProduct.internationalCode && (
                                                        <p className="text">
                                                    <span className="bold">
                                                        {__("Int. Code")} :
                                                    </span>{" "}
                                                            {
                                                                tasnifProduct.internationalCode
                                                            }
                                                        </p>
                                                    )}
                                                    <p className="text">
                                                <span className="bold">
                                                    {`${__("Marking")}:`}
                                                </span>{" "}
                                                        {parseInt(
                                                            tasnifProduct.label,
                                                        ) === 1
                                                            ? __("Required")
                                                            : __("Not required")
                                                        }
                                                    </p>
                                                    <Button
                                                        className="btn outline sm"
                                                        text={__("Clear")}
                                                        onClick={() => {
                                                            setTasnifProduct(null);
                                                            setCurrentProduct({
                                                                ...currentProduct,
                                                                productTasnif: null,
                                                                units: "",
                                                                packageCode: ""
                                                            });
                                                            setSelectedUnit(-1);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="info-card w-100">
                                                <p className="card-heading">
                                                    {__("Tasnif Product Info")}
                                                </p>
                                                <div className="card-content">
                                                    <p className="text">
                                                <span className="bold">
                                                    {__("Title:")}
                                                </span>
                                                    </p>
                                                    <p className="text">
                                                        {currentProduct?.productTasnif
                                                            ?.name?.ru || ""}
                                                    </p>
                                                    <p className="text">
                                                        {currentProduct?.productTasnif
                                                            ?.name?.uz || ""}
                                                    </p>
                                                    <p className="text">
                                                <span className="bold">
                                                    ICPS:
                                                </span>{" "}
                                                        {
                                                            currentProduct
                                                                ?.productTasnif?.icps
                                                        }
                                                    </p>
                                                    {currentProduct?.productTasnif
                                                        ?.internationalCode && (
                                                        <p className="text">
                                                    <span className="bold">
                                                        Int. Code:
                                                    </span>{" "}
                                                            {
                                                                currentProduct
                                                                    ?.productTasnif
                                                                    ?.internationalCode
                                                            }
                                                        </p>
                                                    )}
                                                    <p className="text">
                                                <span className="bold">
                                                    {`${__("Marking")}:`}
                                                </span>{" "}
                                                        {parseInt(
                                                            currentProduct
                                                                ?.productTasnif?.label,
                                                        ) === 1
                                                            ? __("Required")
                                                            : __("Not required")}
                                                    </p>
                                                    <Button
                                                        className="btn outline sm"
                                                        text="Clear"
                                                        onClick={() => {
                                                            setCurrentProduct({
                                                                ...currentProduct,
                                                                productTasnif: null,
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div>
                                    <div className="input-item">
                                        <div className="component-input full smart-search-container">
                                            <label
                                                className="input-label"
                                            >
                                                <input
                                                    defaultValue={
                                                        currentProduct?.titleUz
                                                    }
                                                    onChange={(e) => {
                                                        setCurrentProduct({
                                                            ...currentProduct,
                                                            titleUz: e.target.value,
                                                        });
                                                    }}
                                                    placeholder={__(
                                                        "Enter title",
                                                    )}
                                                    className="input"
                                                    type="text"
                                                    required={true}
                                                />
                                                <span className="input-text">
                                            {__("Title")}
                                        </span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="input-item">
                                        <div className="component-input full">
                                            <label
                                                className="input-label"
                                            >
                                        <textarea
                                            defaultValue={
                                                currentProduct?.descriptionUz
                                            }
                                            onChange={(e) =>
                                                setCurrentProduct({
                                                    ...currentProduct,
                                                    descriptionUz:
                                                    e.target.value,
                                                })
                                            }
                                            placeholder={__(
                                                "Enter description",
                                            )}
                                            className="input"
                                            rows={3}
                                        />
                                                <span className="input-text">
                                            {__("Description")}
                                        </span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="input-item">
                                        <div className="component-input full">
                                            <label
                                              className="input-label"
                                            >
                                                <span className="input-text">
                                                    {__("Marking")}
                                                </span>
                                                <SelectComponent
                                                  value={currentProduct?.isMarked ? isMarkedValues[0] : isMarkedValues[1]}
                                                  options={isMarkedValues}
                                                  onChange={(e) => onIsMarkedChangeHandler(e.value)}
                                                  translateLabel
                                                />
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Categories*/}
                        <div className="component-box-card">
                            <div className="card-header">
                                <div className="card-info">
                                    <div className="card-icon">
                                        <i className="icon-information"></i>
                                    </div>
                                    <div className="card-text">
                                        <p className="card-title">
                                            {__("Categories")}
                                        </p>
                                        <p className="card-subtitle"></p>
                                    </div>
                                </div>
                            </div>
                            <div className="input-item">
                                <div className="component-input smart-search-container">
                                    <label
                                        className="input-label"
                                    >
                                        <input
                                            onChange={(e) => {
                                                setCategoriesSearch(
                                                    e.target.value,
                                                );
                                            }}
                                            placeholder={__("Start typing...")}
                                            className="input"
                                            type="text"
                                            value={categoriesSearch}
                                        />
                                        <span className="input-text">
                                            {__("Search Categories")}
                                        </span>
                                    </label>
                                    {categoriesSearchResults?.length > 0 &&
                                        categoriesSearch && (
                                            <div className="smart-search-list">
                                                {categoriesSearchResults &&
                                                    categoriesSearchResults?.map(
                                                        (item, i) => (
                                                            <Box
                                                                display="flex"
                                                                justifyContent="center"
                                                                key={i}
                                                            >
                                                                <SearchResultCard
                                                                    item={item}
                                                                    label={item.title?.ru}
                                                                    classNames={
                                                                        "with-padding bordered hoverable"
                                                                    }
                                                                    onClick={() =>
                                                                        handleCategory(
                                                                            item,
                                                                        )
                                                                    }
                                                                />
                                                            </Box>
                                                        ),
                                                    )}
                                            </div>
                                        )}
                                </div>
                                {currentProduct?.categories &&
                                    currentProduct?.categories.length > 0 && (
                                        <div className="info-card white-bg">
                                            <p className="card-heading">
                                                {__("Categories")}
                                            </p>
                                            <div className="card-content equal-padding">
                                                <div className="tags-container">
                                                    {currentProduct?.categories.map((category) => (
                                                        <div
                                                            className="tag"
                                                            key={category?._id}
                                                        >
                                                            {category?.title?.ru}
                                                            <span
                                                                className="delete-btn"
                                                                onClick={() =>
                                                                    deleteCategory(
                                                                        category._id,
                                                                    )
                                                                }
                                                            ></span>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                            </div>
                        </div>
                        {/* Tax Information */}
                        <div className="component-box-card">
                            <div className="card-header">
                                <div className="card-info">
                                    <div className="card-icon">
                                        <i className="icon-information" />
                                    </div>
                                    <div className="card-text">
                                        <p className="card-title">
                                            {__("Tax Information")}
                                        </p>
                                        <p className="card-subtitle" />
                                    </div>
                                </div>
                            </div>
                            {/* 1 */}
                            <div className="input-item">
                                {/* International code */}
                                <div className="component-input ">
                                    <label
                                        className="input-label"
                                    >
                                        <input
                                          className="input"
                                          type="text"
                                          onChange={(e) => {
                                              setCurrentProduct({
                                                  ...currentProduct,
                                                  productTasnif: {
                                                      ...currentProduct?.productTasnif,
                                                      internationalCode: e.target.value
                                                  }
                                              });
                                          }}
                                          value={
                                              currentProduct?.productTasnif
                                                ?.internationalCode || ""
                                          }
                                        />
                                        <span className="input-text">
                                            {__("Intenational Code")}
                                        </span>
                                    </label>
                                    {tasnifSearchResults?.length > 0 &&
                                        tasnifSearch.internationalCode && (
                                            <div className="smart-search-list">
                                                {tasnifSearchResults &&
                                                    tasnifSearchResults?.map(
                                                        (item, i) => (
                                                            <Box
                                                                display="flex"
                                                                justifyContent="center"
                                                                key={i}
                                                            >
                                                                <ProductCard
                                                                    item={item}
                                                                    classNames={
                                                                        "with-padding bordered hoverable"
                                                                    }
                                                                    onClick={() =>
                                                                        handleTasnif(
                                                                            item,
                                                                        )
                                                                    }
                                                                />
                                                            </Box>
                                                        ),
                                                    )}
                                            </div>
                                        )}
                                </div>
                                {/* Owner type*/}
                                <div className="component-input">
                                    <label
                                        className="input-label"
                                    >
                                        <span className="input-text">
                                            {__("Owner Type")}
                                        </span>
                                        <SelectComponent
                                            value={ownerTypeValues.find(type => type.value === currentProduct?.ownerType)}
                                            options={ownerTypeValues}
                                            onChange={onOwnerTypeSelect}
                                            translateLabel
                                        />
                                    </label>
                                </div>
                            </div>
                            {/* 2 */}
                            <div className="input-item">
                                {/* ICPS */}
                                <div className="component-input smart-search-container">
                                    <label
                                        className="input-label"
                                    >
                                        <input
                                            defaultValue={
                                                currentProduct?.productTasnif
                                                    ?.icps
                                            }
                                            onChange={(e) => {
                                                setTasnifSearch({
                                                    ICPS: e.target.value,
                                                });
                                                setTasnifProduct(null);
                                            }}
                                            placeholder={__("Enter ICPS")}
                                            className="input"
                                            type="text"
                                            disabled={!!tasnifProduct}
                                        />
                                        <span className="input-text">
                                            {__("ICPS")}
                                        </span>
                                    </label>
                                    {tasnifSearchResults?.length > 0 &&
                                        tasnifSearch.ICPS && (
                                            <div className="smart-search-list">
                                                {tasnifSearchResults &&
                                                    tasnifSearchResults?.map(
                                                        (item, i) => (
                                                            <Box
                                                                display="flex"
                                                                justifyContent="center"
                                                                key={i}
                                                            >
                                                                <ProductCard
                                                                    item={item}
                                                                    classNames={
                                                                        "with-padding bordered hoverable"
                                                                    }
                                                                    onClick={() =>
                                                                        handleTasnif(
                                                                            item,
                                                                        )
                                                                    }
                                                                />
                                                            </Box>
                                                        ),
                                                    )}
                                            </div>
                                        )}
                                </div>
                                {/* VAT */}
                                <div className="component-input">
                                    <label
                                        className="input-label"
                                    >
                                        <span className="input-text">
                                            {__("VAT")}
                                        </span>
                                        <SelectComponent
                                            value={VATValues.find(vat => vat.value === currentProduct?.VAT) || 12}
                                            options={VATValues}
                                            onChange={onVATSelected}
                                        />
                                    </label>
                                </div>
                            </div>
                            {/* 3 */}
                            <div className="input-item">
                                {/* Unit */}
                                <div className="component-input">
                                    <label
                                      className="input-label"
                                    >
                                        <span className="input-text">
                                            {__("Unit")}
                                        </span>
                                        <SelectComponent
                                          value={tasnifUnits?.find(unit => unit.value === selectedUnit) || "-"}
                                          options={tasnifUnits}
                                          onChange={onUnitSelected}
                                        />
                                    </label>
                                </div>
                                {/*  Product Type  */}
                                <div className="component-input">
                                    <label
                                      className="input-label"
                                    >
                                        <span className="input-text">
                                            {__("Type")}
                                        </span>
                                        <SelectComponent
                                          value={productTypes.find(type => type.value === currentProduct?.productType) || "-"}
                                          options={productTypes}
                                          onChange={onProductTypeChange}
                                          translateLabel
                                        />
                                    </label>
                                </div>
                            </div>
                            {/* 4 */}
                            {warehouseStatus ? (
                              <div className="input-item">
                                  {/* Article */}
                                  <div className="component-input">
                                      <label
                                        className="input-label"
                                      >
                                          <input
                                            defaultValue={
                                                currentProduct?.article
                                            }
                                            onChange={(e) =>
                                              setCurrentProduct({
                                                  ...currentProduct,
                                                  article: e.target.value,
                                              })
                                            }
                                            placeholder={__("Enter Article")}
                                            className="input"
                                            type="text"
                                          />
                                          <span className="input-text">
                                            {__(" Article")}
                                        </span>
                                      </label>
                                  </div>
                              </div>
                            ) : (
                              <div className="input-item">
                                  {/* In Stock */}
                                  <div className="component-input">
                                      <label
                                        className="input-label"
                                      >
                                          <NumericFormat
                                            value={currentProduct?.inStock}
                                            className={"input"}
                                            required={true}
                                            onValueChange={(values) => {
                                                const {value} = values;
                                                setCurrentProduct({
                                                    ...currentProduct,
                                                    inStock: value,
                                                });
                                            }}
                                            placeholder={__("Enter in stock")}
                                            thousandSeparator={" "}
                                            inputMode={"numeric"}
                                          />
                                          <span className="input-text">
                                            {__("In Stock")}
                                        </span>
                                      </label>
                                  </div>
                              </div>
                            )}
                        </div>
                        {/* Price Information */}
                        <div className="component-box-card">
                            <div className="card-header">
                                <div className="card-info">
                                    <div className="card-icon">
                                        <i className="icon-receipt-money"/>
                                    </div>
                                    <div className="card-text">
                                        <p className="card-title">
                                            {__("Price Information")}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            {/* 1 */}
                            <div className="input-item">
                                {/* Price */}
                                <div className="component-input">
                                    <label
                                      className="input-label"
                                    >

                                        <span className="input-text">
                                            {__(" Price")}
                                        </span>
                                        <NumericFormat
                                          value={currentProduct?.price}
                                          className={"input"}
                                          required={true}
                                            placeholder={__("Enter price")}
                                            thousandSeparator={" "}
                                            decimalSeparator={"."}
                                            inputMode={"numeric"}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            onValueChange={(values) => {
                                                const {value} = values;

                                                setCurrentProduct({
                                                        ...currentProduct,
                                                        price: value,
                                                    },
                                                );
                                            }}
                                        />
                                    </label>
                                </div>
                                {/* Purchase Price */}
                                <div className="component-input">
                                    <label
                                        className="input-label"
                                    >
                                        <NumericFormat
                                            className={"input"}
                                            value={currentProduct?.purchasePrice}
                                            placeholder={__(
                                              "Enter Purchase Price",
                                            )}
                                            thousandSeparator={" "}
                                            decimalSeparator={"."}
                                            inputMode={"numeric"}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            onValueChange={(values) => {
                                                const {value} = values;
                                                setCurrentProduct({
                                                    ...currentProduct,
                                                    purchasePrice:
                                                        value,
                                                });
                                            }}
                                        />
                                        <span className="input-text">
                                            {__("Purchase Price")}
                                        </span>
                                    </label>
                                </div>
                            </div>
                            {/* 2 */}
                            <div className="input-item">
                                {/* Discount amount */}
                                <div className="component-input">
                                    <label
                                        className="input-label"
                                    >
                                        {discountType === "amount" && (
                                            <NumericFormat
                                                defaultValue={
                                                    product?.discount?.value?.amount
                                                }
                                                className={"input"}
                                                required={true}
                                                onValueChange={(values) => {
                                                    const {value} = values;
                                                    if (!currentProduct?.price)
                                                        return false;
                                                    else if (+value < currentProduct?.price)
                                                        setCurrentProduct({
                                                            ...currentProduct,
                                                            discountAmount:
                                                                +value || 0,
                                                        });
                                                }}
                                                thousandSeparator={" "}
                                                decimalScale={2}
                                                decimalSeparator={"."}
                                                inputMode={"numeric"}
                                                fixedDecimalScale={true}
                                                value={
                                                    currentProduct?.discountAmount ||
                                                    0
                                                }
                                            />
                                        )}
                                        {discountType === "percentage" && (
                                            <input
                                                defaultValue={
                                                    product?.discount.value.percentage
                                                }
                                                className={"input"}
                                                required={true}
                                                onChange={(e) => {
                                                    const regexSuccess =
                                                        /^(0|[1-9][0-9]?|(100))?$/gm.test(
                                                            e.target.value,
                                                        ) ||
                                                        e.target.value === "";
                                                    if (!regexSuccess)
                                                        e.preventDefault();
                                                    else
                                                        setCurrentProduct({
                                                            ...currentProduct,
                                                            discountPercentage:
                                                            e.target.value,
                                                        });
                                                }}
                                                type={"number"}

                                            />
                                        )}
                                        <div className="button-toggler">
                                            <label
                                                className="button-toggler-item"
                                            >
                                                <input
                                                    className={"hidden-input"}
                                                    type="radio"
                                                    name={"discountType"}
                                                    value={"amount"}
                                                    onClick={() => {
                                                        setDiscountType(
                                                            "amount",
                                                        );
                                                    }}
                                                    defaultChecked={
                                                        product?.discount?.value
                                                            ?.amount
                                                    }
                                                />
                                                <span className="btn-view">
                                                    {__("soums")}
                                                </span>
                                            </label>
                                            <label
                                                className="button-toggler-item"
                                            >
                                                <input
                                                    className={"hidden-input"}
                                                    type="radio"
                                                    name={"discountType"}
                                                    value={"percentage"}
                                                    onClick={() => {
                                                        setDiscountType(
                                                            "percentage",
                                                        );
                                                    }}
                                                    defaultChecked={
                                                        product?.discount?.value
                                                            ?.percentage
                                                    }
                                                />
                                                <span className="btn-view">
                                                    %
                                                </span>
                                            </label>
                                        </div>
                                        <span className="input-text">
                                            {__("Discount")}
                                        </span>
                                    </label>
                                </div>
                                <div className="input-item-container">
                                    {/* Start Date */}
                                    <div className="component-input">
                                        <label
                                            className="input-label"
                                        >
                                        <span className="input-text date-picker">
                                            {__("Discount Start Date")}
                                        </span>
                                            <StyledEngineProvider>
                                                <BasicDatePicker
                                                    value={startDate || ""}
                                                    selectedDate={startDate}
                                                    setSelectedDate={setStartDate}
                                                    onChange={onStartDateChange}
                                                />
                                            </StyledEngineProvider>
                                        </label>
                                    </div>
                                    {/* End Date */}
                                    <div className="component-input">
                                        <label
                                            className="input-label"
                                        >
                                        <span className="input-text date-picker">
                                            {__("Discount End Date")}
                                        </span>
                                            <StyledEngineProvider>
                                                <BasicDatePicker
                                                    value={endDate || ""}
                                                    selectedDate={endDate}
                                                    setSelectedDate={setEndDate}
                                                    onChange={onEndDateChange}
                                                />
                                            </StyledEngineProvider>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Supply Information */}
                        <div className="component-box-card">
                            <div className="card-header">
                                <div className="card-info">
                                    <div className="card-icon">
                                        <i className="icon-information" />
                                    </div>
                                    <div className="card-text">
                                        <p className="card-title">
                                            {__("Supply Information")}
                                        </p>
                                        <p className="card-subtitle" />
                                    </div>
                                </div>
                            </div>
                            <div className="input-item">
                                {/* CommitentTIN*/}
                                <div className="component-input">
                                    <label
                                        className="input-label"
                                    >
                                        <IMaskInput
                                            mask={committentMask}
                                            unmask
                                            value={ currentProduct?.committentTIN }
                                            onAccept={(value) => {
                                                setCurrentProduct({
                                                    ...currentProduct,
                                                    committentTIN: value
                                                });
                                            }}
                                            className="input"
                                            type="text"
                                            placeholder="___ ___ ___"
                                            minLength={11}
                                            maxLength={17}
                                        />
                                        <span className="input-text">
                                            {__("Commitent TIN/PINFL")}
                                        </span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="component box-save">
                    <div className="not-save-item">
                        <div className="not-save-icon">
                            <i className="icon-unsaved" />
                        </div>
                        <div className="not-save-text">
                            <p className="save-text">{__("Not Saved Yet")}</p>
                        </div>
                    </div>
                    <div className="not-save-button">
                        <Button
                            className="btn outline sm"
                            text={__("Cancel")}
                            onClick={onCancelClick}
                            type="button"
                        />
                        <Button
                            className="btn sm"
                            text={__("Save")}
                            type="submit"
                        />
                    </div>
                </div>
            </form>
        </div>
    );
};
export default PageProductEdit;
