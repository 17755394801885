import React, { useEffect } from "react";
import { useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  GridFooterContainer,
  gridPageCountSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import Pagination from "@mui/material/Pagination";
import TablePagination from "@mui/material/TablePagination";
import { updatePageAction, updatePageSizeAction, resetControllerAction } from "../../redux/reducers/paginationControllerReducer";
import "./styles.sass";
import { translate } from "../../translations/translate";


const CustomPagination = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const apiRef = useGridApiContext();
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  const controller = useSelector(state => state.paginationControllerReducer);

  useEffect(() => {
    dispatch(resetControllerAction());

    return () => {
      dispatch(resetControllerAction());
    };
  }, [location]);

  const storageKey = `pagination_${location.pathname}`; // Unique key per table, e.g., "pagination_/fiscal"

  // Load saved page from sessionStorage on mount and update Redux
  useEffect(() => {
    const savedPage = sessionStorage.getItem(storageKey);
    if (savedPage !== null) {
      const pageNum = parseInt(savedPage, 10);
      if (!isNaN(pageNum) && pageNum !== controller.page) {
        dispatch(updatePageAction(pageNum));
      }
    } else {
      dispatch(resetControllerAction());
    }

    return () => {
      if (controller.page >= 0) {
        sessionStorage.setItem(storageKey, controller.page.toString());
      } else {
        sessionStorage.removeItem(storageKey);
      }
    };
  }, [location.pathname, dispatch]);

  useEffect(() => {
    if (controller.page >= 0) {
      sessionStorage.setItem(storageKey, controller.page.toString());
    } else {
      sessionStorage.removeItem(storageKey);
    }
  }, [controller.page, storageKey]);


  const lang = useSelector((state) => state.languageReducer.lang);
  const __ = (key) => translate(lang, key);

  return (
    <GridFooterContainer style={{ flexDirection: "row-reverse" }} >
      <Pagination
        shape="rounded"
        count={pageCount}
        page={controller.page + 1}
        onChange={(e, value) => dispatch(updatePageAction(value - 1))}
      />
      {/* <GridPagination
          rowsperpagetext= {__("Rows per page")}
          onRowsPerPageChange={
          e => dispatch(updatePageSizeAction(e.target.value))
        }
      /> */}
      <TablePagination
          component="div"
          count={controller.total}
          page={Math.min(controller.page, Math.max(0, Math.ceil(controller.total / controller.pageSize) - 1))}
          onPageChange={(e, value) => dispatch(updatePageAction(value))}
          rowsPerPage={controller.pageSize}
          onRowsPerPageChange={(e) => dispatch(updatePageSizeAction(e.target.value))}
          labelRowsPerPage={__("Rows per page")}
          rowsPerPageOptions={[5, 10, 20, 50, 100]}
        />
    </GridFooterContainer>
  );
};

export default CustomPagination;
