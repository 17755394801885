import React from "react";
import PageHeader from "../../../components/PageHeader";
import avatar from "../../../assets/images/avatar.png";
import { Box, colors, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useSelector } from "react-redux";
import { mockCartViewDataTeam } from "../../../demo/data/DataCartView/index.js";
import { Link } from "react-router-dom";
import { translate } from "../../../translations/translate";
import StatusTag from "../../../components/StatusTag";
import "../../../assets/fonts/epos-icons/style.css";
import "../../Cart/Table/styles.sass";
import "./styles.sass";

const PageCartView = ({ ...props }) => {
    const lang = useSelector(state => state.languageReducer.lang);
    const __ = key => translate(lang, key);

    const columns = [
        {
            field: "productName",
            headerName: __("Product Name"),
            flex: 1,
            cellClassName: "mane-column--cell",
            renderCell: ({ row: { productName } }) => {
                return (
                    <Box display="flex" justifyContent="center">
                        <Link to={props.link}>
                            <Typography
                                fontFamily="Manrope, sans-serif"
                                fontWeight={600}
                                fontSize={14}
                                color={colors.common["black"]}
                            >
                                {productName}
                            </Typography>
                        </Link>
                    </Box>
                );
            },
        },
        {
            field: "productPrice",
            headerName: __("Product Price"),
            flex: 1,
            renderCell: ({ row: { productPrice } }) => {
                return (
                    <Box display="flex" justifyContent="center">
                        <Typography
                            fontFamily="Manrope, sans-serif"
                            fontWeight={400}
                            fontSize={14}
                            color={colors.common["black"]}
                        >
                            {productPrice}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: "category",
            headerName: __("Category"),
            flex: 1,
            renderCell: ({ row: { category } }) => {
                return (
                    <Box display="flex" justifyContent="center">
                        <Typography
                            fontFamily="Manrope, sans-serif"
                            fontWeight={400}
                            fontSize={14}
                            color={colors.common["black"]}
                        >
                            {category}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: "vat",
            headerName: __("VAT"),
            flex: 1,
            renderCell: ({ row: { vat } }) => {
                return (
                    <Box display="flex" justifyContent="center">
                        <Typography
                            fontFamily="Manrope, sans-serif"
                            fontWeight={400}
                            fontSize={14}
                            color={colors.common["black"]}
                        >
                            {vat}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: "status",
            headerName: __("Status"),
            type: "string",
            renderCell: (props) => {
                return (
                    <Box display="flex" justifyContent="center">
                        <StatusTag
                            className="ui-status active sm"
                            text={props.value}
                        />
                    </Box>
                );
            },
        },
    ];
    return (
        <div {...props} className="page component-page-report-view">
            <form className="page-inner">
                <PageHeader name={__("View")} text={__("Back")} link="/cart/table" />
                <div className="cards">
                    <div className="left-side-cards">
                        <div className="component-box-card">
                            <div className="card-info">
                                <div className="card-icon">
                                    <i className="icon-document-file" />
                                </div>
                                <div className="card-text">
                                    <p className="card-title">
                                        {__("Cart Information")}
                                    </p>
                                    <p className="card-subtitle" />
                                </div>
                            </div>
                            <div className="input-item">
                                <div className="component-input">
                                    <div className="component-input-user">
                                        <div className="component-switcher">
                                            <div className="component-switcher-handler" />
                                            <span className="label-name">
                                                {__("Owner")}
                                            </span>
                                            <div className="component-items-bar">
                                                <img
                                                    className="user-icon"
                                                    src={avatar}
                                                    alt="avatar"
                                                    width="21px"
                                                />
                                                <p className="component-item-name">
                                                    Alex Florense
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="component-input">
                                    <label
                                        className="input-label"
                                    >
                                        <input
                                            defaultValue="12/01/2021"
                                            className="input input-disabled"
                                            type="text"
                                            disabled
                                        />
                                        <span className="input-text">
                                            {__("Created At")}
                                        </span>
                                    </label>
                                </div>
                            </div>
                            <div className="input-item">
                                <div className="component-input">
                                    <label
                                        className="input-label"
                                    >
                                        <input
                                            defaultValue="123456789"
                                            className="input input-disabled"
                                            type="text"
                                            disabled
                                        />
                                        <span className="input-text">
                                            {__("Cashdesk")}
                                        </span>
                                    </label>
                                </div>
                                <div className="component-input">
                                    <label
                                        className="input-label"
                                    >
                                        <input
                                            defaultValue="Approved"
                                            className="input input-disabled"
                                            type="text"
                                            disabled
                                        />
                                        <span className="input-text">
                                            {__("Status")}
                                        </span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="component-box-card">
                            <div className="card-info">
                                <div className="card-icon">
                                    <i className="icon-document-file" />
                                </div>
                                <div className="card-text">
                                    <p className="card-title">{__("Amounts")}</p>
                                </div>
                            </div>
                            <div className="input-item">
                                <div className="component-input">
                                    <label
                                        className="input-label"
                                    >
                                        <input
                                            defaultValue="24"
                                            className="input input-disabled"
                                            type="text"
                                            disabled
                                        />
                                        <span className="input-text">
                                            {__("Total")}
                                        </span>
                                    </label>
                                </div>
                                <div className="component-input">
                                    <label
                                        className="input-label"
                                    >
                                        <input
                                            defaultValue="240"
                                            className="input input-disabled"
                                            type="text"
                                            disabled
                                        />
                                        <span className="input-text">
                                            {__("SubTotal")}
                                        </span>
                                    </label>
                                </div>
                            </div>
                            <div className="input-item">
                                <div className="component-input">
                                    <label
                                        className="input-label"
                                    >
                                        <input
                                            defaultValue="0"
                                            className="input input-disabled"
                                            type="text"
                                            disabled
                                        />
                                        <span className="input-text">
                                            {__("Overall Discount")}
                                        </span>
                                    </label>
                                </div>
                                <div className="component-input">
                                    <label
                                        className="input-label"
                                    >
                                        <input
                                            defaultValue="0"
                                            className="input input-disabled"
                                            type="text"
                                            disabled
                                        />
                                        <span className="input-text">
                                            {__("Manual Discount")}
                                        </span>
                                    </label>
                                </div>
                            </div>
                            <div className="input-item">
                                <div className="component-input">
                                    <label
                                        className="input-label"
                                    >
                                        <input
                                            defaultValue="2"
                                            className="input input-disabled"
                                            type="text"
                                            disabled
                                        />
                                        <span className="input-text">
                                            {__("Product Quantity")}
                                        </span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="component-box-card">
                            <div className="card-info">
                                <div className="card-icon">
                                    <i className="icon-document-file" />
                                </div>
                                <div className="card-text">
                                    <p className="card-title">{__("VAT")}</p>
                                </div>
                            </div>
                            <div className="input-item">
                                <div className="component-input">
                                    <label
                                        className="input-label"
                                    >
                                        <input
                                            defaultValue="2%"
                                            className="input input-disabled"
                                            type="text"
                                            disabled
                                        />
                                        <span className="input-text">
                                            {__("Percentage")}
                                        </span>
                                    </label>
                                </div>
                                <div className="component-input">
                                    <label
                                        className="input-label"
                                    >
                                        <input
                                            defaultValue="2"
                                            className="input input-disabled"
                                            type="text"
                                            disabled
                                        />
                                        <span className="input-text">
                                            {__("Amounts")}
                                        </span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="component-box-card">
                            <div className="card-info">
                                <div className="card-icon">
                                    <i className="icon-document-file" />
                                </div>
                                <div className="card-text">
                                    <p className="card-title">{__("Received")}</p>
                                </div>
                            </div>
                            <div className="input-item">
                                <div className="component-input">
                                    <label
                                        className="input-label"
                                    >
                                        <input
                                            defaultValue="0"
                                            className="input input-disabled"
                                            type="text"
                                            disabled
                                        />
                                        <span className="input-text">{__("Cash")}</span>
                                    </label>
                                </div>
                                <div className="component-input">
                                    <label
                                        className="input-label"
                                    >
                                        <input
                                            defaultValue="0"
                                            className="input input-disabled"
                                            type="text"
                                            disabled
                                        />
                                        <span className="input-text">{__("Card")}</span>
                                    </label>
                                </div>
                            </div>
                            <div className="input-item">
                                <div className="component-input">
                                    <label
                                        className="input-label"
                                    >
                                        <input
                                            defaultValue="0"
                                            className="input input-disabled"
                                            type="text"
                                            disabled
                                        />
                                        <span className="input-text">
                                            {__("E-Cash")}
                                        </span>
                                    </label>
                                </div>
                                <div className="component-input">
                                    <label
                                        className="input-label"
                                    >
                                        <input
                                            defaultValue="24"
                                            className="input input-disabled"
                                            type="text"
                                            disabled
                                        />
                                        <span className="input-text">
                                            {__("Total")}
                                        </span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="component-box-card">
                            <div className="card-info">
                                <div className="card-icon">
                                    <i className="icon-document-file" />
                                </div>
                                <div className="card-text">
                                    <p className="card-title">{__("Refund")}</p>
                                </div>
                            </div>
                            <div className="input-item">
                                <div className="component-input">
                                    <label
                                        className="input-label"
                                    >
                                        <input
                                            defaultValue="0"
                                            className="input input-disabled"
                                            type="text"
                                            disabled
                                        />
                                        <span className="input-text">
                                            {__("Total Amount")}
                                        </span>
                                    </label>
                                </div>
                                <div className="component-input">
                                    <label
                                        className="input-label"
                                    >
                                        <input
                                            defaultValue="1"
                                            className="input input-disabled"
                                            type="text"
                                            disabled
                                        />
                                        <span className="input-text">
                                            {__("Total Quantity")}
                                        </span>
                                    </label>
                                </div>
                            </div>
                            <div className="input-item">
                                <div className="component-input">
                                    <label
                                        className="input-label"
                                    >
                                        <input
                                            defaultValue="0"
                                            className="input input-disabled"
                                            type="text"
                                            disabled
                                        />
                                        <span className="input-text">
                                            {__("Discount")}
                                        </span>
                                    </label>
                                </div>
                                <div className="component-input">
                                    <label
                                        className="input-label"
                                    >
                                        <input
                                            defaultValue="24"
                                            className="input input-disabled"
                                            type="text"
                                            disabled
                                        />
                                        <span className="input-text">{__("VAT")}</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="component-box-card">
                            <div className="card-info">
                                <div className="card-icon">
                                    <i className="icon-document-file" />
                                </div>
                                <div className="card-text">
                                    <p className="card-title">{__("Products")}</p>
                                    <p className="card-subtitle" />
                                </div>
                            </div>
                            <div className="page page-cart-table">
                                <Box>
                                    <Box
                                        m="40px 0 0"
                                        height="75vh"
                                        sx={{
                                            "& .name-column--cell": {
                                                color: colors.green[300],
                                            },
                                            "& .MuiDataGrid-footerContainer": {
                                                borderTop: "none",
                                            },
                                        }}
                                    >
                                        <DataGrid
                                            rows={mockCartViewDataTeam}
                                            columns={columns}
                                            components={{
                                                UITable: <StatusTag />,
                                            }}
                                            checkboxSelection={true}
                                            rowsPerPageOptions={[
                                                5, 10, 20, 100,
                                            ]}
                                        />
                                    </Box>
                                </Box>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default PageCartView;
