import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import ModalWindow from "../../../components/ModalWindow";
import { Box, colors, Typography } from "@mui/material";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import { useSelector } from "react-redux";
import { mockCartDataTeam } from "../../../demo/data/DataCart/index";
import { Link, useNavigate } from "react-router-dom";
import { translate } from "../../../translations/translate";
import StatusTag from "../../../components/StatusTag";
import Action from "../../../components/Action/index.jsx";
import BreadCrumbs from "../../../components/BreadCrumbs/index.jsx";
import Filter from "../../../components/Filter/index.jsx";
import avatar from "../../../assets/images/avatar.png";
import "../../../assets/fonts/epos-icons/style.css";
import UserLinkCard from "../../../components/UserLinkCard";
import "./styles.sass";
import CustomPagination from "../../../components/Pagination";
import { useLocation } from "react-router";

const PageCartTable = ({ ...props }) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const navigate = useNavigate();
    const [id, setId] = useState();
    const lang = useSelector((state) => state.languageReducer.lang);
    const __ = (key) => translate(lang, key);
    const { state, pathname } = useLocation();


    const apiRef = useGridApiRef();

    const storageKey = `datagrid_filters_${state.pathname}`;
    const columnVisibilityKey = `datagrid_visible_columns_${state.pathname}`;
    const requiredColumns = ["title", "action"]; // Columns that must always be visible


    const [filterModel, setFilterModel] = useState(() => {
        const savedFilters = sessionStorage.getItem(storageKey);
        return savedFilters ? JSON.parse(savedFilters) : { items: [] };
    });

    const [columnVisibilityModel, setColumnVisibilityModel] = useState(() => {
        const savedColumns = sessionStorage.getItem(columnVisibilityKey);
        return savedColumns ? JSON.parse(savedColumns) : {};  // Default to all columns visible
    });

    useEffect(() => {
        sessionStorage.setItem(storageKey, JSON.stringify(filterModel));
    }, [filterModel]);

    const handleColumnVisibilityChange = (newModel) => {
        requiredColumns.forEach((col) => {
            newModel[col] = true;
        });

        setColumnVisibilityModel((prevModel) => {
            const updatedModel = { ...prevModel, ...newModel };
            sessionStorage.setItem(columnVisibilityKey, JSON.stringify(updatedModel));
            return updatedModel;
        });
    };


    const openModals = (id) => {
        setModalIsOpen(true);
        setId(id);
    };
    const closeModal = () => {
        setModalIsOpen(false);
    };

    const actionNavigate = (id, url) => {
        navigate(url, {
            state: {
                id,
            },
        });
    };

    const columns = [
        {
            field: "ownerName",
            headerName: __("Owner"),
            type: "string",
                          
            flex: 1,
            renderCell: (props) => {
                return (
                    <Box display="flex" justifyContent="center">
                        <UserLinkCard
                            userLink="../"
                            avatar={avatar}
                            name={props.value}
                        />
                    </Box>
                );
            },
        },
        {
            field: "createdAt",
            headerName: __("Created At"),
            flex: 1,
            renderCell: ({ row: { createdAt } }) => {
                return (
                    <Box display="flex" justifyContent="center">
                        <Typography
                            fontFamily="Manrope, sans-serif"
                            fontWeight={400}
                            fontSize={14}
                            color={colors.common["black"]}
                        >
                            {createdAt || "—"}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: "businessName",
            headerName: __("Business"),
            flex: 1,
            cellClassName: "mane-column--cell",
            renderCell: ({ row: { businessName } }) => {
                return (
                    <Box display="flex" justifyContent="center">
                        <Link to={props.link}>
                            <Typography
                                fontFamily="Manrope, sans-serif"
                                fontWeight={600}
                                fontSize={14}
                                color={colors.common["black"]}
                            >
                                {businessName || "—"}
                            </Typography>
                        </Link>
                    </Box>
                );
            },
        },
        {
            field: "cashDesk",
            headerName: __("Cashdesk"),
            flex: 1,
            renderCell: ({ row: { cashDesk } }) => {
                return (
                    <Box display="flex" justifyContent="center">
                        <Typography
                            fontFamily="Manrope, sans-serif"
                            fontWeight={400}
                            fontSize={14}
                            color={colors.common["black"]}
                        >
                            {cashDesk || "—"}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: "status",
            headerName: __("Status"),
            type: "string",
            flex: 1,
            renderCell: (props) => {
                return (
                    <Box display="flex" justifyContent="center">
                        <StatusTag
                            className="ui-status active sm"
                            text={props.value}
                        />
                    </Box>
                );
            },
        },
        {
            field: "action",
            headerName: __("Action"),
            type: "string",
            renderCell: (props) => {
                return (
                    <Box display="flex" justifyContent="center">
                        <Action
                            // onClick={() =>
                            //     actionNavigate(props?.row?.id, "/user/edit")
                            // }
                            onClickView={() =>
                                actionNavigate(props?.row?.id, "/cart/view")
                            }
                            openModal={() => openModals(props?.row?.id)}
                        />
                    </Box>
                );
            },
        },
    ];

    return (
        <div className="page page-cart-table">
            <Box>
                <BreadCrumbs />
                <Filter text={__("View Cart")} createLink="/cart/view" />
                <Box
                    sx={{
                        "&": {
                            margin: "40px 0 0",
                            width: "100%",
                            height: "65vh",
                            minHeight: "350px",
                        },
                        "& .name-column--cell": {
                            color: colors.green[300],
                        },
                        "& .MuiDataGrid-footerContainer": {
                            borderTop: "none",
                        },
                    }}
                >
                    <DataGrid
                        sx={{
                            height: "65vh",
                            minHeight: "350px",
                        }}
                        rows={mockCartDataTeam}
                        columns={columns}
                        components={{
                            UITable: <StatusTag />,
                            Pagination: CustomPagination,
                        }}
                        checkboxSelection={true}
                        pageSize={10}
                        rowsPerPageOptions={[5, 10, 20, 100]}
                        apiRef={apiRef}
                        filterModel={filterModel}
                        onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
                        columnVisibilityModel={columnVisibilityModel}
                        onColumnVisibilityModelChange={handleColumnVisibilityChange}
                        componentsProps={{
                            columnsPanel: {
                                sx: {
                                    "& .MuiDataGrid-panelFooter button:first-child":{
                                        display: "none"
                                    },
                                    "& .MuiDataGrid-panelFooter button:last-child": {
                                        display: "none"
                                    }
                                }
                            }
                        }}
                    />
                </Box>
            </Box>
            <Modal
                className="delete-modal"
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
            >
                <div className="modal-bottom">
                    <ModalWindow
                        title={__("Are you sure?")}
                        text={__(
                            "Are you sure you want to permanently delete this cart?",
                        )}
                        onClick={closeModal}
                        Click={closeModal}
                    />
                </div>
            </Modal>
        </div>
    );
};

export default PageCartTable;
