import React, {useState, useEffect} from "react";
import {IMaskInput} from "react-imask";
import {useSelector} from "react-redux";
import {ApiService} from "../../../utils/ApiService";
import {errorToast, successToast} from "../../../utils/toast";
import {translate} from "../../../translations/translate";
import {StyledEngineProvider} from "@mui/material/styles";
import BasicDatePicker from "../../../components/DatePicker";
import UIAvatar from "../../../components/Avatar";
import Button from "../../../components/global/Button";
import PageHeader from "../../../components/PageHeader";
import Input from "../../../components/Input";
import getStatuses from "../../../utils/globalAPIRequests/getStatuses";
import getUserRoles from "../../../utils/globalAPIRequests/getUserRoles";
import BreadCrumbs from "../../../components/BreadCrumbs";
import SelectComponent from "../../../components/Select";
import DragAndDrop from "../../DragAndDrop";
import "./styles.sass";
import {useNavigate} from "react-router-dom";
import {useFormatMask} from "../../../hooks/useFormatMask";
import { capitalize } from "../../../utils/capitalize";

const PageCreate = () => {
    const PhoneMask = "+998 (00) 000-00-00";
    const navigate = useNavigate();
    const lang = useSelector((state) => state.languageReducer.lang);
    const __ = (key) => translate(lang, key);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const phoneEmailMask = [
        {
            mask: PhoneMask,
        },
    ];
    const [isDragNDropOpen, setIsDragNDropOpen] = useState(false);
    const handleDragNDropOpen = () => {
        setIsDragNDropOpen(!isDragNDropOpen);
    };


    let roles = getUserRoles();
    useEffect(() => {
        roles && roles.forEach(role => role.label = __(role.label));
    }, [roles]);

    const statuses = getStatuses("user");
    useEffect(() => {
        statuses && statuses.forEach(status => status.label = __(status.label));
    }, [statuses]);

    const [first, setFirstName] = useState("");
    const [last, setLastName] = useState("");
    const [middle, setMiddleName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [birthDate, setBirthDate] = useState(new Date());
    const [role, setRole] = useState("639305cf9426beb51d3d5ea9");
    const [statusUser] = useState("Active");
    const [password, setPassword] = useState("");
    const [isValid, setIsValid] = useState(true);
    const [avatar, setAvatar] = useState({});
    const handleSetAvatar = (url) => setAvatar(url);

    const {token} = useSelector((state) => state.authReducer);

    const updatePassword = (password) => {
        setPassword(password);
    };

    const isValidPassword = () => {
        // Checks if password contains at least 1 letter and number and no spaces
        return /^(?=.*[a-zA-Z])(?=.*\d).*$/.test(password) &&
            !password.includes(" ");
    };

    // CALLBACK FUNCTIONS
    const onCancelClick = function () {
        navigate("/users");
    };

    const onSubmit = (e) => {
        e.preventDefault();

        if (phone?.length < 13) {
            errorToast(__("Incorrect length of the phone number"));
            return;
        }

        if (isValidPassword()) {
            setIsValid(true);

            const body = {
                name: {
                    first,
                    last,
                    middle,
                },
                email,
                phone,
                role,
                statusUser,
                password,
                avatar: avatar?.id,
                birthDate: birthDate ? birthDate
                    ?.toJSON()
                    .slice(0, 10)
                    .split("-")
                    .reverse()
                    .join(".") : "",
            };
            if (isSubmitting) return;
            setIsSubmitting(true);
            ApiService.postEvent("/users/create", token, body)

                .then((response) => {
                    if (response.status === 201) {
                        successToast(__("User successfully created"));
                        setIsSubmitting(false);

                        navigate("/users");
                    } else {
                        errorToast(__(response.message));
                    }
                })

                .catch((error) => {
                    setIsSubmitting(false);

                    errorToast(__(error.message));
                });
        } else {
            setIsValid(false);
        }
    };
    const buttons = [
        {
            name: "Back",
            link: "/users",
            state: {},
            className: "outline"
        }
    ];

    return (
        <div>
            <BreadCrumbs
                breadcrumbs={[
                    {label: "Users", link: "/users"},
                    {label: "Create"},
                ]}
            />

            <div className="page component-page-user-create">
                <form onSubmit={onSubmit} className="page-inner">
                    <PageHeader
                        name={__("Create User")}
                        buttons={buttons}
                    />
                    <div className="cards">
                        <div className="left-side-cards">
                            <div className="component-box-card">
                                <div className="card-header">
                                    <div className="card-info">
                                        <div className="card-icon">
                                            <i className="icon-profile"/>
                                        </div>
                                        <div className="card-text">
                                            <p className="card-title">
                                                {__("Personal Information")}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="input-column">
                                    <UIAvatar
                                        onClick={handleDragNDropOpen}
                                        url={avatar.url}
                                        fileId={avatar.id}
                                        onRemoveSuccess={() => setAvatar({})}
                                    />
                                    <div className="input-row">
                                        <div className="input-item">
                                            <div className="component-input">
                                                <label
                                                    className="input-label"
                                                >
                                                    <input
                                                        value={first}
                                                        onChange={(e) =>
                                                            setFirstName(
                                                                capitalize(e.target.value)
                                                            )
                                                        }
                                                        placeholder={__(
                                                            "Enter your name",
                                                        )}
                                                        name="firstName"
                                                        className="input"
                                                        type="text"
                                                        required
                                                        onInvalid={e => e.target.setCustomValidity(__("Enter your name"))}
                                                        onInput={e => e.target.setCustomValidity("")}
                                                    />
                                                    <span className="input-text">
                                                        {__("First Name")}
                                                    </span>
                                                </label>
                                            </div>
                                            <div className="component-input">
                                                <label
                                                    className="input-label"
                                                >
                                                    <input
                                                        value={last}
                                                        onChange={(e) =>
                                                            setLastName(
                                                                capitalize(e.target.value)
                                                            )
                                                        }
                                                        placeholder={__(
                                                            "Enter your last name",
                                                        )}
                                                        className="input"
                                                        type="text"
                                                        required
                                                        onInvalid={e => e.target.setCustomValidity(__("Enter your last name"))}
                                                        onInput={e => e.target.setCustomValidity("")}

                                                    />
                                                    <span className="input-text">
                                                        {__("Last Name")}
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="input-item">
                                            <div className="component-input">
                                                <label
                                                    className="input-label"
                                                >
                                                    <input
                                                        value={middle}
                                                        onChange={(e) =>
                                                            setMiddleName(
                                                                capitalize(e.target.value)
                                                            )
                                                        }
                                                        placeholder={__(
                                                            "Enter your middle name",
                                                        )}
                                                        className="input"
                                                        type="text"
                                                        required
                                                        onInvalid={e => e.target.setCustomValidity(__("Enter your middle name"))}
                                                        onInput={e => e.target.setCustomValidity("")}

                                                    />
                                                    <span className="input-text">
                                                        {__("Middle Name")}
                                                    </span>
                                                </label>
                                            </div>
                                            <div className="component-input">
                                                <label
                                                    className="input-label"
                                                >
                                                    <input
                                                        value={email}
                                                        onChange={(e) =>
                                                            setEmail(
                                                                e.target.value,
                                                            )
                                                        }
                                                        placeholder={__(
                                                            "Enter your email",
                                                        )}
                                                        className="input"
                                                        type="email"
                                                        required
                                                        onInvalid={e => e.target.setCustomValidity(__("Enter your email"))}
                                                        onInput={e => e.target.setCustomValidity("")}
                                                    />
                                                    <span className="input-text">
                                                        {__("Email")}
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="input-item">
                                            <div className="component-input">
                                                <label
                                                    className="input-label"
                                                >
                                                    <IMaskInput
                                                        value={phone}
                                                        onChange={(e) =>
                                                            setPhone(
                                                                useFormatMask(e
                                                                    .target
                                                                    .value),
                                                            )
                                                        }
                                                        onPaste={
                                                            (e) =>
                                                                setPhone(
                                                                    e.clipboardData.getData("text"),
                                                                )
                                                        }
                                                        autoComplete="off"
                                                        className="mask-on-input card-input input"
                                                        type="tel"
                                                        placeholder="+(998) 99 222 33 44"
                                                        mask={phoneEmailMask}
                                                        required
                                                        onInvalid={e => e.target.setCustomValidity(__("Phone"))}
                                                        onInput={e => e.target.setCustomValidity("")}
                                                    />
                                                    <span className="input-text">
                                                        {__("Phone")}
                                                    </span>
                                                </label>
                                            </div>
                                            <div className="component-input">
                                                <label
                                                    className="input-label"
                                                >
                                                    <span className="input-text">
                                                        {__("Date of Birth")}
                                                    </span>
                                                    <StyledEngineProvider>
                                                        <BasicDatePicker
                                                            value={birthDate || ""}
                                                            required
                                                            onChange={(e) => {
                                                                setBirthDate(
                                                                    e?.$d,
                                                                );
                                                            }}
                                                            selectedDate={
                                                                birthDate
                                                            }
                                                            setSelectedDate={
                                                                setBirthDate
                                                            }
                                                        />
                                                    </StyledEngineProvider>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="component-box-card">
                                <div className="card-header">
                                    <div className="card-info">
                                        <div className="card-icon">
                                            <i className="icon-profile"/>
                                        </div>
                                        <div className="card-text">
                                            <p className="card-title">
                                                {__("Position")}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="input-item">
                                    <div className="component-input">
                                        <label
                                            className="input-label"
                                        >
                                            <span className="input-text">
                                                {__("Position")}
                                            </span>
                                            <SelectComponent
                                                options={roles}
                                                onChange={(e) =>
                                                    setRole(e.value)
                                                }
                                                value={roles?.find(roleSingle => roleSingle?.value === role)}
                                            />
                                        </label>
                                    </div>

                                </div>
                            </div>
                            <div className="component-box-card">
                                <div className="card-header">
                                    <div className="card-info">
                                        <div className="card-icon">
                                            <i className="icon-security"/>
                                        </div>
                                        <div className="card-text">
                                            <p className="card-title">
                                                {__("Password")}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="input-item">
                                    <div className="component-input password-input">
                                        <Input
                                            type="password"
                                            text={__("Password")}
                                            className="ui-input"
                                            updatePassword={updatePassword}
                                            onInvalid={e => e.target.setCustomValidity(__("Password"))}
                                            onInput={e => e.target.setCustomValidity("")}
                                        />
                                        {!isValid && (
                                            <div className="password-input__validation">
                                                <p className="validation__text">
                                                    Password must contain no
                                                    space, at least 1 letter and
                                                    number
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="component box-save">
                        <div className="not-save-item">
                            <div className="not-save-icon">
                                <i className="icon-unsaved"></i>
                            </div>
                            <div className="not-save-text">
                                <p className="save-text">
                                    {__("Not Saved Yet")}
                                </p>
                            </div>
                        </div>
                        <div className="not-save-button">
                            <Button
                                className="btn outline sm"
                                text={__("Cancel")}
                                onClick={onCancelClick}
                                type="button"
                            />
                            <Button
                                className="btn sm"
                                text={__("Save")}
                                disabled={isSubmitting}
                                type="submit"
                            />

                            {isDragNDropOpen &&
                                <DragAndDrop
                                    setter={handleSetAvatar}
                                    closeDragNDrop={handleDragNDropOpen}
                                    uploadType={"avatar"}
                                    docModel={"User"}
                                    isImage={true}
                                />
                            }
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default PageCreate;
