import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { IMaskInput } from "react-imask";
import { errorToast } from "../../../utils/toast";
import { useDispatch, useSelector } from "react-redux";
import { authAction } from "../../../redux/actions/authAction";
import { useFormatMask } from "../../../hooks/useFormatMask";
import { translate } from "../../../translations/translate";
import Button from "../../../components/global/Button";
import Input from "../../../components/LayoutBasic/Input";
import Header from "../../../components/LayoutBasic/Header";
import CheckBox from "../../../components/CheckBox";
import axios from "axios";
import config from "../../../config";
import "./styles.sass";

const PageAuthorization = ({ ...props }) => {
    const dispatch = useDispatch();
    const PhoneMask = "+998 (00) 000-00-00";
    const lang = useSelector((state) => state.languageReducer.lang);
    const __ = (key) => translate(lang, key);

    const phoneEmailMask = [
        {
            mask: PhoneMask,
        },
    ];
    const [isNewbie, setIsNewbie] = useState(true);
    const [value, setValue] = useState("");
    const [phone, setPhone] = useState("");
    const navigate = useNavigate();
    const [isToggle, setToggle] = useState(false);
    const userAuthLink = "/users/authorize";
    const clientAuthLink = "/clients/authorize";
    const [authLink, setAuthLink] = useState(userAuthLink);
    const handlePhoneInput = (e) => {
        let value = e.clipboardData ? e.clipboardData.getData("text") : e.target.value;
        value = value.replace(/([^0-9+])/g, "");
        const body = {
            phone: useFormatMask(value),
        };
        if (value.length === 13) {
            axios
                .post(`${config.API_BASE_URL}/exist`, body)
                .then(function (response) {
                    if (
                        response?.data?.data?.exists === true &&
                        response?.status === 200
                    ) {
                        setIsNewbie(false);
                        setValue("");
                        setPhone(useFormatMask(value));
                        if (response.data.data.model === "Client")
                            setAuthLink("/clients/authorize");
                    } else {
                        errorToast(__(response.data.message));
                        setIsNewbie(true);
                    }
                })
                .catch(function (error) {
                    if (error?.response?.status === 300) {
                        setToggle(true);
                        setIsNewbie(false);
                        setPhone(useFormatMask(value));
                    } else errorToast(__(error.response.data.message));
                });
        }
    };

    const onSubmit = (e) => {
        e.preventDefault();
        const body = {
            phone,
            password: value,
        };
        axios
            .post(config.API_BASE_URL + authLink, body)
            .then(function (response) {
                if (response.status === 200) {
                    if(authLink === "/clients/authorize") {
                        dispatch(authAction(authLink, {
                            phone,
                            password: value,
                        }));
                        return;
                    }

                    navigate("/auth/verification", {
                        state: {
                            phone,
                            password: value,
                            verificationToken: response?.data?.data?.verificationToken,
                            authLink: authLink,
                        },
                    });
                } else {
                    errorToast(__(response.message));
                }
            })
            .catch(function (error) {
                errorToast(__(error.message));
            });
    };

    const togglerAction = (e) => {
        setAuthLink(e === "client" ? clientAuthLink : userAuthLink);
    };

    return (
        <div {...props} className="page page-authorization">
            <Header />
            <div className="component-card">
                <div className="card-inner">
                    <h1 className="component-heading">{__("Welcome")}</h1>
                    <div className="component-card-text">
                        <p className="component-text">
                            {__("Enter your phone number to continue")}
                        </p>
                    </div>
                    <form className="card-form" onSubmit={onSubmit}>
                        <div className="inputs-block">
                            <IMaskInput
                                className="mask-on-input card-input"
                                htmlFor="phone"
                                id="phone"
                                type="phone"
                                placeholder="+(998) 99 222 33 44"
                                mask={phoneEmailMask}
                                autoComplete="off"
                                onChange={(val) => handlePhoneInput(val)}
                                onPaste={(val) => handlePhoneInput(val)}
                            />
                            {!isNewbie && (
                                <Input
                                    className="card-input"
                                    htmlFor="userPassword"
                                    id="userPassword"
                                    type="password"
                                    placeholder={__("Password")}
                                    onChange={(e) => setValue(e.target.value)}
                                />
                            )}
                            {isToggle && (
                                <div className="auth-checkbox">
                                    <p>
                                        {__(
                                            "Both User and Client have been found.",
                                        )}{" "}
                                        <br />
                                        {__(
                                            "Select your desired login option:",
                                        )}
                                    </p>
                                    <div className="auth-checkbox-inner">
                                        <div className="auth-checkbox-item">
                                            <CheckBox
                                                inputType="radio"
                                                htmlFor="user"
                                                id="user"
                                                titleClass="checkbox-title"
                                                title={__("User")}
                                                defaultChecked={true}
                                                value="User"
                                                name="userType"
                                                onChange={() =>
                                                    togglerAction("user")
                                                }
                                            />
                                        </div>
                                        <div className="auth-checkbox-item">
                                            <CheckBox
                                                inputType="radio"
                                                htmlFor="client"
                                                id="client"
                                                titleClass="checkbox-title"
                                                title={__("Client")}
                                                value="Client"
                                                name="userType"
                                                onChange={() =>
                                                    togglerAction("client")
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        {!isNewbie && (
                            <Button
                                className="btn"
                                disabled={isNewbie}
                                text={isNewbie ? __("Sign up") : __("Sign in")}
                            />
                        )}
                        {isNewbie && (
                            <Button
                                className="btn"
                                text={__("Sign up")}
                                onClick={(e) => {
                                    e.preventDefault();
                                    navigate("register");
                                }}
                            />
                        )}

                        <div className="reset-link">
                            <Link
                                className="link-reset-passwd"
                                to="/auth/reset"
                                state={{
                                    authLink,
                                }}
                            >
                                {isNewbie ? " " : __("Reset your password")}
                            </Link>
                        </div>
                    </form>
                </div>
            </div>
            <span className="site-version">
                <div className="company-info">
                <div className="company-info--container">
                    <p className="company-info--content">{__("LLC")} “E-POS Systems”, <br/>
                        {__("The Republic of Uzbekistan")}
                    </p>
                    <p className="company-info--content">”E-POS Management” v1.0.0</p>
                </div>
            </div>
            </span>
        </div>
    );
};

export default PageAuthorization;
