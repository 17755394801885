import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { parseFiscalID, parseVirtualID } from "../../../utils/helper.js";
import { translate } from "../../../translations/translate";
import PageHeader from "../../../components/PageHeader";
import useApiHook from "../../../hooks/useApiHook";
import config from "../../../config";
import Preloader from "../../../components/Preloader/index.jsx";
import BreadCrumbs from "../../../components/BreadCrumbs/index.jsx";
import Filter from "../../../components/Filter";
import { Box, colors, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { getParsedDate } from "../../../utils/getParsedDate";
import {url as genURL} from "../../../utils/generalizeURL";
import "./styles.sass";

const PageFiscalEdit = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const {id} = useParams();
    const url = `${config.API_BASE_URL}/fiscalmodule/${state?.fiscal?.id || id}`;
    const { data, loading, error } = useApiHook(url);
    const lang = useSelector((state) => state.languageReducer.lang);
    const __ = (key) => translate(lang, key);
    const fiscal = data?.data?.docs[0];
    const [showField, setShowField] = useState();
    useEffect(() => {
        data?.data?.docs?.map((item) => {
            setShowField(item?.deactivation?.reason);
        });
    }, [data]);

    if (loading)
        return (
            <div>
                <Preloader />
            </div>
        );

    if (error) return <div>{error.message}</div>;

    const buttons = [
        {
            name: "Back",
            link: "/fiscal",
            state: {},
            className: "outline"
        },
        {
            name: "Edit",
            link: `/fiscal/${state?.fiscal?.id || id}/edit`,
            state: {
                fiscal: {
                    id: state?.fiscal?.id || id
                }
            },
            className: ""
        }
    ];
    const row = data?.data?.docs[0]?.changingHistory?.map(moduleInfo => ({
        ...moduleInfo,
        virtualID: data?.data?.docs[0]?.virtualID,
        file: {
            fileName: moduleInfo.file?.fileInfo?.originalName,
            url: moduleInfo.file?.fileInfo?.url
        },
        id: moduleInfo._id,
    })) || [];
    const columns = [
        {
            field: "old-module",
            flex: 1,
            cellClassName: "mane-column--cell",
            headerName: __("Old Module"),
            renderCell: ({ row }) => (
                <Typography
                    fontFamily="Manrope, sans-serif"
                    fontWeight={400}
                    fontSize={14}
                    color={colors.common[500]}
                    textOverflow={"ellipsis"}
                    overflow={"hidden"}
                    whiteSpace={"nowrap"}
                >
                    {row.oldFiscal}
                </Typography>
            )
        },
        {
            field: "new-module",
            flex: 1,
            cellClassName: "mane-column--cell",
            headerName: __("New Module"),
            renderCell: ({ row }) => (
                <Typography
                    fontFamily="Manrope, sans-serif"
                    fontWeight={400}
                    fontSize={14}
                    color={colors.common[500]}
                    textOverflow={"ellipsis"}
                    overflow={"hidden"}
                    whiteSpace={"nowrap"}
                >
                    {row.newFiscal}
                </Typography>
            )
        },
        {
            field: "date",
            flex: 1,
            cellClassName: "mane-column--cell",
            headerName: __("Date"),
            renderCell: ({ row }) => (
                <Typography
                    fontFamily="Manrope, sans-serif"
                    fontWeight={400}
                    fontSize={14}
                    color={colors.common[500]}
                    textOverflow={"ellipsis"}
                    overflow={"hidden"}
                    whiteSpace={"nowrap"}
                >
                    {getParsedDate(row.changedAt)}
                </Typography>
            )
        },
        {
            field: "virtual-number",
            flex: 1,
            cellClassName: "mane-column--cell",
            headerName: __("Virtual Number"),
            renderCell: ({ row }) => (
                <Typography
                    fontFamily="Manrope, sans-serif"
                    fontWeight={400}
                    fontSize={14}
                    color={colors.common[500]}
                    textOverflow={"ellipsis"}
                    overflow={"hidden"}
                    whiteSpace={"nowrap"}
                >
                    {row.virtualID}
                </Typography>
            )
        },
        {
            field: "file",
            flex: 1,
            cellClassName: "mane-column--cell",
            headerName: __("File"),
            renderCell: ({ row }) => (
                <Typography
                    fontFamily="Manrope, sans-serif"
                    fontWeight={400}
                    fontSize={14}
                    color={colors.common[500]}
                    textOverflow={"ellipsis"}
                    overflow={"hidden"}
                    whiteSpace={"nowrap"}
                >
                    {
                        row.file?.fileName ? (
                            <a className="link--underlined" href={genURL(row.file?.url)} target="_blank" rel="noopener noreferrer">
                                { row.file.fileName }
                            </a>
                        ) : __("Not Found")
                    }
                </Typography>
            )
        }
    ];

    return (
        <div>
            <BreadCrumbs
                breadcrumbs={[
                    { label: "Fiscal", link: "/fiscal" },
                    { label: fiscal?.fiscalID },
                ]}
            />
            <div className="page component-page-fiscal-view">
                <div className="page-inner">
                    <PageHeader
                        name={__("View Fiscal Module")}
                        buttons={buttons}
                    />
                    <div className="cards-inner">
                        <div className="left-side-cards">
                            {data?.data?.docs?.map((item, i) => (
                                <div key={i}>
                                    <div className="component-fiscal-box-card">
                                        <div className="card-user">
                                            <div className="user-icon">
                                                <i className="icon-information" />
                                            </div>
                                            <div className="user-text">
                                                <p className="user-title">
                                                    {__(
                                                        "Fiscal Module Information",
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="input-item input-item-fiscal">
                                            <div className="component-input component-input-fiscal">
                                                <label
                                                    className="input-label"
                                                >
                                                    <span className="input-text">
                                                        {__("Fiscal ID")}
                                                    </span>
                                                    <input
                                                        defaultValue={item?.fiscalID?.slice(
                                                            0,
                                                            2,
                                                        )}
                                                        className="input input-sm"
                                                        type="text"
                                                        disabled
                                                    />
                                                </label>
                                            </div>
                                            <div className="component-input">
                                                <label
                                                    className="input-label"
                                                >
                                                    <input
                                                        defaultValue={parseFiscalID(
                                                            item?.fiscalID,
                                                        )}
                                                        className="input input-fiscal-sm"
                                                        type="text"
                                                        disabled
                                                    />
                                                    <span className="input-text"></span>
                                                </label>
                                            </div>
                                            <div className="component-input">
                                                <label
                                                    className="input-label"
                                                >
                                                    <input
                                                        defaultValue={__(item?.status)}
                                                        className="input"
                                                        disabled
                                                    />
                                                    <span className="input-text date-picker">
                                                        {__("Status")}
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="input-item">
                                            <div className="component-input">
                                                <label
                                                    className="input-label"
                                                >
                                                    <input
                                                        defaultValue={parseVirtualID(
                                                            item?.virtualID,
                                                        )}
                                                        className="input "
                                                        type="text"
                                                        disabled
                                                    />
                                                    <span className="input-text">{__("Virtual Number")}</span>
                                                </label>
                                            </div>
                                            <div className="component-input">
                                                <div className="label-container">
                                                    <label
                                                        className="input-label"
                                                    >
                                                        <input
                                                            defaultValue={item?.cashdesk?.uniqueID || "-"}
                                                            className="input"
                                                            type="text"
                                                            disabled
                                                        />
                                                        <span className="input-text">{__("Cashdesk Key")}</span>
                                                    </label>
                                                    {item?.cashdesk?.uniqueID && (
                                                        <button
                                                            className="input-label__delete-btn"
                                                            type="button"
                                                            onClick={() => navigate(`/cashdesks/${item?.cashdesk?.uniqueID}`)}
                                                        >
                                                            <i className="icon-view"></i>
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="input-inner">
                                            <div className="component-input">
                                                <div className="label-container">
                                                    <label className="input-label">
                                                        <input
                                                            defaultValue={item?.business?.name || "-"}
                                                            className="input"
                                                            type="text"
                                                            disabled
                                                        />
                                                        <span className="input-text">{__("Business Name")}</span>
                                                    </label>
                                                    {item?.business?.name && (
                                                        <button
                                                            className="input-label__delete-btn"
                                                            type="button"
                                                            onClick={() => navigate(`/businesses/${item?.business?._id}`)}
                                                        >
                                                            <i className="icon-view"></i>
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="input-inner">
                                            {showField && (
                                                <div className="component-ui-input">
                                                    <label
                                                        className="ui-card-label"
                                                    >
                                                      <textarea
                                                          defaultValue={
                                                              item
                                                                  ?.deactivation
                                                                  ?.reason
                                                          }
                                                          className="ui-input text-area"
                                                          disabled
                                                      />
                                                        <span className="ui-input-text">
                                                          {__("Reason of deactivation")}
                                                      </span>
                                                    </label>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="page page-table-branch table branches-table">
                        <Box>
                            <Filter description={__("History")} withoutButton />
                            <Box
                                sx={{
                                    "&": {
                                        margin: "0",
                                    },
                                    "& .name-column--cell": {
                                        color: colors.green[300],
                                    },
                                    "& .MuiDataGrid-footerContainer": {
                                        borderTop: "none",
                                    },
                                }}
                            >
                                <DataGrid
                                    rows={row}
                                    rowCount={1}
                                    page={1}
                                    pageSize={1}
                                    columns={columns}
                                    {...config.TABLE_CONFIG(__)}
                                />
                            </Box>
                        </Box>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PageFiscalEdit;
