import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import ModalWindow from "../../../components/ModalWindow";
import { Box, colors, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { translate } from "../../../translations/translate";
import { ApiService } from "../../../utils/ApiService";
import { errorToast } from "../../../utils/toast";
import StatusTag from "../../../components/StatusTag";
import Action from "../../../components/Action/index.jsx";
import BreadCrumbs from "../../../components/BreadCrumbs/index.jsx";
import Filter from "../../../components/Filter/index.jsx";
import useApiHook from "../../../hooks/useApiHook";
import config from "../../../config";
import Preloader from "../../../components/Preloader";
import "../../../assets/fonts/epos-icons/style.css";
import "./styles.sass";
import { updateTotalAction } from "../../../redux/reducers/paginationControllerReducer";
import {getTokenData} from "../../../utils/helper";

Modal.setAppElement("#root");

const PageClientTable = () => {

    // STATE
    const state = useLocation();

    const filters = {
        isStatus: true,
    };
    const page = "clients";
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const navigate = useNavigate();
    const [id, setId] = useState();
    const [searchInputValue, setSearchInputValue] = useState("");

    const openModals = (id) => {
        setModalIsOpen(true);
        setId(id);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    let url = `${config.API_BASE_URL}/clients?`;
    const [urlBase, setUrlBase] = useState(url);

    // CHECKING IF THERE ARE FILTERS
    if(state?.state) {
        // STATUS FILTER
        let status = "";
        state?.state?.status?.forEach(singleStatus => {
            if(singleStatus.isChecked) {
                status += `&${singleStatus.name.toLowerCase()}=true`;
            }
        });

        // CONCATENATING URL WITH FILTERS
        url += status;
    }

    const { data, loading, error } = useApiHook(urlBase);
    const { token } = useSelector((state) => state.authReducer);
    const decodedToken = getTokenData(token);
    const isDealer = decodedToken?.role?.title === "Dealer";
    const lang = useSelector((state) => state.languageReducer.lang);
    const __ = (key) => translate(lang, key);
    const dispatch = useDispatch();
    const controller = useSelector(state => state.paginationControllerReducer);

    useEffect(() => {
        if(data) {
            dispatch(updateTotalAction(parseInt(data?.data?.totalDocs) || 0));
        }
    }, [data]);

    useEffect(() => {
        setUrlBase(
            `${url}&page=${controller.page + 1}&limit=${
                controller.pageSize
            }`,
        );
    }, [controller.page, controller.pageSize]);

    useEffect(() => {
        if(searchInputValue)
            setUrlBase(`${config.API_BASE_URL}/clients/search/client?query=${searchInputValue}${isDealer ? `&dealerID=${decodedToken?.ID}`: ""}`);
        else setUrlBase(url);
    }, [searchInputValue]);

    if (loading)
        return (
            <div>
                <Preloader />
            </div>
        );

    if (error) return <div>{error}</div>;

    let clientList = [];

    if(data?.data.docs) {
        data?.data?.docs.map((item) => {
            let clientSingle = {
                firstName: item.name?.first || "—",
                lastName: item.name?.last || "—",
                id: item._id,
                role: item?.role?.title || "—",
                email: item.email || "—",
                phone: item.phone || "—",
                status: item.status,
            };
            clientList.push(clientSingle);
        });
    }

    const removeClient = () => {
        ApiService.deleteEvent(`/clients/${id}`, token)
            .then((res) => {
                if (res.status === 200) {
                    window.location.reload();
                }
            })
            .catch((error) => {
                errorToast(__(error.message));
            });
    };

    const columns = [
        {
            field: "name",
            headerName: __("Name"),
            flex: 1,
            cellClassName: "mane-column--cell",
            renderCell: ({ row }) => {
                let fullName = row.firstName + " " + row.lastName;
                if(fullName.length > 37) {
                    fullName = fullName.slice(0, 35) + "...";
                }
                return (
                    <Box display="flex" justifyContent="center">
                        <Link
                            to={`/clients/${row.id}`}
                            state={{
                                client: {
                                    id: row.id,
                                },
                            }}
                        >
                            <Typography
                                fontFamily="Manrope, sans-serif"
                                fontWeight={600}
                                fontSize={14}
                                color={colors.common["black"]}
                                textOverflow={"ellipsis"}
                                overflow={"hidden"}
                                whiteSpace={"nowrap"}
                            >
                                {fullName}
                            </Typography>
                        </Link>
                    </Box>
                );
            },
        },
        {
            field: "email",
            headerName: __("Email"),
            flex: 1,
            renderCell: ({ row: { email } }) => {
                return (
                    <Box display="flex" justifyContent="flex-start" width={"100%"}>
                        <Typography
                            fontFamily="Manrope, sans-serif"
                            fontWeight={400}
                            fontSize={14}
                            color={colors.common["black"]}
                            textOverflow={"ellipsis"}
                            overflow={"hidden"}
                            whiteSpace={"nowrap"}
                        >
                            {email}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: "phone",
            headerName: __("Phone Number"),
            className: "table-phone",
            flex: 1,
            renderCell: ({ row: { phone } }) => {
                return (
                    <Box display="flex" justifyContent="flex-start" width={"100%"}>
                        <Typography
                            fontFamily="Manrope, sans-serif"
                            fontWeight={400}
                            fontSize={14}
                            color={colors.grey[500]}
                            textOverflow={"ellipsis"}
                            overflow={"hidden"}
                            whiteSpace={"nowrap"}
                        >
                            {phone}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: "status",
            headerName: __("Status"),
            type: "string",
            flex: 1,
            renderCell: (props) => {
                return (
                    <Box display="flex" justifyContent="center">
                        <StatusTag className={"sm"} text={props.value} />
                    </Box>
                );
            },
        },
        {
            field: "action",
            headerName: __("Action"),
            type: "string",
            renderCell: ({ row }) => {
                return (
                    <Box display="flex" justifyContent="center">
                        <Action
                            onClick={() =>
                                navigate(
                                    `/clients/${row.slug || row.id}/edit`,
                                    {
                                        state: {
                                            client: {
                                                id: row.id,
                                                slug: row.slug,
                                            },
                                        },
                                    },
                                )
                            }
                            onClickView={() =>
                                navigate(`/clients/${row.slug || row.id}`, {
                                    state: {
                                        client: {
                                            id: row.id,
                                            slug: row.slug,
                                        },
                                    },
                                })
                            }
                            openModal={() => openModals(row?.id)}
                        />
                    </Box>
                );
            },
        },
    ];
    return (
        <div>
            <BreadCrumbs
                breadcrumbs={[{ label: "Clients", item: "/clients" }]}
            />
            <div className="page page-client-table">
                <Box>
                    <Filter
                        description={__("Clients")}
                        text={__("Add Client")}
                        createLink="/clients/create"
                        filters={filters}
                        page={page}
                        search={searchInputValue}
                        setSearch={setSearchInputValue}
                    />
                    <Box
                        sx={{
                            "&": {
                                margin: "0",
                                height: "90vh",
                                minHeight: "350px",
                            },
                            "& .name-column--cell": {
                                color: colors.green[300],
                            },
                            "& .MuiDataGrid-footerContainer": {
                                borderTop: "none",
                            },
                        }}
                    >
                        <DataGrid
                            rows={clientList}
                            columns={columns}
                            rowCount={controller.total}
                            page={controller.page}
                            pageSize={controller.pageSize}
                            {...config.TABLE_CONFIG}
                        />
                    </Box>
                </Box>
                <Modal
                    className="delete-modal"
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                >
                    <div className="modal-bottom">
                        <ModalWindow
                            title={__("Are you sure?")}
                            text={__(
                                "Are you sure you want to permanently delete this client?",
                            )}
                            onClick={closeModal}
                            onClickDelete={removeClient}
                        />
                    </div>
                </Modal>
            </div>
        </div>
    );
};

export default PageClientTable;
