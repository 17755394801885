import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { translate } from "../../../translations/translate";
import { useLocation, useNavigate, useParams } from "react-router";
import { ApiService } from "../../../utils/ApiService";
import { errorToast, successToast } from "../../../utils/toast";
import Button from "../../../components/global/Button";
import PageHeader from "../../../components/PageHeader";
import BreadCrumbs from "../../../components/BreadCrumbs";
import cleanseObject from "../../../utils/cleanseObject";

import "./styles.sass";

const PageCashDeskEditEps = ({ ...props }) => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { id } = useParams();
    const lang = useSelector((state) => state.languageReducer.lang);
    const __ = (key) => translate(lang, key);
    const { token } = useSelector((state) => state.authReducer);

    const {
        formState: { errors },
        handleSubmit,
    } = useForm();
    const onCancelClick = function () {
        navigate("/cashdesks");
    };
    const buttons = [
        {
            name: "Back",
            link: `/cashdesks/${state?.cashdesk?._id || id}/edit`,
            state: {},
            className: "outline",
        },
    ];
    const eps = state?.eps;

    const [obj, setObj] = useState({
        EPS: {
            click: {
                SERVICE_ID: eps?.click?.SERVICE_ID,
                MERCHANT_ID: eps?.click?.MERCHANT_ID,
                SECRET_KEY: eps?.click?.SECRET_KEY,
                MERCHANT_USER_ID: eps?.click?.MERCHANT_USER_ID,
                USERNAME: eps?.click?.USERNAME,
                PASSWORD: eps?.click?.PASSWORD,
            },
            uzum: {
                SERVICE_ID: eps?.uzum?.SERVICE_ID,
                MERCHANT_ID: eps?.uzum?.MERCHANT_ID,
                SECRET_KEY: eps?.uzum?.SECRET_KEY,
                MERCHANT_USER_ID: eps?.uzum?.MERCHANT_USER_ID,
            },
            payme: {
                ID: eps?.payme?.ID,
                Key: eps?.payme?.Key,
            },
            anor: {
                BRANCH_ID: eps?.anor?.BRANCH_ID,
                SECRET_KEY: eps?.anor?.SECRET_KEY,
            },
            alifpay: {
                TOKEN: eps?.alifpay?.TOKEN,
                SECRET_KEY: eps?.alifpay?.SECRET_KEY
            }
        },
    });

    const onSubmit = (e) => {
        const body = cleanseObject(obj);

        ApiService.patchEvent(
            `/cashdesks/${state?.cashdesk?._id || id}/eps`,
            token,
            body,
        )
            .then((response) => {
                if (response.status === 200) {
                    successToast(__("Successfully updated"));
                    navigate("/cashdesks");
                }
            })
            .catch((error) => {
                errorToast(__(error?.message));
            });
    };

    return (
        <div>
            <BreadCrumbs
                breadcrumbs={[
                    { label: "Cashdesks", link: "/cashdesks" },
                    { label: "EPS" },
                ]}
            />
            <div {...props} className="page component-page-cashdesk-edit">
                <form onSubmit={handleSubmit(onSubmit)} className="page-inner">
                    <PageHeader name={__("Cashdesk EPS")} buttons={buttons} />
                    <div className="cards">
                        <div className="left-side-cards">
                            <div className="component-box-card">
                                <div className="card-header">
                                    <div className="card-info">
                                        <div className="card-icon">
                                            <span className="uzum" />
                                        </div>
                                        <div className="card-text">
                                            <p className="card-title">
                                                {__("Uzum")}
                                            </p>
                                            <p className="card-subtitle" />
                                        </div>
                                    </div>
                                </div>
                                <div className="input-item">
                                    <div className="component-input">
                                        <label
                                            className="input-label"
                                        >
                                            <input
                                                placeholder="Enter Merchant ID"
                                                className="input"
                                                type="text"
                                                value={obj.EPS?.uzum?.MERCHANT_ID}
                                                onChange={(e) => {
                                                    setObj({
                                                        ...obj,
                                                        EPS: {
                                                            ...obj.EPS,
                                                            uzum: {
                                                                ...obj?.EPS
                                                                    ?.uzum,
                                                                MERCHANT_ID:
                                                                    e.target
                                                                        .value,
                                                            },
                                                        },
                                                    });
                                                }}
                                            />
                                            <span className="input-text">
                                                {__("Merchant ID")}
                                            </span>
                                        </label>
                                    </div>
                                    <div className="component-input">
                                        <label
                                            className="input-label"
                                        >
                                            <input
                                                placeholder="Enter Service ID"
                                                className="input"
                                                type="text"
                                                value={obj.EPS?.uzum?.SERVICE_ID}
                                                onChange={(e) => {
                                                    setObj({
                                                        ...obj,
                                                        EPS: {
                                                            ...obj.EPS,
                                                            uzum: {
                                                                ...obj?.EPS
                                                                    ?.uzum,
                                                                SERVICE_ID:
                                                                    e.target
                                                                        .value,
                                                            },
                                                        },
                                                    });
                                                }}
                                            />
                                            <span className="input-text">
                                                {__("Service ID")}
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                <div className="input-item">
                                    <div className="component-input">
                                        <label
                                            className="input-label"
                                        >
                                            <input
                                                placeholder="Enter Merchant User ID"
                                                className="input"
                                                type="text"
                                                value={obj.EPS?.uzum?.MERCHANT_USER_ID}
                                                onChange={(e) => {
                                                    setObj({
                                                        ...obj,
                                                        EPS: {
                                                            ...obj.EPS,
                                                            uzum: {
                                                                ...obj?.EPS
                                                                    ?.uzum,
                                                                MERCHANT_USER_ID:
                                                                    e.target
                                                                        .value,
                                                            },
                                                        },
                                                    });
                                                }}
                                            />
                                            <span className="input-text">
                                                {__("Merchant User ID")}
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                <div className="input-item">
                                    <div className="component-input">
                                        <label
                                            className="input-label"
                                        >
                                            <input
                                                placeholder="Enter Secret Key"
                                                className="input"
                                                type="text"
                                                value={obj.EPS?.uzum?.SECRET_KEY}
                                                onChange={(e) => {
                                                    setObj({
                                                        ...obj,
                                                        EPS: {
                                                            ...obj.EPS,
                                                            uzum: {
                                                                ...obj?.EPS
                                                                    ?.uzum,
                                                                SECRET_KEY:
                                                                    e.target
                                                                        .value,
                                                            },
                                                        },
                                                    });
                                                }}
                                            />
                                            <span className="input-text">
                                                {__("Secret Key")}
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="component-box-card">
                                <div className="card-header">
                                    <div className="card-info">
                                        <div className="card-icon">
                                            <span className="anor"></span>
                                        </div>
                                        <div className="card-text">
                                            <p className="card-title">
                                                {__("Anor")}
                                            </p>
                                            <p className="card-subtitle" />
                                        </div>
                                    </div>
                                </div>
                                <div className="input-item">
                                    <div className="component-input">
                                        <label
                                            className="input-label"
                                        >
                                            <input
                                                placeholder="Enter Branch ID"
                                                className="input"
                                                type="text"
                                                value={obj.EPS?.anor?.BRANCH_ID}
                                                onChange={(e) => {
                                                    setObj({
                                                        ...obj,
                                                        EPS: {
                                                            ...obj.EPS,
                                                            anor: {
                                                                ...obj?.EPS
                                                                    ?.anor,
                                                                BRANCH_ID:
                                                                    e.target
                                                                        .value,
                                                            },
                                                        },
                                                    });
                                                }}
                                            />
                                            <span className="input-text">
                                                {__("Branch ID")}
                                            </span>
                                        </label>
                                    </div>
                                    <div className="component-input">
                                        <label
                                            className="input-label"
                                        >
                                            <input
                                                placeholder="Enter Service KEY"
                                                className="input"
                                                type="text"
                                                value={obj.EPS?.anor?.SECRET_KEY}
                                                onChange={(e) => {
                                                    setObj({
                                                        ...obj,
                                                        EPS: {
                                                            ...obj.EPS,
                                                            anor: {
                                                                ...obj?.EPS
                                                                    ?.anor,
                                                                SECRET_KEY:
                                                                    e.target
                                                                        .value,
                                                            },
                                                        },
                                                    });
                                                }}
                                            />
                                            <span className="input-text">
                                                {__("Secret Key")}
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="component-box-card">
                                <div className="card-header">
                                    <div className="card-info">
                                        <div className="card-icon">
                                            <span className="payme" />
                                        </div>
                                        <div className="card-text">
                                            <p className="card-title">
                                                {__("Payme")}
                                            </p>
                                            <p className="card-subtitle" />
                                        </div>
                                    </div>
                                </div>
                                <div className="input-item">
                                    <div className="component-input">
                                        <label
                                            className="input-label"
                                        >
                                            <input
                                                placeholder="Enter ID"
                                                className="input"
                                                type="text"
                                                value={obj.EPS?.payme?.ID}
                                                onChange={(e) => {
                                                    setObj({
                                                        ...obj,
                                                        EPS: {
                                                            ...obj.EPS,
                                                            payme: {
                                                                ...obj?.EPS
                                                                    ?.payme,
                                                                ID: e.target
                                                                    .value,
                                                            },
                                                        },
                                                    });
                                                }}
                                            />
                                            <span className="input-text">
                                                {__("ID")}
                                            </span>
                                        </label>
                                    </div>
                                    <div className="component-input">
                                        <label
                                            className="input-label"
                                        >
                                            <input
                                                placeholder="Enter KEY"
                                                className="input"
                                                type="text"
                                                value={obj.EPS?.payme?.Key}
                                                onChange={(e) => {
                                                    setObj({
                                                        ...obj,
                                                        EPS: {
                                                            ...obj.EPS,
                                                            payme: {
                                                                ...obj?.EPS
                                                                    ?.payme,
                                                                Key: e.target
                                                                    .value,
                                                            },
                                                        },
                                                    });
                                                }}
                                            />
                                            <span className="input-text">
                                                {__("Key")}
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="component-box-card">
                                <div className="card-header">
                                    <div className="card-info">
                                        <div className="card-icon">
                                            <span className="click" />
                                        </div>
                                        <div className="card-text">
                                            <p className="card-title">
                                                {__("Click")}
                                            </p>
                                            <p className="card-subtitle" />
                                        </div>
                                    </div>
                                </div>
                                <div className="input-item">
                                    <div className="component-input">
                                        <label
                                            className="input-label"
                                        >
                                            <input
                                                placeholder="Enter Service ID"
                                                className="input"
                                                type="text"
                                                value={obj.EPS?.click?.SERVICE_ID}
                                                onChange={(e) => {
                                                    setObj({
                                                        ...obj,
                                                        EPS: {
                                                            ...obj.EPS,
                                                            click: {
                                                                ...obj?.EPS
                                                                    ?.click,
                                                                SERVICE_ID:
                                                                    e.target
                                                                        .value,
                                                            },
                                                        },
                                                    });
                                                }}
                                            />
                                            <span className="input-text">
                                                {__("Service ID")}
                                            </span>
                                        </label>
                                    </div>
                                    <div className="component-input">
                                        <label
                                            className="input-label"
                                        >
                                            <input
                                                placeholder="Enter Merchant ID"
                                                className="input"
                                                type="text"
                                                value={obj.EPS?.click?.MERCHANT_ID}
                                                onChange={(e) => {
                                                    setObj({
                                                        ...obj,
                                                        EPS: {
                                                            ...obj.EPS,
                                                            click: {
                                                                ...obj?.EPS
                                                                    ?.click,
                                                                MERCHANT_ID:
                                                                    e.target
                                                                        .value,
                                                            },
                                                        },
                                                    });
                                                }}
                                            />
                                            <span className="input-text">
                                                {__("Merchant ID")}
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                <div className="input-item">
                                    <div className="component-input">
                                        <label
                                            className="input-label"
                                        >
                                            <input
                                                placeholder="Enter Secret KEY"
                                                className="input"
                                                type="text"
                                                value={obj.EPS?.click?.SECRET_KEY}
                                                onChange={(e) => {
                                                    setObj({
                                                        ...obj,
                                                        EPS: {
                                                            ...obj.EPS,
                                                            click: {
                                                                ...obj?.EPS
                                                                    ?.click,
                                                                SECRET_KEY:
                                                                    e.target
                                                                        .value,
                                                            },
                                                        },
                                                    });
                                                }}
                                            />
                                            <span className="input-text">
                                                {__("Secret Key")}
                                            </span>
                                        </label>
                                    </div>
                                    <div className="component-input">
                                        <label
                                            className="input-label"
                                        >
                                            <input
                                                placeholder="Enter Merchant User ID"
                                                className="input"
                                                type="text"
                                                value={obj.EPS?.click?.MERCHANT_USER_ID}
                                                onChange={(e) => {
                                                    setObj({
                                                        ...obj,
                                                        EPS: {
                                                            ...obj.EPS,
                                                            click: {
                                                                ...obj?.EPS
                                                                    ?.click,
                                                                MERCHANT_USER_ID:
                                                                    e.target
                                                                        .value,
                                                            },
                                                        },
                                                    });
                                                }}
                                            />
                                            <span className="input-text">
                                                {__("Merchant User ID")}
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                <div className="input-item">
                                    <div className="component-input">
                                        <label
                                            className="input-label"
                                        >
                                            <input
                                                placeholder="Enter User Name"
                                                className="input"
                                                type="text"
                                                value={obj.EPS?.click?.USERNAME}
                                                onChange={(e) => {
                                                    setObj({
                                                        ...obj,
                                                        EPS: {
                                                            ...obj.EPS,
                                                            click: {
                                                                ...obj?.EPS
                                                                    ?.click,
                                                                USERNAME:
                                                                    e.target
                                                                        .value,
                                                            },
                                                        },
                                                    });
                                                }}
                                            />
                                            <span className="input-text">
                                                {__("User Name")}
                                            </span>
                                        </label>
                                    </div>
                                    <div className="component-input">
                                        <label
                                            className="input-label"
                                        >
                                            <input
                                                placeholder="Enter Password"
                                                className="input"
                                                type="text"
                                                value={obj.EPS?.click?.PASSWORD}
                                                onChange={(e) => {
                                                    setObj({
                                                        ...obj,
                                                        EPS: {
                                                            ...obj.EPS,
                                                            click: {
                                                                ...obj?.EPS
                                                                    ?.click,
                                                                PASSWORD:
                                                                    e.target
                                                                        .value,
                                                            },
                                                        },
                                                    });
                                                }}
                                            />
                                            <span className="input-text">
                                                {__("Password")}
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="component-box-card">
                                <div className="card-header">
                                    <div className="card-info">
                                        <div className="card-icon">
                                            <span className="alif" />
                                        </div>
                                        <div className="card-text">
                                            <p className="card-title">
                                                {__("Alif")}
                                            </p>
                                            <p className="card-subtitle" />
                                        </div>
                                    </div>
                                </div>
                                <div className="input-item">
                                    <div className="component-input">
                                        <label
                                            className="input-label"
                                        >
                                            <input
                                                placeholder="Enter Token"
                                                className="input"
                                                type="text"
                                                value={obj.EPS?.alifpay?.TOKEN}
                                                onChange={(e) => {
                                                    setObj({
                                                        ...obj,
                                                        EPS: {
                                                            ...obj.EPS,
                                                            alifpay: {
                                                                ...obj?.EPS
                                                                    ?.alifpay,
                                                                TOKEN: e.target
                                                                    .value,
                                                            },
                                                        },
                                                    });
                                                }}
                                            />
                                            <span className="input-text">
                                                {__("Token")}
                                            </span>
                                        </label>
                                    </div>
                                    <div className="component-input">
                                        <label
                                            className="input-label"
                                        >
                                            <input
                                                placeholder="Enter KEY"
                                                className="input"
                                                type="text"
                                                value={obj.EPS?.alifpay?.SECRET_KEY}
                                                onChange={(e) => {
                                                    setObj({
                                                        ...obj,
                                                        EPS: {
                                                            ...obj.EPS,
                                                            alifpay: {
                                                                ...obj?.EPS
                                                                    ?.alifpay,
                                                                SECRET_KEY: e.target
                                                                    .value,
                                                            },
                                                        },
                                                    });
                                                }}
                                            />
                                            <span className="input-text">
                                                {__("Key")}
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="component box-save">
                        <div className="not-save-item">
                            <div className="not-save-icon">
                                <i className="icon-saved"></i>
                            </div>
                            <div className="not-save-text">
                                <p className="save-text">
                                    {__("Not Saved Yet")}
                                </p>
                            </div>
                        </div>
                        <div className="not-save-button">
                            <Button
                                className="btn outline sm"
                                text={__("Cancel")}
                                onClick={onCancelClick}
                                type="button"
                            />
                            <Button
                                className="btn sm"
                                text={__("Save")}
                                type="submit"
                            />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default PageCashDeskEditEps;
