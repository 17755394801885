import React, { useState } from "react";
import { IMaskInput } from "react-imask";
import { useSelector } from "react-redux";
import { translate } from "../../../translations/translate";
import { ApiService } from "../../../utils/ApiService";
import { errorToast, successToast } from "../../../utils/toast";
import { useFormatMask } from "../../../hooks/useFormatMask";
import { useNavigate } from "react-router";

import Button from "../../../components/global/Button";
import PageHeader from "../../../components/PageHeader";
import Input from "../../../components/Input";
import UIAvatar from "../../../components/Avatar";
import BreadCrumbs from "../../../components/BreadCrumbs";

import "./styles.sass";
import BasicDatePicker from "../../../components/DatePicker";
import { formatDate } from "../../../utils/formatDate";
import { StyledEngineProvider } from "@mui/styled-engine-sc";
import DragAndDrop from "../../DragAndDrop";
import cleanseObject from "../../../utils/cleanseObject";
import { capitalize } from "../../../utils/capitalize";

const PageClientCreate = () => {
    const navigate = useNavigate();

    const PhoneMask = "+998 (00) 000-00-00";
    const phoneEmailMask = [
        {
            mask: PhoneMask,
        },
    ];

    const [isValid, setIsValid] = useState(true);
    const [first, setFirstName] = useState("");
    const [last, setLastName] = useState("");
    const [middle, setMiddleName] = useState("");
    const [avatar, setAvatar] = useState(null);
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [birthDate, setBirthDate] = useState(new Date());
    const [series] = useState("");
    const [number] = useState("");
    const [password, setPassword] = useState("");
    const [displayFirstName] = useState("");
    const [displayLastName] = useState("");
    const [displayMiddleName] = useState("");
    const [passport, setPassport] = useState(null);

    const { token } = useSelector((state) => state.authReducer);
    const lang = useSelector((state) => state.languageReducer.lang);
    const __ = (key) => translate(lang, key);


    const [isSubmitting, setIsSubmitting] = useState(false);

    // CALLBACKS
    const updatePassword = (password) => {
        setPassword(password);
    };
    const onBirthDateChange = (e) => {
        setBirthDate(e?.$d);
    };

    const isValidPassword = () => {
        // Checks if password contains at least 1 letter and number and no spaces
        return /^(?=.*[a-zA-Z])(?=.*\d).*$/.test(password) &&
            !password.includes(" ");
    };

    const onCancelClick = function () {
        navigate("/clients");
    };

    // FOR AVATAR IMAGE UPLOAD
    const [isDragNDropOpen, setIsDragNDropOpen] = useState(false);
    const [isPassportDragNDropOpen, setIsPassportDragNDropOpen] = useState(false);

    const handleDragNDropOpen = () => {
        setIsDragNDropOpen(!isDragNDropOpen);
    };

    const togglePassportDragNDrop = () => {
        setIsPassportDragNDropOpen((prevState) => !prevState);
    };

    const [avatarUrl, setAvatarUrl] = useState("");
    const handleSetAvatar = (url) => {
        setAvatarUrl(url.url);
        setAvatar(url.id);
    };

    const handleSetPassport = (url) => {
        setPassport({
            name: url.name,
            id: url.id
        });
    };

    const onSubmit = (e) => {
        e.preventDefault();

        if (phone?.length < 13) {
            errorToast(__("Incorrect length of the phone number"));
            return;
        }

        if (isValidPassword()) {
            setIsValid(true);
            const body = {
                name: {
                    first,
                    last,
                    middle,
                },
                avatar,
                email,
                phone,
                password,
                birthDate: birthDate ? formatDate(birthDate) : "",
                passport: {
                    series,
                    number,
                    expireDate: "",
                },
                displayName: {
                    first: displayFirstName,
                    last: displayLastName,
                    middle: displayMiddleName,
                },
                passportDoc: passport?.id,
                createdByUser: true,
            };
            if (isSubmitting) return;
            setIsSubmitting(true);
            ApiService.postEvent("/clients/create", token, cleanseObject(body))
                .then((response) => {
                    if (response.status === 201) {
                        successToast(__("Successfully created"));
                        setIsSubmitting(false);

                        navigate("/businesses/create", {state: {
                            client: response.data,
                        }});
                    } else {
                        errorToast(__(response.message));
                    }
                })
                .catch((error) => {
                    setIsSubmitting(false);
                    errorToast(__(error.message));
                });
            return;
        }
        setIsValid(false);
    };

    const buttons = [
        {
            name: "Back",
            link: "/clients",
            state: {},
            className: "outline",
        },
    ];

    return (
        <div>
            <BreadCrumbs
                breadcrumbs={[
                    { label: "Clients", link: "/clients" },
                    { label: "Create" },
                ]}
            />

            <div className="page component-page-user-create">
                <form onSubmit={onSubmit} className="page-inner">
                    <PageHeader name={__("Create Client")} buttons={buttons} />
                    <div className="cards">
                        <div className="left-side-cards">
                            <div className="component-box-card">
                                <div className="card-header">
                                    <div className="card-info">
                                        <div className="card-icon">
                                            <i className="icon-profile" />
                                        </div>
                                        <div className="card-text">
                                            <p className="card-title">
                                                {__("Client Information")}
                                            </p>
                                            <p className="card-subtitle" />
                                        </div>
                                    </div>
                                </div>
                                <div className="input-column">
                                    <UIAvatar
                                        onClick={handleDragNDropOpen}
                                        url={avatarUrl || ""}
                                        fileId={avatar}
                                        onRemoveSuccess={() => setAvatarUrl("")}
                                    />
                                    <div className="input-row">
                                        <div className="input-item">
                                            <div className="component-input">
                                                <label
                                                    className="input-label"
                                                >
                                                    <input
                                                        value={first}
                                                        onChange={(e) =>
                                                            setFirstName(capitalize(e.target.value))
                                                        }
                                                        placeholder={__(
                                                            "Enter your first name",
                                                        )}
                                                        className="input"
                                                        required
                                                        type="text"
                                                        onInvalid={e => e.target.setCustomValidity(__("Enter your first name"))}
                                                        onInput={e => e.target.setCustomValidity("")}
                                                    />
                                                    <span className="input-text">
                                                        {__("First Name")}
                                                    </span>
                                                </label>
                                            </div>
                                            <div className="component-input">
                                                <label
                                                    className="input-label"
                                                >
                                                    <input
                                                        value={last}
                                                        onChange={(e) =>
                                                            setLastName(
                                                                capitalize(e.target.value)
                                                            )
                                                        }
                                                        placeholder={__(
                                                            "Enter your last name",
                                                        )}
                                                        className="input"
                                                        type="text"
                                                        onInvalid={e => e.target.setCustomValidity(__("Enter your last name"))}
                                                        onInput={e => e.target.setCustomValidity("")}
                                                        required
                                                    />
                                                    <span className="input-text">
                                                        {__("Last Name")}
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="input-item">
                                            <div className="component-input">
                                                <label
                                                    className="input-label"
                                                >
                                                    <input
                                                        value={middle}
                                                        onChange={(e) =>
                                                            setMiddleName(
                                                                capitalize(e.target.value)
                                                            )
                                                        }
                                                        placeholder={__(
                                                            "Enter your middle name",
                                                        )}
                                                        className="input"
                                                        type="text"
                                                    />
                                                    <span className="input-text">
                                                        {__("Middle Name")}
                                                    </span>
                                                </label>
                                            </div>
                                            <div className="component-input">
                                                <label
                                                    className="input-label"
                                                >
                                                    <span className="input-text date-picker">
                                                        {__("Date of Birth")}
                                                    </span>
                                                    <StyledEngineProvider>
                                                        <BasicDatePicker
                                                            value={birthDate || ""}
                                                            onChange={onBirthDateChange}
                                                            selectedDate={birthDate}
                                                            setSelectedDate={setBirthDate}
                                                        />
                                                    </StyledEngineProvider>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="input-item">
                                            <div className="component-input">
                                                <label
                                                    className="input-label"
                                                >
                                                    <IMaskInput
                                                        value={phone}
                                                        onChange={(e) =>
                                                            setPhone(
                                                                useFormatMask(
                                                                    e.target
                                                                        .value,
                                                                ),
                                                            )
                                                        }
                                                        onPaste={(e) =>
                                                            setPhone(
                                                                useFormatMask(
                                                                    e.clipboardData.getData(
                                                                        "text",
                                                                    ),
                                                                ),
                                                            )
                                                        }
                                                        autoComplete="off"
                                                        className="mask-on-input card-input input"
                                                        type="tel"
                                                        placeholder="+(998) 99 222 33 44"
                                                        mask={phoneEmailMask}
                                                        required
                                                        onInvalid={e => e.target.setCustomValidity(__("Phone"))}
                                                        onInput={e => e.target.setCustomValidity("")}
                                                    />
                                                    <span className="input-text">
                                                        {__("Phone")}
                                                    </span>
                                                </label>
                                            </div>
                                            <div className="component-input">
                                                <label
                                                    className="input-label"
                                                >
                                                    <input
                                                        value={email}
                                                        onChange={(e) =>
                                                            setEmail(
                                                                e.target.value,
                                                            )
                                                        }
                                                        placeholder={__(
                                                            "Enter your email",
                                                        )}
                                                        className="input"
                                                        type="email"
                                                    />
                                                    <span className="input-text">
                                                        {__("Email")}
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="component-box-card">
                                <div className="card-header">
                                    <div className="card-info">
                                        <div className="card-icon">
                                            <i className="icon-information" />
                                        </div>
                                        <div className="card-text">
                                            <p className="card-title">
                                                {__("Passport Information")}
                                            </p>
                                            <p className="card-subtitle" />
                                        </div>
                                    </div>
                                </div>
                                <div className="files-block">
                                    <div className="file-row">
                                        <div className="file-card disabled">
                                              <p className="text">{passport?.name || ""}</p>
                                             <p className="label">{__("Client's Passport Scan")}</p>
                                        </div>
                                        <div
                                          className={"file-card custom"}>
                                            <input
                                              value={__("Client's Passport Scan")}
                                              disabled
                                              type="text"
                                              className="text"
                                            />
                                        </div>
                                        <div className="component-card-button">
                                            {passport?.id
                                              ? (
                                                <button
                                                  onClick={(e) => {
                                                      e.preventDefault();
                                                      setPassport(null);
                                                  }}
                                                  className="btn squared error"
                                                >
                                                    <i className="icon-delete"></i>
                                                </button>
                                              )
                                              : (
                                                <button
                                                  onClick={(e) => {
                                                      e.preventDefault();
                                                      togglePassportDragNDrop();
                                                  }}
                                                  className="btn squared"
                                                >
                                                    <i className="icon-upload"></i>
                                                </button>
                                              )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="component-box-card">
                                <div className="card-header">
                                    <div className="card-info">
                                        <div className="card-icon">
                                            <i className="icon-security" />
                                        </div>
                                        <div className="card-text">
                                            <p className="card-title">
                                                {__("Password")}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="input-item">
                                    <label className="component-input">
                                        <Input
                                            type="password"
                                            text={__("Password")}
                                            className="ui-input"
                                            updatePassword={updatePassword}
                                            isClientPassword={true}
                                        />

                                        {!isValid && (
                                            <div className="password-input__validation">
                                                <p className="validation__text">
                                                    Password must contain no
                                                    space, at least 1 letter and
                                                    number
                                                </p>
                                            </div>
                                        )}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="component box-save">
                        <div className="not-save-item">
                            <div className="not-save-icon">
                                <i className="icon-unsaved" />
                            </div>
                            <div className="not-save-text">
                                <p className="save-text">
                                    {__("Not Saved Yet")}
                                </p>
                            </div>
                        </div>
                        <div className="not-save-button">
                            <Button
                                className="btn outline sm"
                                text={__("Cancel")}
                                onClick={onCancelClick}
                                type="button"
                            />
                            <Button
                                className="btn sm"
                                text={__("Save")}
                                type="submit"
                                disabled={isSubmitting}
                            />
                            {isDragNDropOpen &&
                                <DragAndDrop
                                    setter={handleSetAvatar}
                                    closeDragNDrop={handleDragNDropOpen}
                                    uploadType={"avatar"}
                                    docModel={"User"}
                                    isImage={true}
                                />
                            }
                            {isPassportDragNDropOpen &&
                                <DragAndDrop
                                    setter={handleSetPassport}
                                    closeDragNDrop={togglePassportDragNDrop}
                                    docModel={"User"}
                               />
                            }
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default PageClientCreate;
