import "./styles.sass";
import { errorToast } from "../../../utils/toast";
import { useSelector } from "react-redux";
import { translate } from "../../../translations/translate";

const CardVerificationInput = ({ otp, setOtp, setFilled }) => {

    const lang = useSelector((state) => state.languageReducer.lang);
    const __ = (key) => translate(lang, key);

    const onOTPPaste = (event) => {
        event.preventDefault();

        // Get the clipboard data
        const clipboardData = (event.clipboardData || window.clipboardData).getData("text");

        // SETTING OTP TO PASTED VALUE
        const pastedArray = clipboardData.trim().split("");
        if(pastedArray?.length !== 6) {
            errorToast(__("You pasted wrong OTP format"));
            return;
        }
        setOtp(pastedArray);
        if(pastedArray.length === 6) {
            setFilled(5);
        }
    };
    const handleChange = (event, index) => {
        const element = event.target;
        if (isNaN(element.value) || element.value === " ") return false;
        setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);
        if (index === 5) {
            let filled = 0;
            otp.forEach(num => {
                if (num) filled += 1;
            });
            setFilled(filled);
        }
        if (element.nextSibling) {
            element.nextSibling.focus();
        }
    };
    const handleKeyDown = (event, index) => {
        const element = event.target;
        if (event.key === "Backspace") {
            event.preventDefault();
            element.value = "";
            setOtp([...otp.map((d, idx) => (idx === index ? "" : d))]);
            if (element.previousSibling) {
                setFilled((prevState) => prevState - 1);
                element.previousSibling.focus();
                if (window.getSelection) {
                    window.getSelection().removeAllRanges();
                }
            }
        }
    };

    return (
        <div className="component-card-verification-input">
            <label>
                {otp.map((data, index) => {
                    return (
                        <input
                            className="input-otp"
                            type="text"
                            name="otp"
                            maxLength={1}
                            key={index}
                            value={data}
                            onChange={(e) => handleChange(e, index)}
                            onKeyDown={(e) => handleKeyDown(e, index)}
                            onFocus={(e) => e.target.select()}
                            onPaste={onOTPPaste}
                        />
                    );
                })}
            </label>
        </div>
    );
};

export default CardVerificationInput;
