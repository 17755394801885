import React, { useState, useEffect } from "react";
import { IMaskInput } from "react-imask";
import { useSelector } from "react-redux";
import { ApiService } from "../../../utils/ApiService";
import { translate } from "../../../translations/translate";
import { errorToast, successToast } from "../../../utils/toast";
import { useLocation, useNavigate, useParams } from "react-router";
import config from "../../../config";
import useApiHook from "../../../hooks/useApiHook";
import Button from "../../../components/global/Button";
import PageHeader from "../../../components/PageHeader";
import Preloader from "../../../components/Preloader";
import BreadCrumbs from "../../../components/BreadCrumbs";
import "./styles.sass";
import { searchStaff } from "../../../utils/globalAPIRequests/searchStaff";
import DragAndDrop from "../../DragAndDrop";
import { url as genURL } from "../../../utils/generalizeURL";
import TextArea from "../../../components/TextArea/TextArea";
import { replaceNewlinesWithSpaces } from "../../../utils/helper";

const PageBranchEdit = ({ ...props }) => {
    const { token } = useSelector((state) => state.authReducer);

    const navigate = useNavigate();

    const PhoneMask = "+998 (00) 000-00-00";
    const lang = useSelector((state) => state.languageReducer.lang);
    const __ = (key) => translate(lang, key);
    const storedUserData = useSelector(state => state.authReducer);
    const phoneEmailMask = [
        {
            mask: PhoneMask,
        },
    ];

    // MANAGER
    const [managerSearch] = useState("");
    const [manager] = useState(null);
    const [managerSearchResults, setManagerSearchResults] = useState([]);
    const [_, setApproved] = useState(null);

    useEffect(() => {
        if (managerSearch.length > 0) {
            (async () => {
                return await searchStaff(data?.data?.docs[0].businessID._id, managerSearch, token);
            })().then((result) => {
                setManagerSearchResults(result);
            });
        } else {
            setManagerSearchResults(null);
        }
    }, [managerSearch]);

    const { state } = useLocation();
    const { id } = useParams();
    const url = `${config.API_BASE_URL}/branches/${state?.branch?.id || id}`;
    const { data, loading, error } = useApiHook(url);
    const [equal, setEqual] = useState(true);
    const [branchInitial, setBranchInitial] = useState({});
    const [branchCurrent, setBranchCurrent] = useState({});
    const [users, setUsers] = useState(false);
    const [managers, setManagers] = useState(false);

    const handleUsers = () => {
        setUsers(!users);
    };

    document.body.addEventListener("click", (e) => {
        if (e.target.className !== "component-switcher-handler" && users)
            handleUsers();
    });

    const handleManagers = () => {
        setManagers(!managers);
    };

    document.body.addEventListener("click", (e) => {
        if (e.target.className !== "component-switcher-handler" && managers)
            handleManagers();
    });

    useEffect(() => {
        if (data?.data?.docs) {
            data?.data?.docs?.map((item) => {
                if (!item.documents.length) {
                    setDocuments([{
                        index: 0,
                        documentType: {
                            key: "rentContract",
                            description: "Rent Contract",
                        },
                    }]);
                } else {
                    setDocuments(item.documents.map((document, index) => ({
                        index: index,
                        documentType: document.documentType,
                        _id: document._id,
                        name: document?.id?.fileInfo?.originalName,
                        url: document?.id?.fileInfo?.url,
                    })));
                }
                setApproved(item.approved.isApproved);
                setBranchInitial({
                    name: item.name,
                    documents: item.documents.map((document, index) => ({
                        index: index,
                        documentType: document.documentType,
                        _id: document._id,
                        name: document?.id?.fileInfo?.originalName,
                    })),
                    phones: Array.isArray(item?.phones) ? item.phones[0] : item?.phones,
                    state: item.address?.state,
                    region: item.address?.region,
                    city: item.address?.city,
                    district: item.address?.district,
                    street: item.address?.street,
                    house: item.address?.house,
                    ZIPCode: item.address?.ZIPCode,
                    contacts: {
                        responsiblePerson: {
                            name: {
                                first: item?.contacts?.responsiblePerson?.name?.first,
                                last: item?.contacts?.responsiblePerson?.name?.last,
                                middle: item?.contacts?.responsiblePerson?.name?.middle,
                            },
                            phone: item?.contacts?.responsiblePerson?.phone,
                            clientID: item?.contacts?.responsiblePerson?.clientID?._id,
                        },
                        manager: {
                            name: {
                                first: item?.contacts?.manager?.name?.first,
                                last: item?.contacts?.manager?.name?.last,
                                middle: item?.contacts?.manager?.name?.middle,
                            },
                            phone: item?.contacts?.manager?.phone,
                            clientID: item?.contacts?.manager?.clientID?._id,
                        }
                    },
                });
                setBranchCurrent({
                    name: item.name,
                    documents: item.documents.map((document, index) => ({
                        index: index,
                        documentType: document.documentType,
                        _id: document._id,
                        name: document?.id?.fileInfo?.originalName,
                    })),
                    phones: Array.isArray(item?.phones) ? item.phones[0] : item?.phones,
                    state: item.address?.state,
                    region: item.address?.region,
                    city: item.address?.city,
                    district: item.address?.district,
                    street: item.address?.street,
                    house: item.address?.house,
                    ZIPCode: item.address?.ZIPCode,
                    managerFirst: item?.contacts?.manager?.name?.first,
                    managerLast: item?.contacts?.manager?.name?.last,
                    managerMiddle: item?.contacts?.manager?.name?.middle,
                    managerPhone: item?.contacts?.manager?.phone,
                    contacts: {
                        responsiblePerson: {
                            name: {
                                first: item?.contacts?.responsiblePerson?.name?.first,
                                last: item?.contacts?.responsiblePerson?.name?.last,
                                middle: item?.contacts?.responsiblePerson?.name?.middle,
                            },
                            phone: item?.contacts?.responsiblePerson?.phone,
                            clientID: item?.contacts?.responsiblePerson?.clientID?._id,
                        },
                        manager: {
                            name: {
                                first: item?.contacts?.manager?.name?.first,
                                last: item?.contacts?.manager?.name?.last,
                                middle: item?.contacts?.manager?.name?.middle,
                            },
                            phone: item?.contacts?.manager?.phone,
                            clientID: item?.contacts?.manager?.clientID?._id,
                        }
                    },
                });
            });
        }
    }, [data]);

    useEffect(() => {
        if (
            branchInitial.name === branchCurrent.name &&
            branchInitial?.documents?.[0]?._id === branchCurrent?.documents?.[0]?._id &&
            branchInitial.phones === branchCurrent.phones &&
            branchInitial.middle === branchCurrent.middle &&
            branchInitial.state === branchCurrent.state &&
            branchInitial.region === branchCurrent.region &&
            branchInitial.city === branchCurrent.city &&
            branchInitial.district === branchCurrent.district &&
            branchInitial.street === branchCurrent.street &&
            branchInitial.house === branchCurrent.house &&
            branchInitial.ZIPCode === branchCurrent.ZIPCode &&
            branchInitial.contacts?.manager?.name?.first === branchCurrent.contacts?.manager?.name?.first &&
            branchInitial.contacts?.manager?.name?.last === branchCurrent.contacts?.manager?.name?.last &&
            branchInitial.contacts?.manager?.name?.middle === branchCurrent.contacts?.manager?.name?.middle &&
            branchInitial.contacts?.manager?.phone === branchCurrent.contacts?.manager?.phone &&
            branchInitial?.contacts?.responsiblePerson?.name?.first === branchCurrent?.contacts?.responsiblePerson?.name?.first &&
            branchInitial?.contacts?.responsiblePerson?.name?.last === branchCurrent?.contacts?.responsiblePerson?.name?.last &&
            branchInitial?.contacts?.responsiblePerson?.name?.middle ===
            branchCurrent?.contacts?.responsiblePerson?.name?.middle &&
            branchInitial?.contacts?.responsiblePerson?.phone === branchCurrent?.contacts?.responsiblePerson?.phone
        ) {
            setEqual(true);
        } else setEqual(false);
    }, [branchCurrent]);

    const onCancelClick = function () {
        navigate("/branches");
    };

    // DOCUMENTS
    const [currentDocType, setCurrentDocType] = useState("");
    const [currentCustomDescription, setCurrentCustomDescription] = useState("");
    const [isDragNDropOpen, setIsDragNDropOpen] = useState(false);
    const handleDragNDropOpen = () => {
        setIsDragNDropOpen(!isDragNDropOpen);
    };
    const [documents, setDocuments] = useState([]);
    const handleSetDocument = (obj) => {
        let documentsTemp = documents;
        if (currentDocType === "custom") {
            const index = documentsTemp.length;
            const docObject = {
                index: index,
                documentType: {
                    key: currentDocType,
                    description: currentCustomDescription
                },
                _id: obj.id,
                name: obj.name,
                url: obj.url,
            };
            if (Array.isArray(documentsTemp)) {
                documentsTemp.push(docObject);
            }
            else {
                documentsTemp = [];
                documentsTemp.push(docObject);
            }
            setCurrentCustomDescription("");
        }
        else {
            documentsTemp.forEach((doc, i, arr) => {
                if (doc.documentType.key === currentDocType) {
                    arr[i]["_id"] = obj.id;
                    arr[i]["name"] = obj.name;
                    arr[i]["url"] = obj.url;
                }
            });
        }
        setDocuments([...documentsTemp]);
        setCurrentDocType(null);
        setBranchCurrent({
            ...branchCurrent,
            documents: documents,
        });
    };
    const handleCustomDocumentDescription = (index, description) => {
        const documentsTemp = documents;
        documentsTemp[index].documentType.description = description;
        setDocuments([...documentsTemp]);
        // setBranch({
        //     ...branch,
        //     documents: documents,
        // });
    };
    const deleteDoc = () => {
        setDocuments([]);
        setBranchCurrent({
            ...branchCurrent,
            documents: [],
        });
    };
    const onSubmit = (e) => {
        e.preventDefault();
        const phones = Array.isArray(branchCurrent?.phones)
            ? branchCurrent?.phones
            : [branchCurrent.phones];
        const body = {
            name: branchCurrent.name,
            phones: phones,
            address: {
                state: branchCurrent.state,
                region: branchCurrent.region,
                city: branchCurrent.city,
                district: branchCurrent.district,
                street: branchCurrent.street,
                house: branchCurrent.house,
                ZIPCode: branchCurrent.ZIPCode,
            },
            contacts: {
                manager: {
                    name: {
                        first: branchCurrent.contacts.manager.name.first,
                        last: branchCurrent.contacts.manager.name.last,
                        middle: branchCurrent.contacts.manager.name.middle,
                    },
                    phone: branchCurrent.contacts.manager.phone,
                },
                responsiblePerson: {
                    name: {
                        first: branchCurrent.responsibleFirst,
                        last: branchCurrent.responsibleLast,
                        middle: branchCurrent.responsibleMiddle,
                    },
                    phone: branchCurrent.responsiblePhone,
                },
            },
        };
        if (branchInitial.documents?.[0]?._id !== branchCurrent.documents?.[0]?._id) {
            body.documents = branchCurrent.documents.map(document => ({
                documentType: document.documentType,
                id: storedUserData.profileData._id,
                _id: document._id,
            }));
        }
        ApiService.patchEvent(
            `/branches/${data?.data?.docs[0]?._id}`,
            token,
            body,
        )
            .then((value) => {
                if (value.status === 200) {
                    successToast(__("Successfully updated"));
                    navigate(`/branches/${state?.branch?.id || id}`);
                }
            })

            .catch((error) => {
                errorToast(__(error.message));
            });
    };

    if (loading)
        return (
            <div>
                <Preloader />
            </div>
        );
    if (error) return <div>{errorToast}</div>;
    const buttons = [
        {
            name: "Back",
            link: "/branches",
            state: {},
            className: "outline",
        }
    ];


    return (
        <div>
            <BreadCrumbs
                breadcrumbs={[
                    { label: "Branches", link: "/branches" },
                    { label: "Edit" },
                ]}
            />
            <div {...props} className="page component-page-branch-edit">
                <form onSubmit={onSubmit} className="page-inner">
                    <PageHeader name={__("Edit Branch")} buttons={buttons} />
                    <div className="cards">
                        <div className="left-side-cards">
                            {data?.data?.docs?.map((item, i) => (
                                <div key={i}>
                                    <div className="component-box-card">
                                        <div className="card-header">
                                            <div className="card-info">
                                                <div className="card-icon">
                                                    <i className="icon-information" />
                                                </div>
                                                <div className="card-text">
                                                    <p className="card-title">
                                                        {__(
                                                            "Branch Information",
                                                        )}
                                                    </p>
                                                    <p className="card-subtitle" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="input-item">
                                            <div className="component-input">
                                                <label
                                                    className="input-label"
                                                >
                                                    <input
                                                        defaultValue={item.name}
                                                        onChange={(e) =>
                                                            setBranchCurrent({
                                                                ...branchCurrent,
                                                                name: e.target
                                                                    .value,
                                                            })
                                                        }
                                                        placeholder={__(
                                                            "Enter branch name",
                                                        )}
                                                        className="input"
                                                        type="text"
                                                    />
                                                    <span className="input-text">
                                                        {__("Branch Name")}
                                                    </span>
                                                </label>
                                            </div>
                                            <div className="component-input">
                                                <label
                                                    className="input-label"
                                                >
                                                    <IMaskInput
                                                        className="mask-on-input card-input input"
                                                        type="tel"
                                                        placeholder="+(998) 99 222 33 44"
                                                        mask={phoneEmailMask}
                                                        autoComplete="off"
                                                        value={branchCurrent?.phones}
                                                        onAccept={(value) => {
                                                            setBranchCurrent({
                                                                ...branchCurrent,
                                                                phones: value
                                                            });
                                                        }}
                                                    />
                                                    <span className="input-text">
                                                        {__("Phone")}
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="input-item">
                                            <div className="component-input">
                                                <label
                                                    className="input-label"
                                                    htmlFor="business"
                                                >
                                                    <input
                                                        defaultValue={item.businessID.name}
                                                        id="business"
                                                        className="input"
                                                        type="text"
                                                        disabled
                                                    />
                                                    <span className="input-text">
                                                        {__("Business")}
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        documents.length ? (
                                            <div className="component-box-card">
                                                <div className="card-header">
                                                    <div className="card-info">
                                                        <div className="card-icon">
                                                            <i className="icon-tie"></i>
                                                        </div>
                                                        <div className="card-text">
                                                            <p className="card-title">
                                                                {__("Documents")}
                                                            </p>
                                                            <p className="card-subtitle">{__("Upload the required documents")}</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="files-block">
                                                    {Array.isArray(documents) && documents.map((doc, index) => (
                                                        <div className="file-row" key={doc.index}>
                                                            <div className="file-card disabled">
                                                                {"" &&
                                                                    <img src="" alt="." className="icon" />
                                                                }
                                                                <p className="text">{doc?.name || ""}</p>
                                                                <p className="label">{__(doc?.documentType?.description)}</p>
                                                                {genURL(doc?.url) && doc?._id &&
                                                                    <div className="action">
                                                                        <a className="icon"
                                                                            href={genURL(doc?.url)}
                                                                            target="_blank"
                                                                            rel="noreferrer"
                                                                        >
                                                                            <i className="icon-view" />
                                                                        </a>
                                                                    </div>
                                                                }
                                                            </div>
                                                            <div className={`file-card ${(doc?.documentType?.key === "custom") ? "" : "disabled"}`}>
                                                                <input
                                                                    value={__(doc?.documentType?.description) || ""}
                                                                    disabled={!(doc?.documentType?.key === "custom")}
                                                                    onChange={(e) => {
                                                                        handleCustomDocumentDescription(index, e.target.value);
                                                                    }}
                                                                    type="text"
                                                                    className="text"
                                                                />
                                                            </div>
                                                            <div className="component-card-button">
                                                                {doc?._id
                                                                    ? (
                                                                        <button
                                                                            onClick={deleteDoc}
                                                                            className="btn squared error"
                                                                        >
                                                                            <i className="icon-delete"></i>
                                                                        </button>
                                                                    )
                                                                    : (
                                                                        <button
                                                                            onClick={(e) => {
                                                                                e.preventDefault();
                                                                                setCurrentDocType(doc?.documentType?.key);
                                                                                handleDragNDropOpen();
                                                                            }}
                                                                            className="btn squared"
                                                                        >
                                                                            <i className="icon-upload"></i>
                                                                        </button>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    ))
                                                    }
                                                </div>
                                            </div>
                                        )
                                            : null
                                    }
                                    <div className="component-box-card">
                                        <div className="card-header">
                                            <div className="card-info">
                                                <div className="card-icon">
                                                    <i className="icon-business-building-location" />
                                                </div>
                                                <div className="card-text">
                                                    <p className="card-title">
                                                        {__("Branch Address")}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ marginTop: 24 }}>
                                            <TextArea
                                                value={
                                                    branchCurrent?.region
                                                }
                                                onChange={(e) =>
                                                    setBranchCurrent({
                                                        ...branchCurrent,
                                                        region: replaceNewlinesWithSpaces(e.target.value),
                                                    })
                                                }
                                                placeholder={__("Enter Address")}
                                            />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="component box-save">
                        <div className="not-save-item">
                            <div className="not-save-icon">
                                <i className="icon-saved"/>
                            </div>
                            <div className="not-save-text">
                                <p className="save-text">
                                    {__("Not Saved Yet")}
                                </p>
                            </div>
                        </div>
                        <div className="not-save-button">
                            <Button
                              className="btn outline sm"
                              text={__("Cancel")}
                              onClick={onCancelClick}
                              type="button"
                            />
                            <Button
                              disabled={equal}
                              className="btn sm"
                              text={__("Save")}
                              type="submit"
                            />
                        </div>
                        {isDragNDropOpen && (
                          <DragAndDrop
                            setter={handleSetDocument}
                            closeDragNDrop={handleDragNDropOpen}
                            uploadType={"document"}
                            docModel={manager?._id ? "Client" : "User"}
                            ownerID={manager?._id || storedUserData._id}
                            isUpload={"true"}
                          />
                        )}
                    </div>
                </form>
            </div>
        </div>
    );
};

export default PageBranchEdit;
