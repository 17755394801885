import {useCallback, useEffect, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {debounce} from "lodash";
import {Box} from "@mui/material";

import BreadCrumbs from "../../../components/BreadCrumbs";
import PageHeader from "../../../components/PageHeader";
import SelectComponent from "../../../components/Select";
import BasicTable from "../../../components/BasicTable/BasicTable";
import Button from "../../../components/global/Button";
import TableHeader from "../../../components/TableHeader";

import {searchProductQuery} from "../../../utils/globalAPIRequests/searchProduct";
import {ApiService} from "../../../utils/ApiService";
import {errorToast, successToast} from "../../../utils/toast";
import {translate} from "../../../translations/translate";

const defaultHeaderInputs = {
    invoice: "",
    date: new Date(Date.now()),
    resPerson: "",
    totalCount: 0,
    totalSum: ""
};

const defaultTableTitles = [
    "Search",
    "Article",
    "Title",
    "Barcode",
    "Labeling",
    "Purchase Price",
    "Selling Price",
    "In Stock",
    "Amount",
    "Delete"
];

const tableDefaultData = {
    search: "",
    article: "",
    name: "",
    barCode: "",
    labeling: false,
    purchasePrice: "",
    sellingPrice: "",
    inStock: "",
    amount: "",
    id: ""
};

const PageTransferCreate = () => {
    // destructure state from useLocation hook
    const { state } = useLocation();
    // destructure Business ID and branches from state
    const { business: { _id: businessId, branches } = {} } = state || {};
    // getting Business Slug from useParams hook
    const { businessID: slug } = useParams();
    // useNavigate hook
    const navigate = useNavigate();

    // JWT Token
    const { token } = useSelector((state) => state.authReducer);
    // User data
    const { profileData : { displayName } } = useSelector((state) => state.authReducer);
    const userName = displayName?.first + " " + displayName?.last;
    // Lang selector
    const lang = useSelector((state) => state.languageReducer.lang);
    const __ = (key) => translate(lang, key);

    // creates a new branches array for the select component
    const reformatedBranches = branches?.map((branch) => ({
        label: branch.name,
        value: branch
    }));

    reformatedBranches?.push({
        label: __("Warehouse"),
        value: undefined
    });

    // States
    const [headerInputValues, setHeaderInputValues] = useState({...defaultHeaderInputs, resPerson: userName});
    const [tableTitles, setTableTitles] = useState(defaultTableTitles);
    const [productsArr, setProductsArr] = useState([tableDefaultData]);
    const [searchResult, setSearchResult] = useState({});
    const [isResultEmpty, setIsResultEmpty] = useState({});
    // source undefined means that in view by default choose warehouse
    const [selectedBranches, setSelectedBranches] = useState({ source: undefined, receiver: ""});
    const [modalIsOpen, setModalIsOpen] = useState(false);

    // Handlers
    const debouncedProductSearch = useCallback(
      debounce(async (index, value) => {
          const response = await searchProductQuery(businessId, value, token);

          setSearchResult(prevResults => ({
              ...prevResults,
              [index]: response
          }));

          // checking the search result
          if (!response.length) {
              setIsResultEmpty(prevState => ({
                  ...prevState,
                  [index]: true
              }));
          }
      }, 300), [searchResult]
    );

    const handleProductSearch = (index, value) => {
        // condition when need not show any dropdown, also, we will not send a request to the server
        if (value.length <= 2) {
            setSearchResult(prevResults => ({
                ...prevResults,
                [index]: []
            }));

            setIsResultEmpty(prevState => ({
                ...prevState,
                [index]: false
            }));

            return;
        }

        debouncedProductSearch(index, value);
    };

    const handleSelectProduct = (index, product) => {
        const newData = [...productsArr];

        newData[index] = {
            ...productsArr[index],
            id: product._id,
            article: product?.article,
            name: product.title?.uz,
            barCode: product?.productTasnif?.internationalCode || "",
            labeling: product?.isMarked,
            purchasePrice: product?.purchasePrice / 100 || "",
            sellingPrice: product?.price / 100,
            inStock: product?.inStock,
            stock: product?.stock
        };

        setProductsArr(newData);
        setSearchResult({});
    };

    const handleSelectLabeling = (index, value) => {
        const labeling = value === "0";
        const newData = [...productsArr];

        newData[index] = {
            ...productsArr[index],
            labeling
        };

        setProductsArr(newData);
    };

    const handleHeaderChange = (fieldName, value) => {
        setHeaderInputValues(prevState => ({
            ...prevState,
            [fieldName]: value
        }));
    };

    const handleTableDataChange = (index, fieldName, value) => {
        const newData = [...productsArr];

        newData[index] = {
            ...productsArr[index],
            [fieldName]: value
        };

        setProductsArr(newData);

        if(fieldName === "search") {
            handleProductSearch(index, value);
        }
    };

    const handleRemoveRow = (index) => {
        if (productsArr.length === 1 && index === 0  )
            return;

        const newProductsArr = productsArr.filter((_, idx) => idx !== index);
        setProductsArr(newProductsArr);
    };

    const addRow = () => {
        setProductsArr([
            ...productsArr,
            tableDefaultData
        ]);
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        // this condition helps to submit only modals form
        if (modalIsOpen) return;

        if (selectedBranches.source === selectedBranches.receiver) {
            errorToast(__("The same branches have been selected"));
            return;
        }

        const containsNameField = productsArr.some(obj => obj.name !== "");
        // Check fields value
        if (containsNameField) {
            const products = productsArr.map(product => ({
                productID: { _id: product.id },
                count: product.amount,
                price: product.sellingPrice * 100,
                purchasePrice: product.purchasePrice && product.purchasePrice * 100,
                productionDate: product.productionDate,
                expireDate: product.expiryDate
            }));

            const body = {
                recieptDate: headerInputValues.date.toISOString(),
                responsiblePerson: headerInputValues.resPerson,
                operationType: "Transfer",
                fromBranchID: selectedBranches.source,
                toBranchID: selectedBranches.receiver,
                products,
                totalCount: headerInputValues.totalCount,
                totalPrice: headerInputValues.totalSum
            };

            try {
                const response = await ApiService.postEvent(`/businesses/${businessId}/invoice`, token, body);
                successToast(__(response?.message));
                navigate(-1);
            } catch (e) {
                errorToast(e?.message);
            }

            return;
        }

        errorToast(__("The product is not selected or not all fields are filled in"));
    };

    // Effects
    useEffect(() => {
        const totalCount = productsArr.length;

        const totalSum = productsArr.reduce((acc, currentProduct) => {
            if (currentProduct.purchasePrice)
                return acc + currentProduct.purchasePrice * currentProduct.amount;
            else return acc;
        }, 0);

        setHeaderInputValues(prevState => ({
            ...prevState,
            totalCount,
            totalSum,
        }));
    }, [productsArr]);

    useEffect( () => {
        (async function() {
            try {
                const response = await ApiService.getResources(`/businesses/${businessId}/invoice/last/num?query=Transfer`, token);
                setHeaderInputValues(prevState => ({
                    ...prevState,
                    invoice: response.data
                }));
            } catch (e) {
                console.error(e);
            }
        })();
    }, []);

    const buttons = [
        {
            name: "Back",
            onClick: () => navigate(-1),
            state: {},
            className: "outline",
        }
    ];

    const breadCrumbsArray = [
        { label: "Transfers", link: `/businesses/${slug || businessId}/transfers` },
        { label: "Create" },
    ];

    return (
      <div>
          <BreadCrumbs breadcrumbs={breadCrumbsArray}/>
          <div className="withdrawal-page page-inner">
              <PageHeader name={__("Create Transfer")} buttons={buttons} />
              <Box
                sx={{marginTop: "24px", borderRadius: "6px"}}
                component="form"
                onSubmit={onSubmit}
              >
                  <div className="component-box-card">
                      <div className="card-header">
                          <div className="card-info">
                              <div className="card-icon">
                                  <i className="icon-branch"></i>
                              </div>
                              <div className="card-text">
                                  <p className="card-title">
                                      {__("Branch")}
                                  </p>
                              </div>
                          </div>
                      </div>
                      <div className="input-item">
                          <div className="component-input">
                              <label
                                className="input-label"
                              >
                                  <span className="input-text">
                                    {__("Source")}
                                  </span>
                                  {/* TODO: create separate function to select branchID */}
                                  <SelectComponent
                                    value={reformatedBranches?.find((branch) => selectedBranches?.source === branch.value?._id)}
                                    options={reformatedBranches}
                                    onChange={(e) => {
                                        const branchID = e.value?._id;
                                        if(branchID) {
                                            const updatedTitles = tableTitles.map(title => title === "In Stock" ? "In Branch" : title);
                                            setTableTitles(updatedTitles);
                                        } else {
                                            setTableTitles(defaultTableTitles);
                                        }
                                        setSelectedBranches({
                                            ...selectedBranches,
                                            source: branchID
                                        });
                                    }}
                                  />
                              </label>
                          </div>
                          <div className="component-input">
                              <label
                                className="input-label"
                              >
                                  <span className="input-text">
                                    {__("Receiver")}
                                  </span>
                                  <SelectComponent
                                    value={reformatedBranches?.find((branch) => selectedBranches?.receiver === branch.value?._id)}
                                    options={reformatedBranches}
                                    onChange={(e) => setSelectedBranches({
                                        ...selectedBranches,
                                        receiver: e.value?._id
                                    })}
                                  />
                              </label>
                          </div>
                      </div>
                  </div>
                  <Box sx={{
                      marginTop: "24px",
                      borderRadius: "6px",
                      overflowX: "scroll"
                  }}>
                      <TableHeader
                        inputValues={headerInputValues}
                        onChangeHandler={handleHeaderChange}
                        branches={reformatedBranches}
                        transferPage
                      />
                      <BasicTable
                        tableTitles={tableTitles}
                        productsArr={productsArr}
                        searchResult={searchResult}
                        isResultEmpty={isResultEmpty}
                        handleOnChange={handleTableDataChange}
                        handleSelectProduct={handleSelectProduct}
                        handleSelectLabeling={handleSelectLabeling}
                        removeRow={handleRemoveRow}
                        addRow={addRow}
                        modalIsOpen={modalIsOpen}
                        setModalIsOpen={setModalIsOpen}
                        setProductsArr={setProductsArr}
                        branchID={selectedBranches?.source}
                      />
                  </Box>
                  <div className="component box-save">
                      <div className="not-save-item">
                          <div className="not-save-icon">
                              <i className="icon-saved"></i>
                          </div>
                          <div className="not-save-text">
                              <p className="save-text">
                                  {__("Not Saved Yet")}
                              </p>
                          </div>
                      </div>
                      <div className="not-save-button">
                          <Button
                            className="btn outline sm"
                            text={__("Cancel")}
                            onClick={() => navigate(-1)}
                            type="button"
                          />
                          <Button
                            className="btn sm"
                            text={__("Save")}
                            type="submit"
                          />
                      </div>
                  </div>
              </Box>
          </div>
      </div>
    );
};

export default PageTransferCreate;
