
// REACT PACKAGES
import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// COMPONENTS
import ModalWindow from "../../../components/ModalWindow";
import { Box, colors, Typography } from "@mui/material";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import StatusTag from "../../../components/StatusTag";
import Action from "../../../components/Action/index.jsx";
import BreadCrumbs from "../../../components/BreadCrumbs/index.jsx";
import Filter from "../../../components/Filter/index.jsx";
import Preloader from "../../../components/Preloader";
import CustomPagination from "../../../components/Pagination";

// UTILS
import { translate } from "../../../translations/translate";
import useApiHook from "../../../hooks/useApiHook";
import config, { getLocaleText } from "../../../config";
import { updateTotalAction } from "../../../redux/reducers/paginationControllerReducer";
import getStaffRoles from "../../../utils/globalAPIRequests/getStaffRoles";

// STYLES
import "../../../assets/fonts/epos-icons/style.css";
import "./styles.sass";
import { ApiService } from "../../../utils/ApiService";
import { errorToast } from "../../../utils/toast";

const PageStaffTable = () => {

    // FILTERS FOR STAFF
    const filters = {
        isRole: true,
        isBranch: true,
        isStatus: true
    };

    // HOOKS

    // USE NAVIGATE
    const navigate = useNavigate();

    // USE DISPATCH
    const dispatch = useDispatch();

    // USE SELECTOR
    const controller = useSelector(state => state.paginationControllerReducer);
    const { token } = useSelector(state => state.authReducer);

    // USE STATE
    const [id, setId] = useState();

    // USE LOCATION
    const { state, pathname } = useLocation();

    // MODAL WINDOW
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const openModals = (id) => {
        setModalIsOpen(true);
        setId(id);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };


    const apiRef = useGridApiRef();

    const storageKey = `datagrid_filters_${pathname}`;
    const columnVisibilityKey = `datagrid_visible_columns_${pathname}`;
    const requiredColumns = ["title", "action"]; // Columns that must always be visible

    const [filterModel, setFilterModel] = useState(() => {
        const savedFilters = sessionStorage.getItem(storageKey);
        return savedFilters ? JSON.parse(savedFilters) : { items: [] };
    });

    const [columnVisibilityModel, setColumnVisibilityModel] = useState(() => {
        const savedColumns = sessionStorage.getItem(columnVisibilityKey);
        return savedColumns ? JSON.parse(savedColumns) : {};  // Default to all columns visible
    });

    useEffect(() => {
        sessionStorage.setItem(storageKey, JSON.stringify(filterModel));
    }, [filterModel]);

    const handleColumnVisibilityChange = (newModel) => {
        requiredColumns.forEach((col) => {
            newModel[col] = true;
        });

        setColumnVisibilityModel((prevModel) => {
            const updatedModel = { ...prevModel, ...newModel };
            sessionStorage.setItem(columnVisibilityKey, JSON.stringify(updatedModel));
            return updatedModel;
        });
    };


    // API REQUESTS

    // FUNCTION FOR REMOVING STAFF MEMBER
    const removeStaffMember = () => {
        ApiService.deleteEvent(`/businesses/${state?.business?.id}/staff/delete`,
          token, {id})
            .then(response => {
                if (response.status === 200) {
                    window.location.reload();
                }
            })
            .catch(error => {
                errorToast(error);
            });
    };



    // GET STAFF
    let url = `${config.API_BASE_URL}/businesses/${state?.business?.id}/staff?`;

    // IF VISITED FROM BRANCH
    if (state?.branch?._id)
        url += `branchID=${state?.branch?._id || state?.branch?.slug}`;

    // IF VISITED FROM CASHDESK
    else if (state?.cashdesk?._id)
        url += `cashdeskID=${state?.cashdesk?._id || state?.cashdesk?.slug}`;
    const { data, loading, error } = useApiHook(url);

    // GET ROLES
    const staffRoles = getStaffRoles(state?.business?.id);

    // USE EFFECT
    useEffect(() => {
        dispatch(updateTotalAction(parseInt(data?.data?.totalDocs) || 0));
    }, [data]);


    // LANGUAGE
    const lang = useSelector((state) => state.languageReducer.lang);
    const __ = (key) => translate(lang, key);

    // LOADING + ERROR
    if (loading)
        return (
            <div>
                <Preloader />
            </div>
        );
    if (error) return <div>{error.message}</div>;

    // MAPPING DATA FROM SERVER TO A NEW ARRAY
    let staffList = data?.data?.map((staff) => (
        {
            clientId: staff?.id?._id,
            id: staff?._id,
            name: staff?.id?.name?.first + " " + staff?.id?.name?.last,
            role: staffRoles?.find(role => role.value === staff.role)?.label,
            branchName: state?.branch?.name || "",
            phone: staff?.id?.phone,
        }
    ));

    // COLUMNS OF A TABLE
    const columns = [
        {
            field: "name",
            headerName: __("Name"),
            flex: 1,
                          
            cellClassName: "mane-column--cell",
            renderCell: ({ row }) => {
                return (
                    <Box display="flex" justifyContent="flex-start" width={"100%"}>
                        <Typography
                            fontFamily="Manrope, sans-serif"
                            fontWeight={600}
                            fontSize={14}
                            color={colors.common["black"]}
                            overflow={"hidden"}
                            textOverflow={"ellipsis"}
                            whiteSpace={"nowrap"}
                        >
                            {row.name}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: "role",
            headerName: __("Role"),
            flex: 1,
            cellClassName: "mane-column--cell",
            renderCell: ({ row }) => {
                return (
                    <Box display="flex" justifyContent="center">
                        <Typography
                            fontFamily="Manrope, sans-serif"
                            fontWeight={600}
                            fontSize={14}
                            color={colors.common["black"]}
                        >
                            {__(row.role)}
                        </Typography>
                    </Box>
                );
            },
        },
        ...staffList?.branchName !== ""
            ? [{
                field: "branchName",
                headerName: __("Branch"),
                flex: 1,
                cellClassName: "mane-column--cell",
                renderCell: ({ row }) => {
                    return (
                        <Box display="flex" justifyContent="center">
                            <Typography
                                fontFamily="Manrope, sans-serif"
                                fontWeight={600}
                                fontSize={14}
                                color={colors.common["black"]}
                            >
                                {row.branchName}
                            </Typography>
                        </Box>
                    );
                },
            }]
            : [],
        {
            field: "phone",
            headerName: __("Phone Number"),
            flex: 1,
            cellClassName: "mane-column--cell",
            renderCell: ({ row }) => {
                return (
                    <Box display="flex" justifyContent="center">
                        <Typography
                            fontFamily="Manrope, sans-serif"
                            fontWeight={600}
                            fontSize={14}
                            color={colors.common["black"]}
                        >
                            {row.phone}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: "action",
            headerName: __("Action"),
            type: "string",
            renderCell: ({ row }) => {
                return (
                    <Box display="flex" justifyContent="center">
                        <Action
                            onClick={() =>
                                navigate(
                                    `/businesses/${state?.business?.slug || state?.business?.id}/staff/${row?.id}/edit`,
                                    {
                                        state: {
                                            business: {
                                                id: state?.business?.id,
                                                slug: state?.business?.slug,
                                            },
                                            branch: {
                                                id: state?.branch?._id,
                                                slug: state?.branch?.slug,
                                            },
                                            cashdesk: {
                                                id: state?.cashdesk?.uniqueID,
                                                slug: state?.cashdesk?.slug,
                                            },
                                            staff: {
                                                id: row?.id
                                            },
                                        },
                                    },
                                )
                            }
                            onClickView={() =>
                                navigate(`/clients/${row?.id}`, {
                                    state: {
                                        client: {
                                            id: row?.clientId,
                                        },
                                    },
                                })
                            }
                            openModal={() => {
                              openModals(row?.clientId);
                            }}
                        />
                    </Box>
                );
            },
        },
    ];

    return (
        <div>
            <BreadCrumbs breadcrumbs={[
                { label: "Businesses", link: "/businesses" },
                { label: state?.business?.slug || state?.business?.id, link: `/businesses/${state?.business?.slug || state?.business?.id}` },
                { label: "Staff" },
            ]} />
            <div className="page page-table-staff">
                <Box>
                    <Filter
                        description={__("Staff")}
                        buttons={[
                            {
                                text: __("Add Staff Member"),
                                link: `/businesses/${
                                    state?.business.slug || state?.business.id
                                }/staff/create`,
                                state: {
                                    business: state?.business,
                                    branch: {
                                        _id: state?.branch?._id,
                                        slug: state?.branch?.slug,
                                    },
                                    cashdesk: {
                                        _id: state?.cashdesk?._id,
                                        slug: state?.cashdesk?.slug,
                                    }
                                },
                                classNames: "outline",
                            },
                        ]}
                        filters={filters}
                    />
                    <Box
                        sx={{
                            "&": {
                                margin: "0",
                                height: "90vh",
                                minHeight: "350px",
                            },
                            "& .name-column--cell": {
                                color: colors.green[300],
                            },
                            "& .MuiDataGrid-footerContainer": {
                                borderTop: "none",
                            },
                        }}
                    >
                        <DataGrid
                            sx={{
                                height: "90vh",
                                minHeight: "350px",
                            }}
                            rows={staffList}
                            columns={columns}
                            components={{
                                UITable: <StatusTag />,
                                Pagination: CustomPagination,
                            }}
                            checkboxSelection={true}
                            rowCount={controller.total}
                            page={controller.page}
                            pageSize={controller.pageSize}
                            rowsPerPageOptions={[5, 10, 20, 100]}
                            localeText={getLocaleText(__)}
                            apiRef={apiRef}
                            filterModel={filterModel}
                            onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
                            columnVisibilityModel={columnVisibilityModel}
                            onColumnVisibilityModelChange={handleColumnVisibilityChange}
                            componentsProps={{
                                columnsPanel: {
                                    sx: {
                                        "& .MuiDataGrid-panelFooter button:first-child":{
                                            display: "none"
                                        },
                                        "& .MuiDataGrid-panelFooter button:last-child": {
                                            display: "none"
                                        }
                                    }
                                }
                            }}
                        />
                    </Box>
                </Box>
                <Modal
                    className="delete-modal"
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                >
                    <div className="modal-bottom">
                        <ModalWindow
                            title={__("Are you sure?")}
                            text={__(
                                "Are you sure you want to permanently delete this staff member?"
                            )}
                            onClick={closeModal}
                            onClickDelete={removeStaffMember}
                        />
                    </div>
                </Modal>
            </div>
        </div>
    );
};

export default PageStaffTable;
