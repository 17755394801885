import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ApiService } from "../../../utils/ApiService";
import { successToast, errorToast } from "../../../utils/toast";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { translate } from "../../../translations/translate";

import Button from "../../../components/global/Button";
import PageHeader from "../../../components/PageHeader";
import config from "../../../config";
import useApiHook from "../../../hooks/useApiHook";
import Preloader from "../../../components/Preloader";
import BreadCrumbs from "../../../components/BreadCrumbs";

import "../View/styles.sass";
import "./styles.sass";

const PageCategoryEdit = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { businessID, id: categoryID } = useParams();
    const [equal, setEqual] = useState(true);
    let categories = [
        {
            value: null,
            label: "-",
        },
    ];
    const { token } = useSelector((state) => state.authReducer);
    const [categoryInitial, setCategoryInitial] = useState({});
    const [categoryCurrent, setCategoryCurrent] = useState({});
    const url = `${config.API_BASE_URL}/businesses/${
        state?.business?.id || state?.business?.slug || businessID
    }/product-categories/${state.category.id || categoryID}`;
    const { data, loading, error } = useApiHook(url);
    state?.categories?.map((category) => {
        category._id !== data?.data?.docs[0]._id &&
            categories.push({
                value: category._id,
                label: category?.title?.ru,
            });
    });

    const lang = useSelector((state) => state.languageReducer.lang);

    const __ = (key) => translate(lang, key);
    useEffect(() => {
        if (data?.data?.category) {
            setCategoryInitial({
                titleUz: data?.data?.category?.title?.uz,
            });
            setCategoryCurrent({
                titleUz: data?.data?.category?.title?.uz,

            });
        }
    }, [data]);
    useEffect(() => {
        if (categoryInitial.titleUz === categoryCurrent.titleUz) {
            setEqual(true);
        } else {
            setEqual(false);
        }
    }, [categoryCurrent]);

    const onCancelClick = function () {
        navigate(
            `/businesses/${
                state?.business?.id || state?.business?.slug || businessID
            }/categories`,
            {
                state: {
                    business: {
                        id: state?.business?.id || businessID,
                        name: state?.business.name,
                        slug: state?.business?.slug,
                    },
                },
            },
        );
    };

    const onSubmit = function (e) {
        e.preventDefault();
        const body = {
            childCategories: categoryCurrent.childCategories,
            description: {
                uz: categoryCurrent.descriptionUz,
                ru: categoryCurrent.descriptionUz,
                en: categoryCurrent.descriptionUz,
            },
            parentCategory: categoryCurrent.parentCategory,
            title: {
                uz: categoryCurrent.titleUz,
                ru: categoryCurrent.titleUz,
                en: categoryCurrent.titleUz,
            },
        };
        ApiService.patchEvent(
            `/businesses/${
                state?.business?.id || state?.business?.slug || businessID
            }/product-categories/${state.category.id || categoryID}`,
            token,
            body,
        )
            .then((value) => {
                if (value.code === "PRODUCT_CATEGORY_UPDATED_SUCCESSFULLY") {
                    successToast(__("Successfully updated"));
                    navigate(-1);
                }
            })

            .catch((error) => {
                errorToast(__(error.message));
            });
    };
    const buttons = [
        {
            name: "Back",
            link: `/businesses/${state?.business?.id || businessID}/categories`,
            state: {
                business: {
                    id: state?.business?.id || businessID,
                    name: state?.business.name,
                },
            },
            className: "outline",
        },
    ];
    if (loading)
        return (
            <div>
                <Preloader />
            </div>
        );
    if (error) return <div>{error}</div>;
    return (
        <div>
            <BreadCrumbs
                breadcrumbs={[
                    {
                        label: "Categories",
                        link: `/businesses/${
                            state?.business?.id || businessID
                        }/product-categories`,
                    },
                    { label: "Edit" },
                ]}
            />
            <div className="page component-page-business-create">
                <form className="page-inner" onSubmit={onSubmit}>
                    <PageHeader
                        name={__("Edit Category")}
                        buttons={buttons}
                        state={{
                            business: {
                                _id: state?.business?.id || businessID,
                                slug: state?.business?.slug || businessID,
                            },
                        }}
                    />
                    <div className="cards">
                        <div className="left-side-cards">
                            <div>
                                <div className="component-box-card">
                                    <div className="card-header">
                                        <div className="card-info">
                                            <div className="card-icon">
                                                <i className="icon-information" />
                                            </div>
                                            <div className="card-text">
                                                <p className="card-title">
                                                    {__(
                                                        "Category Information",
                                                    )}
                                                </p>
                                                <p className="card-subtitle" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="input-item">
                                        <div className="component-input full">
                                            <label
                                                className="input-label"
                                            >
                                                <input
                                                    value={categoryCurrent.titleUz || ""}
                                                    placeholder={__(
                                                        "Enter title",
                                                    )}
                                                    className="input"
                                                    type="text"
                                                    required={true}
                                                    onChange={(e) => {
                                                        setCategoryCurrent({
                                                            ...categoryCurrent,
                                                            titleUz:
                                                            e.target
                                                                .value,
                                                        });
                                                    }}
                                                />
                                                <span className="input-text">
                                                    {__("Title")}
                                                </span>
                                            </label>
                                        </div>
                                        {/* Other titles*/}
                                       {/* <div className="component-input">
                                            <label
                                                className="input-label"
                                                htmlFor="titleRu"
                                            >
                                                <input
                                                    defaultValue={
                                                        item?.title?.ru
                                                    }
                                                    id="titleRu"
                                                    className="input"
                                                    type="text"
                                                    required={true}
                                                    placeholder={__(
                                                        "Enter title in Russian",
                                                    )}
                                                    onChange={(e) => {
                                                        setCategoryCurrent({
                                                            ...categoryCurrent,
                                                            titleRu:
                                                                e.target
                                                                    .value,
                                                        });
                                                    }}
                                                />
                                                <span className="input-text">
                                                    {__("Title")} (
                                                    {__("Russian")})
                                                </span>
                                            </label>
                                        </div>
                                        <div className="component-input">
                                            <label
                                                className="input-label"
                                                htmlFor="titleEn"
                                            >
                                                <input
                                                    defaultValue={
                                                        item?.title?.en
                                                    }
                                                    placeholder={__(
                                                        "Enter title in English",
                                                    )}
                                                    id="titleEn"
                                                    className="input"
                                                    type="text"
                                                    required={true}
                                                    onChange={(e) => {
                                                        setCategoryCurrent({
                                                            ...categoryCurrent,
                                                            titleEn:
                                                                e.target
                                                                    .value,
                                                        });
                                                    }}
                                                />
                                                <span className="input-text">
                                                    {__("Title")} (
                                                    {__("English")})
                                                </span>
                                            </label>
                                        </div> */}
                                    </div>
                                   {/* <div className="input-item">
                                        <div className="component-input full">
                                            <label
                                                className="input-label"
                                            >
                                                <textarea
                                                    defaultValue=""
                                                    placeholder={__(
                                                        "Enter description",
                                                    )}
                                                    className="input input_large"
                                                    rows={3}
                                                    onChange={(e) => {
                                                        setCategoryCurrent({
                                                            ...categoryCurrent,
                                                            descriptionUz:
                                                            e.target
                                                                .value,
                                                        });
                                                    }}
                                                />
                                                <span className="input-text">
                                                    {__("Description")}
                                                </span>
                                            </label>
                                        </div>
                                         Other descriptions
                                        <div className="component-input">
                                            <label
                                                className="input-label"
                                                htmlFor="descriptionRu"
                                            >
                                                <textarea
                                                    defaultValue={
                                                        item?.description
                                                            ?.ru
                                                    }
                                                    placeholder={__(
                                                        "Enter description in Russian",
                                                    )}
                                                    id="descriptionRu"
                                                    className="input input_large"
                                                    rows={3}
                                                    onChange={(e) => {
                                                        setCategoryCurrent({
                                                            ...categoryCurrent,
                                                            descriptionRu:
                                                                e.target
                                                                    .value,
                                                        });
                                                    }}
                                                />
                                                <span className="input-text">
                                                    {__("Description")} (
                                                    {__("Russian")})
                                                </span>
                                            </label>
                                        </div>
                                        <div className="component-input">
                                            <label
                                                className="input-label"
                                                htmlFor="descriptionEn"
                                            >
                                                <textarea
                                                    defaultValue={
                                                        item?.description
                                                            ?.en
                                                    }
                                                    placeholder={__(
                                                        "Enter description in English",
                                                    )}
                                                    id="descriptionEn"
                                                    className="input input_large"
                                                    rows={3}
                                                    onChange={(e) => {
                                                        setCategoryCurrent({
                                                            ...categoryCurrent,
                                                            descriptionEn:
                                                                e.target
                                                                    .value,
                                                        });
                                                    }}
                                                />
                                                <span className="input-text">
                                                    {__("Description")} (
                                                    {__("English")})
                                                </span>
                                            </label>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="component box-save">
                        <div className="not-save-item">
                            <div className="not-save-icon">
                                <i className="icon-not-save" />
                            </div>
                            <div className="not-save-text">
                                <p className="save-text">
                                    {" "}
                                    {__("Not saved yet")}
                                </p>
                            </div>
                        </div>
                        <div className="not-save-button">
                            <Button
                                className="btn outline sm"
                                text={__("Cancel")}
                                onClick={onCancelClick}
                                type="button"
                            />
                            <Button
                                disabled={equal}
                                className="btn sm"
                                text={__("Save")}
                                type="submit"
                            />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default PageCategoryEdit;
