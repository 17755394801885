import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { translate } from "../../../translations/translate";
import { useLocation, useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import PageHeader from "../../../components/PageHeader";
import useApiHook from "../../../hooks/useApiHook";
import config from "../../../config";
import Preloader from "../../../components/Preloader";
import BreadCrumbs from "../../../components/BreadCrumbs";
import "./styles.sass";
import { splitPermissionsByCategory } from "../../../utils/splitPermissionsByCategory";
import CheckBox from "../../../components/CheckBox";

const PageRolesView = () => {

    // GETTING ID OF A CURRENT ROLE
    const { state } = useLocation();
    const { id } = useParams();
    const isStaffRole = state?.business ? true : false;

    // QUERY TO BACKEND FOR A CURRENT ROLE
    const url = `${config.API_BASE_URL}/${isStaffRole ? `businesses/${state?.business?._id}/staff/roles` : "users-roles"}/${state?.role?.id || id}`;
    const { data, loading, error } = useApiHook(url);

    // QUERY TO BACKEND FOR PERMISSIONS
    const permissionUrl = `${config.API_BASE_URL}/permissions?limit=200`;
    const { data: permissionData, loading: permissionLoading, error: permissionError } = useApiHook(permissionUrl);
    const navigate = useNavigate();

    const [showField, setShowField] = useState(false);
    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm();

    const [role, setRole] = useState({});

    const { token } = useSelector((state) => state.authReducer);
    const lang = useSelector((state) => state.languageReducer.lang);
    const __ = (key) => translate(lang, key);

    const [permissions, setPermissions] = useState([]);

    // SETTING ROLE AFTER DATA IS FETCHED
    useEffect(() => {
        if (data?.data?.docs) {
            data?.data?.docs.forEach(role => {
                setRole({
                    title: role.title,
                    id: role._id,
                    permissions: role.permissions.map(permission => permission._id),
                });
            });
        }
    }, [data]);
    useEffect(() => {
        if (permissionData?.data?.docs) {
            setPermissions(permissionData?.data?.docs);
        }
    }, [permissionData]);

    // PERMISSIONS BY CATEGORY
    const permissionsByCategory = splitPermissionsByCategory(permissions);

    // CALLBACK FUNCTIONS
    const onCancelClick = function () {
        navigate("/roles", isStaffRole ? { state: { business: state?.business } } : {});
    };

    // HEADER BUTTONS
    const buttons = [
        {
            name: "Back",
            link: "/roles",
            className: "outline",
            ...isStaffRole ? ({ state: { business: state?.business } }) : {},
        }
    ];

    // LOADING AND ERROR
    if (loading || permissionLoading)
        return (
            <div>
                <Preloader />
            </div>
        );
    if (error || permissionError) return <div>{error?.message || permissionError?.message}</div>;
    return (
        <div>
            <BreadCrumbs
                breadcrumbs={[
                    { label: "Roles", link: "/roles" },
                    { label: "View" },
                ]}
            />
            <div className="page component-page-roles-create">
                <form className="page-inner">
                    <PageHeader
                        name={__("View Role")}
                        buttons={buttons}
                    />
                    <div className="cards">
                        <div className="left-side-cards">
                            <div className="component-box-card">
                                <div className="card-header">
                                    <div className="card-info">
                                        <div className="card-icon">
                                            <i className="icon-tie"></i>
                                        </div>
                                        <div className="card-text">
                                            <p className="card-title">
                                                {__("Role Information")}
                                            </p>
                                            <p className="card-subtitle"></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="input-item">
                                    <div className="component-input">
                                        <label
                                            className="input-label"
                                        >
                                            <input
                                                value={role?.title}
                                                className="input"
                                                type="text"
                                                disabled
                                            />
                                            <span className="input-text">
                                                {__("Role Title")}
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            {
                                permissionsByCategory?.map((permissionCategory, index) => {
                                    if(permissionCategory[0].category === "UserModelPermissions" && isStaffRole) {
                                        return;
                                    }
                                    return (
                                        <div className="component-box-card" key={index} >
                                            <div className="card-header">
                                                <div className="card-info">
                                                    <div className="card-icon">
                                                        <i className="icon-tie"></i>
                                                    </div>
                                                    <div className="card-text">
                                                        <p className="card-title">
                                                            {__(`${permissionCategory[0].category}`)}
                                                        </p>
                                                        <p className="card-tie"></p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="permissions__flex">
                                                {
                                                    permissionCategory?.map((permission, index) => (
                                                        <div className="permission" key={index}>
                                                            <CheckBox
                                                                isChecked={role?.permissions
                                                                    ?.find(rolePermission => rolePermission === permission._id)}
                                                                htmlFor={`permission-${permission.category}-${index}`}
                                                                inputType="checkbox"
                                                                id={`permission-${permission.category}-${index}`}
                                                                name={`permission-${permission.category}`}
                                                                value={permission._id}
                                                                titleClass="checkbox-title"
                                                                title={__(permission.title)}
                                                                disabled={true}
                                                            />
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default PageRolesView;
