import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router";
import { translate } from "../../../translations/translate";
import { useEffect, useState } from "react";
import { updateTotalAction } from "../../../redux/reducers/paginationControllerReducer";
import Preloader from "../../../components/Preloader";
import { Box, colors, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import BreadCrumbs from "../../../components/BreadCrumbs";
import Filter from "../../../components/Filter";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import config, {getLocaleText} from "../../../config";
import useApiHook from "../../../hooks/useApiHook";

const ZReportComponent = () => {
    const {id: businessID} = useParams();
    const { state, pathname } = useLocation();
    const lang = useSelector((state) => state.languageReducer.lang);
    const __ = (key) => translate(lang, key);
    const [searchInputValue, setSearchInputValue] = useState("");

    let urlBase = `${config.API_BASE_URL}/businesses/${businessID}/reports?`;

    const [url, setUrl] = useState(urlBase);
    const { data, loading, error } = useApiHook(url);
    const dispatch = useDispatch();
    const controller = useSelector(
        (state) => state.paginationControllerReducer,
    );

    const apiRef = useGridApiRef();

    const storageKey = `datagrid_filters_${pathname}`;
    const columnVisibilityKey = `datagrid_visible_columns_${pathname}`;
    const requiredColumns = ["title", "action"]; // Columns that must always be visible

    const [filterModel, setFilterModel] = useState(() => {
        const savedFilters = sessionStorage.getItem(storageKey);
        return savedFilters ? JSON.parse(savedFilters) : { items: [] };
    });

    const [columnVisibilityModel, setColumnVisibilityModel] = useState(() => {
        const savedColumns = sessionStorage.getItem(columnVisibilityKey);
        return savedColumns ? JSON.parse(savedColumns) : {};  // Default to all columns visible
    });

    useEffect(() => {
        sessionStorage.setItem(storageKey, JSON.stringify(filterModel));
    }, [filterModel]);

    const handleColumnVisibilityChange = (newModel) => {
        requiredColumns.forEach((col) => {
            newModel[col] = true;
        });

        setColumnVisibilityModel((prevModel) => {
            const updatedModel = { ...prevModel, ...newModel };
            sessionStorage.setItem(columnVisibilityKey, JSON.stringify(updatedModel));
            return updatedModel;
        });
    };


    const reports = data?.data?.docs;

    useEffect(() => {
        dispatch(updateTotalAction(parseInt(data?.data?.totalDocs) || 0));
    }, [data]);

    useEffect(() => {
        setUrl(
            `${urlBase}&page=${controller.page + 1}&limit=${controller.pageSize}`,
        );
    }, [controller.page, controller.pageSize]);

    useEffect(() => {
        let searchUrl = urlBase;
        if (searchInputValue) {
            searchUrl = `${config.API_BASE_URL}/businesses/${businessID}/reports?reportNumber=${(searchInputValue)}`;
        }
        setUrl(searchUrl);
    }, [searchInputValue]);

    if (loading)
        return (
            <div>
                <Preloader />
            </div>
        );

    if (error) return <div>{error}</div>;
    

    let reportList = [];
    
    if (reports) {
        reports.map((item) => {
            let report = {
                reportID: item.reportNumber || "—", 
                uniqueID: item?.cashdeskID.uniqueID || "—", 
                branchName: item?.cashdeskID.branchID?.name || "—", 
                businessName: item?.cashdeskID?.businessID?.name || "—",
                openedAt: item.openedAt ? new Date(item.openedAt).toLocaleDateString() : "—",
                closedAt: item.closedAt ? new Date(item.closedAt).toLocaleDateString() : "—", 
                id: item._id,
                receipts: `${item.firstChequeNumber || "—"} - ${item.lastChequeNumber || "—"}`,
            };
            reportList.push(report);
        });
        
    }
    
    const columns = [
        {
            field: "reportID",
            headerName: __("Number"),
            flex: 0.5,
                          
            cellClassName: "mane-column--cell",
            renderCell: ({ row }) => {
            return (
                    <Box
                        display="flex"
                        justifyContent="flex-start"
                        width={"100%"}
                    >
                    <Link
                            to={`/businesses/${businessID || state.business?._id}/reports/${row.reportID}`}
                            state={{
                                reportID: row.id,
                                businessID: state?.business?._id
                            }}
                            className="link"
                        >
                            <Typography
                                fontFamily="Manrope, sans-serif"
                                fontWeight={600}
                                fontSize={14}
                                color={colors.common["black"]}
                                textOverflow="ellipsis"
                                overflow="hidden"
                                whiteSpace="nowrap"
                            >
                                {row.reportID}
                            </Typography>
                    </Link>
                </Box>
            );
        },
    },
        {
            field: "businessName",
            headerName: __("Business"),
            flex: 1,
            renderCell: ({ row }) => {
                return (
                    <Box
                        display="flex"
                        justifyContent="flex-start"
                        width={"100%"}
                    >
                        <Typography
                            fontFamily="Manrope, sans-serif"
                            fontWeight={400}
                            fontSize={14}
                            color={colors.common["black"]}
                            textOverflow={"ellipsis"}
                            overflow={"hidden"}
                            whiteSpace={"nowrap"}
                        >
                            {row.businessName}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: "branchName",
            headerName: __("Branch"),
            flex: 1,
            renderCell: ({ row }) => {
                return (
                    <Box
                        display="flex"
                        justifyContent="flex-start"
                        width={"100%"}

                    >
                        <Typography
                            fontFamily="Manrope, sans-serif"
                            fontWeight={500}
                            fontSize={14}
                            color={colors.common["black"]}
                            textOverflow={"ellipsis"}
                            overflow={"hidden"}
                            whiteSpace={"nowrap"}
                        >
                            {row.branchName}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: "uniqueID",
            headerName: __("Cashdesk ID"),
            flex: 1,
            cellClassName: "mane-column--cell",
            renderCell: ({ row }) => {
                const uniqueID = row.uniqueID || "—";  
                return (
                    <Box display="flex" justifyContent="flex-start" width="100%">
                            <Typography
                                fontFamily="Manrope, sans-serif"
                                fontWeight={600}
                                fontSize={14}
                                color={colors.common["black"]}
                                textOverflow="ellipsis"
                                overflow="hidden"
                                whiteSpace="nowrap"
                            >
                                {uniqueID} 
                            </Typography>
                    </Box>
                );
            }
        },
        {
            field: "openedAt",
            headerName: __("Date"),
            flex: 1,
            renderCell: ({ row }) => {
                return (
                    <Typography
                        fontFamily="Manrope, sans-serif"
                        fontWeight={500}
                        fontSize={14}
                        color={colors.common["black"]}
                        textOverflow="ellipsis"
                        overflow="hidden"
                        whiteSpace="nowrap"
                    >
                        {`${row.openedAt} - ${row.closedAt}`}
                    </Typography>
                );
            },
        },        
        {
            field: "receipts",
            headerName: __("Receipts"), 
            flex: 1,
            renderCell: ({ row }) => (
                <Typography
                    fontFamily="Manrope, sans-serif"
                    fontWeight={500}
                    fontSize={14}
                    color={colors.common["black"]}
                    textOverflow="ellipsis"
                    overflow="hidden"
                    whiteSpace="nowrap"
                >
                    {row.receipts}
                </Typography>
            ),
        },
    ];

    return (
        <div>
            <BreadCrumbs
                breadcrumbs={[{ label: "Businesses", link: "/businesses", item: "/businesses/${businessID}/reports" },
                { label: "Reports"}
                ]}
            />
            <div
                className="page page-cashdesk-table"
            >
                <Box>
                    <Filter
                        description={__("Reports")}
                        search={searchInputValue}
                        setSearch={setSearchInputValue}
                        buttons={[]}
                    />
                    <Box
                        sx={{
                            "&": {
                                margin: "0",
                                height: "90vh",
                                minHeight: "350px",
                            },
                            "& .name-column--cell": {
                                color: colors.green[300],
                            },
                            "& .MuiDataGrid-footerContainer": {
                                borderTop: "none",
                            },
                        }}
                    >
                        <DataGrid
                            rows={reportList}
                            columns={columns}
                            rowCount={controller.total}
                            page={controller.page}
                            pageSize={controller.pageSize}
                            {...config.TABLE_CONFIG(__)}
                            localeText={getLocaleText(__)}
                            apiRef={apiRef}
                            filterModel={filterModel}
                            onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
                            columnVisibilityModel={columnVisibilityModel}
                            onColumnVisibilityModelChange={handleColumnVisibilityChange}
                            componentsProps={{
                                columnsPanel: {
                                    sx: {
                                        "& .MuiDataGrid-panelFooter button:first-child":{
                                            display: "none"
                                        },
                                        "& .MuiDataGrid-panelFooter button:last-child": {
                                            display: "none"
                                        }
                                    }
                                }
                            }}
                        />
                    </Box>
                </Box>
            </div>
        </div>
    );
};

export default ZReportComponent;