import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { translate } from "../../../translations/translate";
import { ApiService } from "../../../utils/ApiService";
import { useLocation, useParams } from "react-router";
import { errorToast, successToast } from "../../../utils/toast";
import { useNavigate } from "react-router-dom";
import { parseFiscalID } from "../../../utils/helper.js";
import { fiscalPref } from "../../../hooks/fiscalPref";
import Button from "../../../components/global/Button";
import PageHeader from "../../../components/PageHeader";
import useApiHook from "../../../hooks/useApiHook";
import config from "../../../config";
import Preloader from "../../../components/Preloader";
import BreadCrumbs from "../../../components/BreadCrumbs";
import SelectComponent from "../../../components/Select";
import "./styles.sass";
import { IMaskInput } from "react-imask";
import { splitPermissionsByCategory } from "../../../utils/splitPermissionsByCategory";
import CheckBox from "../../../components/CheckBox";

const PageRolesEdit = () => {

    // GETTING ID OF A CURRENT ROLE
    const { state } = useLocation();
    const { id } = useParams();

    const isStaffRole = state?.business ? true : false;

    // QUERY TO BACKEND FOR A CURRENT ROLE
    const url = `${config.API_BASE_URL}/${isStaffRole ? `businesses/${state?.business?._id}/staff/roles` : "users-roles"}/${state?.role?.id || id}`;
    const { data, loading, error } = useApiHook(url);

    // QUERY TO BACKEND FOR PERMISSIONS
    const permissionUrl = `${config.API_BASE_URL}/permissions?limit=200`;
    const { data: permissionData, loading: permissionLoading, error: permissionError } = useApiHook(permissionUrl);
    const navigate = useNavigate();

    const [showField, setShowField] = useState(false);
    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm();

    // USE STATE
    // const [equal, setEqual] = useState(true);
    // const [roleInitial, setRoleInitial] = useState({});
    const [role, setRole] = useState({});

    const { token } = useSelector((state) => state.authReducer);
    const lang = useSelector((state) => state.languageReducer.lang);
    const __ = (key) => translate(lang, key);

    const [permissions, setPermissions] = useState([]);

    // SETTING ROLE AFTER DATA IS FETCHED
    useEffect(() => {
        if (data?.data?.docs) {
            data?.data?.docs.forEach(role => {
                setRole({
                    title: role.title,
                    id: role._id,
                    permissions: role.permissions.map(permission => permission._id),
                });
            });
        }
    }, [data]);
    useEffect(() => {
        if (permissionData?.data?.docs) {
            setPermissions(permissionData?.data?.docs);
        }
    }, [permissionData]);

    // PERMISSIONS BY CATEGORY
    const permissionsByCategory = splitPermissionsByCategory(permissions);

    const onRoleTitleChange = (event) => {
        setRole({
            ...role,
            title: event.target.value,
        });
    };
    const onPermissionCheck = (event) => {
        if (role.permissions.some(rolePermission => rolePermission === event.target.value)) {
            setRole({
                ...role,
                permissions: role.permissions.filter(permission => permission !== event.target.value),
            });
        } else {
            setRole({
                ...role,
                permissions: [...role.permissions, event.target.value],
            });
        }
    };
    // SAMPLE DATA
    // useEffect(() => {
    //     setRole({
    //         title: "Director",
    //         id: 1,
    //     });
    // }, [state]);

    // CALLBACK FUNCTIONS
    const onCancelClick = function () {
        navigate("/roles", isStaffRole ? { state: { business: state?.business } } : {});
    };

    // ON FORM SUBMIT
    const onSubmit = (e) => {
        e.preventDefault();
        const body = {
            title: role?.title,
            permissions: role?.permissions,
        };
        ApiService
            .patchEvent(`/${isStaffRole
                ? `businesses/${state?.business?._id}/staff/roles`
                : "users-roles"}/${state?.role?.id || id}`, token, body)
            .then((response) => {
                if (response.status === 200) {
                    successToast(__("Successfully updated"));
                    navigate("/roles", isStaffRole ? { state: { business: state?.business } } : {});
                }
            })

            .catch((error) => {
                errorToast(__(error.message));
            });
    };

    // HEADER BUTTONS
    const buttons = [
        {
            name: "Back",
            link: "/roles",
            className: "outline",
            ...isStaffRole ? ({ state: { business: state?.business } }) : {},
        }
    ];

    // LOADING AND ERROR
    if (loading || permissionLoading)
        return (
            <div>
                <Preloader />
            </div>
        );
    if (error || permissionError) return <div>{error?.message || permissionError?.message}</div>;
    return (
        <div>
            <BreadCrumbs
                breadcrumbs={[
                    { label: "Roles", link: "/roles" },
                    { label: "Edit" },
                ]}
            />
            <div className="page component-page-roles-create">
                <form onSubmit={onSubmit} className="page-inner">
                    <PageHeader
                        name={__("Edit Role")}
                        buttons={buttons}
                    />
                    <div className="cards">
                        <div className="left-side-cards">
                            <div className="component-box-card">
                                <div className="card-header">
                                    <div className="card-info">
                                        <div className="card-icon">
                                            <i className="icon-tie"></i>
                                        </div>
                                        <div className="card-text">
                                            <p className="card-title">
                                                {__("Role Information")}
                                            </p>
                                            <p className="card-subtitle"></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="input-item">
                                    <div className="component-input">
                                        <label
                                            className="input-label"
                                        >
                                            <input
                                                onChange={onRoleTitleChange}
                                                value={role?.title}
                                                className="input"
                                                type="text"
                                            />
                                            <span className="input-text">
                                                {__("Role Title")}
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            {
                                permissionsByCategory?.map((permissionCategory, index) => {
                                    if(permissionCategory[0].category === "UserModelPermissions" && isStaffRole) {
                                        return;
                                    }
                                    return (
                                        <div className="component-box-card" key={index} >
                                            <div className="card-header">
                                                <div className="card-info">
                                                    <div className="card-icon">
                                                        <i className="icon-tie"></i>
                                                    </div>
                                                    <div className="card-text">
                                                        <p className="card-title">
                                                            {__(`${permissionCategory[0].category}`)}
                                                        </p>
                                                        <p className="card-tie"></p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="permissions__flex">
                                                {
                                                    permissionCategory?.map((permission, index) => (
                                                        <div className="permission" key={index}>
                                                            <CheckBox
                                                                isChecked={role?.permissions
                                                                    ?.find(rolePermission => rolePermission === permission._id)}
                                                                htmlFor={`permission-${permission.category}-${index}`}
                                                                inputType="checkbox"
                                                                id={`permission-${permission.category}-${index}`}
                                                                name={`permission-${permission.category}`}
                                                                value={permission._id}
                                                                onChange={onPermissionCheck}
                                                                titleClass="checkbox-title"
                                                                title={__(permission.title)}
                                                            />
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                    <div className="component box-save">
                        <div className="not-save-item">
                            <div className="not-save-icon">
                                <i className="icon-unsaved" />
                            </div>
                            <div className="not-save-text">
                                <p className="save-text">
                                    {__("Not Saved Yet")}
                                </p>
                            </div>
                        </div>
                        <div className="not-save-button">
                            <Button
                                className="btn outline sm"
                                text={__("Cancel")}
                                onClick={onCancelClick}
                                type="button"
                            />
                            <Button
                                className="btn sm"
                                text={__("Save")}
                                type="submit"
                            />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default PageRolesEdit;
