import React, { useState, useEffect } from "react";
import { Box, Button, colors, Typography } from "@mui/material";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getParsedDate } from "../../../utils/getParsedDate";
import { translate } from "../../../translations/translate";
import { updatePageAction, updateTotalAction } from "../../../redux/reducers/paginationControllerReducer";
import StatusTag from "../../../components/StatusTag";
import Action from "../../../components/Action/index.jsx";
import BreadCrumbs from "../../../components/BreadCrumbs/index.jsx";
import Filter from "../../../components/Filter/index.jsx";
import config from "../../../config";
import Modal from "react-modal";
import ModalWindow from "../../../components/ModalWindow";
import useApiHook from "../../../hooks/useApiHook";
import Preloader from "../../../components/Preloader";
import "../../../assets/fonts/epos-icons/style.css";
import "./styles.sass";
import { ApiService } from "../../../utils/ApiService";
import { errorToast, successToast, warnToast } from "../../../utils/toast";
import { setVersions } from "../../../redux/actions/filtersAction";
import axios from "axios";
import BasicDatePicker from "../../../components/DatePicker";
import { formatDate } from "../../../utils/formatDate";

const PageCashdeskTable = () => {
    const filters = {
        isExpiresAt: true,
        isStatus: true,
        isApproved: true,
        versions  : true
    };
    const page = "cashdesks";

    const navigate = useNavigate();
    const {id: businessID} = useParams();
    const { state, pathname } = useLocation();

    const { token } = useSelector((state) => state.authReducer);
    const storedUserData = useSelector((state) => state.authReducer);
    const filterState = useSelector((state) => state.filter);
    const lang = useSelector((state) => state.languageReducer.lang);
    const __ = (key) => translate(lang, key);
    const isClient = storedUserData.role === "client";
    const filterByStatus = filterState.status.filter(item => item.isChecked)
        .map(item => "&status=" + item.name.toLowerCase()).join("");
    const filterByApprove = filterState.approved.filter(item => item.isChecked)
        .map((item) => {
            if (item.name === "Not Approved")
                return "&isApproved=false";
            return "&isApproved=true";
        }).join("");
    const { from, to } = filterState.expiresAt;
    const filterByDate = `${from ? `&fromDate=${from}` : ""}${to ? `&toDate=${to}` : ""}`;
    const filterByVersions = filterState.cashdeskVersions.filter(item => item.isChecked)
        .map(item => "&version=" + item.name.toLowerCase()).join("");
    const [id, setId] = useState();
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [searchInputValue, setSearchInputValue] = useState("");
    const [combineFilter, setCombineFilter] = useState("");
    const [checkedProducts, setCheckedProducts] = useState([]);
    const isBusinessPage = location.pathname.startsWith("/businesses/");
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [selectedDateEditModal, setSelectedDateEditModal] = useState();

    const removeCashdesk = () => {
        ApiService.deleteEvent(`/cashdesks/${id}`, token)
            .then((res) => {
                if (res.status === 200) {
                    window.location.reload();
                }
            })
            .catch((error) => {
                errorToast(error);
            });
    };

    const openModals = (id) => {
        setModalIsOpen(true);
        setId(id);
    };
    const closeModal = () => {
        setModalIsOpen(false);
    };
    let urlBase = `${config.API_BASE_URL}/cashdesks?`;
    if (state?.business) urlBase += `businessID=${state?.business._id}`;
    if (state?.branch) urlBase += `&branchID=${state?.branch._id}`;
    const versionUrl = `${config.API_BASE_URL}/cashdesks/versions/cashdesk`;
    const [url, setUrl] = useState(urlBase);
    const { data, loading, error } = useApiHook(url);
    const { data:versionData } = useApiHook(versionUrl);
    const dispatch = useDispatch();
    const controller = useSelector(
        (state) => state.paginationControllerReducer,
    );
    const cashdesks = data?.data?.docs;

    const apiRef = useGridApiRef();

    const storageKey = `datagrid_filters_${pathname}`;
    const columnVisibilityKey = `datagrid_visible_columns_${pathname}`;
    const requiredColumns = ["title", "action"]; // Columns that must always be visible


    const [filterModel, setFilterModel] = useState(() => {
        const savedFilters = sessionStorage.getItem(storageKey);
        return savedFilters ? JSON.parse(savedFilters) : { items: [] };
    });

    const [columnVisibilityModel, setColumnVisibilityModel] = useState(() => {
        const savedColumns = sessionStorage.getItem(columnVisibilityKey);
        return savedColumns ? JSON.parse(savedColumns) : {};  // Default to all columns visible
    });

    useEffect(() => {
        sessionStorage.setItem(storageKey, JSON.stringify(filterModel));
    }, [filterModel]);

    const handleColumnVisibilityChange = (newModel) => {
        console.log(newModel);
        requiredColumns.forEach((col) => {
            newModel[col] = true;
        });

        setColumnVisibilityModel((prevModel) => {
            const updatedModel = { ...prevModel, ...newModel };
            sessionStorage.setItem(columnVisibilityKey, JSON.stringify(updatedModel));
            return updatedModel;
        });
    };


    useEffect(() => {
        dispatch(updateTotalAction(parseInt(data?.data?.totalDocs) || 0));
    }, [data]);

    useEffect(() => {
        const versions  = versionData?.data?.versions?.filter((item) => item.appVersion).map(({ appVersion })=> ({
            name : `${appVersion}`,
            isChecked: false
        }));

        if(versions) {
            dispatch(setVersions(versions));
        }
    }, [versionData]);

    useEffect(() => {
        setUrl(
            `${urlBase}&page=${controller.page + 1}&limit=${controller.pageSize}${combineFilter}`,
        );
    }, [controller.page, controller.pageSize]);

    useEffect(() => {
        if(searchInputValue)
            setUrl(`${config.API_BASE_URL}/cashdesks/search/cashdesk?query=${searchInputValue}`);
        else setUrl(urlBase);
    }, [searchInputValue]);

    useEffect(() => {
        const filters = filterByStatus + filterByApprove + filterByDate + filterByVersions ;

        setCombineFilter(filters);
        setUrl(urlBase + filters);
    }, [filterByStatus, filterByApprove, filterByDate, filterByVersions]);


    useEffect(() => {
        setTimeout(()=> {
            console.log(filterModel?.items[0]?.value?.length);
            if(filterModel?.items[0]?.value?.length > 0) {
                setUrl(`${config.API_BASE_URL}/cashdesks/search/cashdesk?query=${filterModel.items[0].value}&page=${controller.page + 1}`);
            }
            else setUrl(url);
        }, 1000);
    }, [filterModel]);


    const handleEditCashdesks = () => {
        if (!checkedProducts || checkedProducts.length === 0) {
            warnToast(__("Please select at least one cashdesk."));
            return;
        }
        setIsEditModalOpen(true);
    };

    const closeEditModal = () => {
        setIsEditModalOpen(false);
    };

    const handleConfirmEdit = async () => {

        const payload = {
            cashdesks: checkedProducts.map(product => product.id),
            blockDate: formatDate(selectedDateEditModal),
        };

        try {
            const response = await axios.patch(`${config.API_BASE_URL}/cashdesks/edit/all`, payload, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token
                },
            });

            successToast(__("Successfully updated"));

            setIsEditModalOpen(false);

            setTimeout(() => {
                window.location.reload();
            }, 1000);
        } catch (error) {
            errorToast(__("Some error occurred"));
        }
    };

    // Giving business id to SidebarNavigation component

    let businessIdSidebar;
    let branchIdSidebar;
    let cashdeskIdSidebar;
    const getBusinessIdSidebar = (id, branchId) => {
        businessIdSidebar = id;
        branchIdSidebar = branchId;
        dispatch({ type: "SET_BUSINESS_ID", payload: businessIdSidebar });
        dispatch({ type: "SET_BRANCH_ID", payload: branchIdSidebar });
        dispatch({ type: "SET_CASHDESK_ID", payload: cashdeskIdSidebar });
    };


    if (loading)
        return (
            <div>
                <Preloader />
            </div>
        );

    if (error) return <div>{error}</div>;

    let cashdesksList = [];

    if (cashdesks) {
        cashdesks.map((item) => {
            let cashdesk = {
                uniqueID: item.uniqueID || "—",
                branchID: item.branchID?._id || "—",
                branchSlug: item.branchID?.slug || "",
                // branchName: item.branchID?.name || "—",
                businessID: item.businessID?._id,
                businessSlug: item.businessID?.slug || "",
                businessName: item.businessID?.name || "—",
                expiresAt: getParsedDate(item.licence?.blockDate) || "—",
                // approved: item.approved?.isApproved ? "Approved" : "Not approved",
                id: item._id,
                status: item.status || "—",
                fiscalModule: item.fiscalModule?.fiscalID,
                fiscalModuleId: item.fiscalModule?._id,
                appVersion : item.appletInfo?.appVersion
            };
            cashdesksList.push(cashdesk);
        });
    }

    const getFilteredRows = () => {
        if (!filterModel.items.length) return cashdesksList; // No filters applied

        return cashdesksList.filter((row) => {
            return filterModel.items.every((filter) => {
                const value = row[filter.columnField] ? row[filter.columnField].toString().toLowerCase() : "";
                const filterValue = filter.value ? filter.value.toLowerCase() : "";

                if (filter.operatorValue === "contains") {
                    return value.includes(filterValue);
                }
                if (filter.operatorValue === "equals") {
                    return value === filterValue;
                }
                return true;
            });
        });
    };

    const columns = [
        {
            field: "uniqueID",
            headerName: __("Cashdesk ID"),
            flex: 1,
            cellClassName: "mane-column--cell",
            renderCell: ({ row }) => {
                return (
                    <Box
                        display="flex"
                        justifyContent="flex-start"
                        width={"100%"}
                    >
                        <Link
                            to={state?.business
                              ? `/businesses/${businessID || state.business?._id}/cashdesks/${row.uniqueID}`
                                : `/businesses/${row.businessID}/branches/${row.branchName}/cashdesks/${row.uniqueID}`}
                            state={{
                                cashdesk: {
                                    id: row.id,
                                },
                                business: state?.business
                            }}
                            className="link"
                        >
                            <Typography
                                fontFamily="Manrope, sans-serif"
                                fontWeight={600}
                                fontSize={14}
                                color={colors.common["black"]}
                                textOverflow={"ellipsis"}
                                overflow={"hidden"}
                                whiteSpace={"nowrap"}
                                onClick={() => getBusinessIdSidebar(row.businessID, row.branchID)}
                            >
                                {row.uniqueID}
                            </Typography>
                        </Link>
                    </Box>
                );
            },
        },
        // {
        //     field: "branchName",
        //     headerName: __("Branch"),
        //     flex: 1,
        //     renderCell: ({ row }) => {
        //         return (
        //             <Box
        //                 display="flex"
        //                 justifyContent="flex-start"
        //                 width={"100%"}
        //                 onClick={() => {
        //                     navigate(
        //                         `/branches/${row.branchID}`,
        //                         {
        //                             state: {
        //                                 branch: {
        //                                     id: row.branchID,
        //                                     slug: row.branchSlug,
        //                                 },
        //                             },
        //                         },
        //                     );
        //                 }}
        //             >
        //                 <Typography
        //                     fontFamily="Manrope, sans-serif"
        //                     fontWeight={500}
        //                     fontSize={14}
        //                     color={colors.common["black"]}
        //                     textOverflow={"ellipsis"}
        //                     overflow={"hidden"}
        //                     whiteSpace={"nowrap"}
        //                 >
        //                     {row.branchName}
        //                 </Typography>
        //             </Box>
        //         );
        //     },
        // },
        {
            field: "businessName",
            headerName: __("Business"),
            flex: 1,
            renderCell: ({ row }) => {
                return (
                    <Box
                        display="flex"
                        justifyContent="flex-start"
                        width={"100%"}
                        onClick={() => {
                            navigate(
                                `/businesses/${
                                    row.businessSlug || row.businessID
                                }`,
                                {
                                    state: {
                                        business: {
                                            id: row.businessID,
                                            slug: row.businessSlug,
                                        },
                                    },
                                },
                            );
                        }}
                    >
                        <Typography
                            fontFamily="Manrope, sans-serif"
                            fontWeight={400}
                            fontSize={14}
                            color={colors.common["black"]}
                            textOverflow={"ellipsis"}
                            overflow={"hidden"}
                            whiteSpace={"nowrap"}
                        >
                            {row.businessName}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: "expiresAt",
            headerName: __("Blocking"),
            flex: 1,
            renderCell: ({ row: { expiresAt } }) => {
                return (
                    <Box
                        display="flex"
                        justifyContent="flex-start"
                        width={"100%"}
                    >
                        <Typography
                            fontFamily="Manrope, sans-serif"
                            fontWeight={400}
                            fontSize={14}
                            color={colors.common["black"]}
                            textOverflow={"ellipsis"}
                            overflow={"hidden"}
                            whiteSpace={"nowrap"}
                        >
                            {expiresAt}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: "fiscalModule",
            headerName: __("Fiscal Module"),
            flex: 1,
            renderCell: ({ row }) => {
                return (
                    <Box display="flex" justifyContent="flex-start"
                         to={`/fiscal/${row.fiscalModuleId}`}
                         onClick={() => {navigate(`/fiscal/${row.fiscalModuleId}`,);}}>
                            <Typography
                                fontFamily="Manrope, sans-serif"
                                fontWeight={400}
                                fontSize={14}
                                color={colors.common["black"]}
                            >
                                {__(row.fiscalModule)}
                            </Typography>
                    </Box>
                );
            },
        },
        // {
        //     field: "approved",
        //     headerName: __("Approved"),
        //     flex: 1,
        //     renderCell: ({ row: { approved } }) => {
        //         return (
        //             <Box display="flex" justifyContent="flex-start">
        //                 <Typography
        //                     fontFamily="Manrope, sans-serif"
        //                     fontWeight={400}
        //                     fontSize={14}
        //                     color={colors.common["black"]}
        //                 >
        //                     {__(approved)}
        //                 </Typography>
        //             </Box>
        //         );
        //     },
        // },
        {
            field: "status",
            headerName: __("Status"),
            type: "string",
            flex: 1,
            renderCell: (props) => {
                return (
                    <Box display="flex" justifyContent="center">
                        <StatusTag className={"sm"} text={props.value} />
                    </Box>
                );
            },
        },
        {
            field: "appVersion",
            headerName: __("Version"),
            renderCell: ({ row: { appVersion } }) => {
                return (
                    <Box display="flex" justifyContent="flex-start">
                        <Typography
                            fontFamily="Manrope, sans-serif"
                            fontWeight={400}
                            fontSize={12}
                            color={colors.common["black"]}
                        >
                            {appVersion ? appVersion : "-"}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: "action",
            headerName: __("Action"),
            type: "string",
            renderCell: ({ row, ...props }) => {
                return (
                    <Box display="flex" justifyContent="center">
                        <Action
                            onClick={() =>
                                navigate(
                                    `/cashdesks/${row.id}/edit`,
                                    {
                                        state: {
                                            cashdesk: {
                                                id: row.id,
                                                slug: row.slug,
                                            },
                                        },
                                    },
                                )
                            }
                            onClickView={() =>
                                navigate(`/cashdesks/${row.slug || row.id}`, {
                                    state: {
                                        ...state,
                                        cashdesk: {
                                            id: row.id,
                                            slug: row.slug,
                                        },
                                    },
                                })
                            }
                            text={props.value}
                            openModal={() => openModals(row?.id)}
                            viewLink={`/cashdesks/${props?.row?.uniqueID}`}
                            edit={!isClient}
                            deleteAction={!isClient}
                        />
                    </Box>
                );
            },
        },
    ];

    return (
        <div>
            <BreadCrumbs
                breadcrumbs={[{ label: "Cashdesks", item: "/cashdesks" }]}
            />
            <div
                className={`page page-cashdesk-table ${isClient ? "grid" : ""}`}
            >
                <Box>
                    <Filter
                        description={__("Cashdesks")}
                        filters={filters}
                        page={page}
                        search={searchInputValue}
                        setSearch={setSearchInputValue}
                        buttons={[
                            {
                                text: __("Edit"),
                                onClick: handleEditCashdesks,
                                classNames: "outline-error",
                            },
                            ...(state?.business?._id && state?.branch?._id
                                ? [
                                    {
                                        link: "/cashdesks/create",
                                        text: __("Add Cashdesk"),
                                        state: {
                                            business: {
                                                slug: state.business?._id,
                                                _id: state?.business?._id,
                                            },
                                            branch: {
                                                ...state.branch,
                                            },
                                        },
                                    },
                                ]
                                : []),
                        ]}
                    />
                    <Box
                        sx={{
                            "&": {
                                margin: "0",
                                height: "90vh",
                                minHeight: "350px",
                            },
                            "& .name-column--cell": {
                                color: colors.green[300],
                            },
                            "& .MuiDataGrid-footerContainer": {
                                borderTop: "none",
                            },
                        }}
                    >
                        <DataGrid
                            rows={cashdesksList}
                            columns={columns}
                            {...config.TABLE_CONFIG(__)}
                            checkboxSelection={isBusinessPage}
                            rowCount={controller.total}
                            pageSize={controller.pageSize}
                            page={controller.page}
                            onPageChange={(newPage) => {
                                dispatch(updatePageAction(newPage));
                            }}
                            onSelectionModelChange={(ids) => {
                                const selectedIDs = new Set(ids);
                                const selectedRowData = cashdesksList.filter((row) => selectedIDs.has(row.id.toString()));
                                setCheckedProducts(selectedRowData);
                            }}
                            apiRef={apiRef}
                            filterModel={filterModel}
                            onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
                            columnVisibilityModel={columnVisibilityModel}
                            onColumnVisibilityModelChange={handleColumnVisibilityChange}
                            componentsProps={{
                                columnsPanel: {
                                    sx: {
                                        "& .MuiDataGrid-panelFooter button:first-child":{
                                            display: "none"
                                        },
                                        "& .MuiDataGrid-panelFooter button:last-child": {
                                            display: "none"
                                        }
                                    }
                                }
                            }}
                        />
                    </Box>
                </Box>
                <Modal
                    className="delete-modal"
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                >
                    <div className="modal-bottom">
                        <ModalWindow
                            title={__("Are you sure?")}
                            text={__(
                                "Are you sure you want to permanently delete this cashdesk?",
                            )}
                            onClick={closeModal}
                            onClickDelete={removeCashdesk}
                        />
                    </div>
                </Modal>
            </div>

            {/* ======= MODALS ======= */}
            <Modal
                className="edit-modal"
                isOpen={isEditModalOpen}
                onRequestClose={closeEditModal}
            >
                <div className="modal-bottom">
                    <div className="component-modal-window">
                        <div className="modal-inner">
                            <div className="modal-title">
                                <h1 className="modal-window-title">
                                    {__("Block Date Change")}
                                </h1>
                            </div>
                            <div
                                className="close-icon"
                                onClick={() => {
                                    setIsEditModalOpen(false);
                                }}
                            ></div>
                            <div className="modal-body">
                                <BasicDatePicker
                                    value={selectedDateEditModal}
                                    onChange={(date) => {
                                        if (date) {
                                            setSelectedDateEditModal(date?.$d || date); // Ensure the correct value is set
                                        }
                                    }}
                                />
                            </div>
                            <div className="btn-container component-card-button">
                                <Button className="btn outline sm" onClick={closeEditModal} sx={{ textTransform: "none"}}>
                                    {__("Cancel")}
                                </Button>
                                <Button  className="btn" onClick={handleConfirmEdit} sx={{ textTransform: "none" }}>
                                    {__("Confirm")}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            {/* ======= MODALS ======= */}

        </div>
    );
};

export default PageCashdeskTable;
