import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { translate } from "../../../translations/translate";
import { ApiService } from "../../../utils/ApiService";
import { useLocation, useParams } from "react-router";
import { errorToast, successToast } from "../../../utils/toast";
import { useNavigate } from "react-router-dom";
import {parseFiscalID, parseVirtualID} from "../../../utils/helper.js";

import Button from "../../../components/global/Button";
import PageHeader from "../../../components/PageHeader";
import useApiHook from "../../../hooks/useApiHook";
import config from "../../../config";
import Preloader from "../../../components/Preloader";
import BreadCrumbs from "../../../components/BreadCrumbs";
import SelectComponent from "../../../components/Select";

import "./styles.sass";
import { PatternFormat } from "react-number-format";

const PageFiscalEdit = () => {
    const fiscalMask = "0000 0000 0000";
    const FiscalMask = [
        {
            mask: fiscalMask,
        },
    ];
    const { state } = useLocation();
    const { id } = useParams();
    const url = `${config.API_BASE_URL}/fiscalmodule/${
        state?.fiscal?.id || id
    }`;
    const { data, loading, error } = useApiHook(url);
    const navigate = useNavigate();

    const lang = useSelector((state) => state.languageReducer.lang);
    const __ = (key) => translate(lang, key);

    const Status = [
        {
            value: "reserved",
            label: __("Reserved"),
        },
        {
            value: "active",
            label: __("Active"),
        },
        {
            value: "free",
            label: __("Free"),
        },
        {
            value: "blocked",
            label: __("Blocked"),
        },
        {
            value: "defective",
            label: __("Defective"),
        },
        {
            value: "expired",
            label: __("Expired"),
        },
    ];

    const [dangerStatus, setDangerStatuses] = useState("");
    const [showField, setShowField] = useState(false);
    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm();

    const handleStatusChange = (statusName) => {
        setDangerStatuses(statusName);
    };

    useEffect(() => {
        if (
            Status.slice(-3).find(
                (eachStatus) => eachStatus.value === dangerStatus,
            )
        ) {
            setShowField(true);
        } else {
            setShowField(false);
        }
    }, [dangerStatus]);

    const [equal, setEqual] = useState(true);
    const [fiscalInitial, setFiscalInitial] = useState({});
    const [fiscalCurrent, setFiscalCurrent] = useState({});
    const { token } = useSelector((state) => state.authReducer);

    useEffect(() => {
        if (data?.data?.docs) {
            data?.data?.docs?.map((fiscal) => {
                setFiscalInitial({
                    fiscalPref: fiscal.fiscalID.slice(0, 2),
                    fiscalNumbers: parseFiscalID(fiscal.fiscalID),
                    fiscalStatus: fiscal.status,
                    comment: fiscal.comment,
                    reason: fiscal.deactivation?.reason,
                    fiscalID: fiscal.fiscalID,
                });
                setFiscalCurrent({
                    fiscalPref: fiscal.fiscalID.slice(0, 2),
                    fiscalNumbers: parseFiscalID(fiscal.fiscalID),
                    fiscalStatus: fiscal.status,
                    comment: fiscal.comment,
                    reason: fiscal.deactivation?.reason,
                    fiscalID: fiscal.fiscalID,
                });
            });
        }
    }, [data]);

    useEffect(() => {
        if (
            fiscalInitial.fiscalPref === fiscalCurrent.fiscalPref &&
            fiscalInitial.fiscalNumbers === fiscalCurrent.fiscalNumbers &&
            fiscalInitial.fiscalStatus === fiscalCurrent.fiscalStatus &&
            fiscalInitial.comment === fiscalCurrent.comment &&
            fiscalInitial.reason === fiscalCurrent.reason &&
            fiscalInitial.fiscalID === fiscalCurrent.fiscalID
        ) {
            setEqual(true);
        } else {
            setEqual(false);
        }
    }, [fiscalCurrent]);

    // CALLBACK FUNCTIONS
    const onCancelClick = function (e) {
        navigate("/fiscal");
    };

    const onSubmit = (e) => {
        e.preventDefault();
        const body = {
            fiscalID:
                fiscalCurrent?.fiscalPref +
                fiscalCurrent?.fiscalNumbers.split(" ").join(""),
            status: fiscalCurrent?.fiscalStatus,
            comment: fiscalCurrent?.comment,
            deactivation: {
                reason: fiscalCurrent?.reason,
            },
        };
        ApiService.patchEvent(`/fiscalmodule/${state?.id || id}`, token, body)
            .then((response) => {
                if (response.status === 200) {
                    successToast(__("Successfully updated"));
                    navigate(`/fiscal/${state?.id || id}`);
                }
            })

            .catch((error) => {
                errorToast(__(error.message));
            });
    };
    const buttons = [
        {
            name: "Back",
            link: "/fiscal",
            className: "outline",
        },
    ];
    useEffect(() => {
        data?.data?.docs?.map((item) => {
            setShowField(item?.deactivation?.reason);
        });
    }, [data]);
    if (loading)
        return (
            <div>
                <Preloader />
            </div>
        );
    if (error) return <div>{error.message}</div>;
    return (
        <div>
            <BreadCrumbs
                breadcrumbs={[
                    { label: "Fiscal", link: "/fiscal" },
                    { label: "Edit" },
                ]}
            />
            <div className="page component-page-fiscal-edit">
                <form onSubmit={onSubmit} className="page-create-inner">
                    <PageHeader
                        name={__("Edit Fiscal Module")}
                        buttons={buttons}
                    />
                    <div className="cards-inner">
                        <div className="left-side-cards">
                            {data?.data?.docs?.map((item, i) => (
                                <div key={i}>
                                    <div className="component-create-box-card">
                                        <div className="card-user">
                                            <div className="user-icon">
                                                <i className="icon-information" />
                                            </div>
                                            <div className="user-text">
                                                <p className="user-title">
                                                    {__(
                                                        "Fiscal Module Information",
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="input-inner">
                                            <div className="component-ui-input input-right input-item">
                                                <div className="component-switcher">
                                                    <label
                                                        className="ui-card-label"
                                                    >
                                                        <span className="ui-input-text">
                                                            {__("Fiscal ID")}
                                                        </span>
                                                        <input
                                                            defaultValue={item?.fiscalID?.slice(
                                                                0,
                                                                2,
                                                            )}
                                                            className="ui-input fiscal-id"
                                                            type="text"
                                                            disabled
                                                        />
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="component-ui-input">
                                                <label
                                                    className="ui-card-label"
                                                >
                                                    <input
                                                        defaultValue={parseFiscalID(
                                                            item?.fiscalID,
                                                        )}
                                                        className="ui-input fiscal-input"
                                                        type="text"
                                                        disabled
                                                    />
                                                </label>
                                            </div>
                                            <div className="component-ui-input">
                                                <label
                                                    className="ui-card-label"
                                                >
                                                    <span className="ui-input-text">
                                                        {__("Status")}
                                                    </span>
                                                    <SelectComponent
                                                        value={Status.find(
                                                            (status) =>
                                                                status?.value ===
                                                                fiscalCurrent?.fiscalStatus,
                                                        )}
                                                        options={Status}
                                                        onChange={(obj) => {
                                                            handleStatusChange(
                                                                obj.value,
                                                            );
                                                            setFiscalCurrent({
                                                                ...fiscalCurrent,
                                                                fiscalStatus:
                                                                    obj.value,
                                                            });
                                                        }}
                                                        name="status"
                                                    />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="input-inner">
                                            <div className="component-ui-input">
                                                <label
                                                    className="ui-card-label"
                                                >
                                                    <input
                                                        defaultValue={parseVirtualID(
                                                            item?.virtualID,
                                                        )}
                                                        className="ui-input "
                                                        type="text"
                                                        disabled
                                                    />
                                                    <span className="input-text">{__("Virtual Number")}</span>
                                                </label>
                                            </div>
                                            <div className="component-ui-input">
                                                <label
                                                  className="ui-card-label"
                                                >
                                                    <input
                                                      defaultValue={item?.cashdesk?.uniqueID}
                                                      className="ui-input "
                                                      type="text"
                                                      disabled
                                                    />
                                                    <span className="input-text">{__("Cashdesk Key")}</span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="input-inner">
                                            <div className="component-ui-input">
                                                <label
                                                    className="ui-card-label"
                                                >
                                                    <textarea
                                                        defaultValue={
                                                            item.comment
                                                        }
                                                        onChange={(e) => {
                                                            setFiscalCurrent({
                                                                ...fiscalCurrent,
                                                                comment:
                                                                    e.target
                                                                        .value,
                                                            });
                                                        }}
                                                        placeholder={__(
                                                            "Enter a comment",
                                                        )}
                                                        className="ui-input text-area"
                                                    />
                                                    <span className="ui-input-text span-text">
                                                        {__("Comment")}
                                                    </span>
                                                </label>
                                            </div>
                                            <div className="component-ui-input">
                                                <label
                                                  className="ui-card-label"
                                                >
                                                    <input
                                                      defaultValue={item?.business?.name}
                                                      className="ui-input "
                                                      type="text"
                                                      disabled
                                                    />
                                                    <span className="input-text">{__("Business Name")}</span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="input-inner">
                                            {showField && (
                                              <div className="component-ui-input">
                                                  <label
                                                    className="ui-card-label"
                                                  >
                                                        <textarea
                                                          defaultValue={
                                                              item
                                                                .deactivation
                                                                ?.reason
                                                          }
                                                          onChange={(e) =>
                                                            setFiscalCurrent(
                                                              {
                                                                  ...fiscalCurrent,
                                                                  reason: e
                                                                    .target
                                                                    .value,
                                                              },
                                                            )
                                                          }
                                                          className="ui-input text-area"
                                                        />
                                                        <span className="ui-input-text">
                                                            {__(
                                                              "Reason of Deactivation",
                                                            )}
                                                        </span>
                                                  </label>
                                              </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="component box-save">
                        <div className="not-save-item">
                            <div className="not-save-icon">
                                <i className="icon-saved" />
                            </div>
                            <div className="not-save-text">
                                <p className="save-text">
                                    {__("Not Saved Yet")}
                                </p>
                            </div>
                        </div>
                        <div className="not-save-button">
                            <Button
                                className="btn outline sm"
                                text={__("Cancel")}
                                onClick={onCancelClick}
                                type="button"
                            />
                            <Button
                                className="btn sm"
                                text={__("Save")}
                                type="submit"
                            />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default PageFiscalEdit;
