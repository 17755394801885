import React, { useEffect, useState } from "react";
import { IMaskInput } from "react-imask";
import { useSelector } from "react-redux";
import { translate } from "../../../translations/translate";
import { ApiService } from "../../../utils/ApiService";
import { errorToast, successToast } from "../../../utils/toast";
import { useLocation, useNavigate } from "react-router-dom";
import { searchClients } from "../../../utils/globalAPIRequests/searchClients";
import { searchStaff } from "../../../utils/globalAPIRequests/searchStaff";
import Button from "../../../components/global/Button";
import PageHeader from "../../../components/PageHeader";
import BreadCrumbs from "../../../components/BreadCrumbs";
import "./styles.sass";
import config from "../../../config";
import useApiHook from "../../../hooks/useApiHook";
import DragAndDrop from "../../DragAndDrop";
import TextArea from "../../../components/TextArea/TextArea";
import { replaceNewlinesWithSpaces } from "../../../utils/helper";

const PageBranchCreate = () => {
    const lang = useSelector((state) => state.languageReducer.lang);
    const __ = (key) => translate(lang, key);

    // DOCUMENTS
    const [currentDocType, setCurrentDocType] = useState("");
    const [currentCustomDescription, setCurrentCustomDescription] = useState("");
    const [isDragNDropOpen, setIsDragNDropOpen] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleDragNDropOpen = () => {
        setIsDragNDropOpen(!isDragNDropOpen);
    };

    const [documents, setDocuments] = useState([
        {
            index: 0,
            documentType: {
                key: "rentContract",
                description: "Rent Contract"
            }
        },
    ]);

    const handleSetDocument = (obj) => {
        let documentsTemp = documents;
        if (currentDocType === "custom") {
            const index = documentsTemp.length;
            const docObject = {
                index: index,
                documentType: {
                    key: currentDocType,
                    description: currentCustomDescription
                },
                _id: obj.id,
                name: obj.name
            };
            if (Array.isArray(documentsTemp)) {
                documentsTemp.push(docObject);
            }
            else {
                documentsTemp = [];
                documentsTemp.push(docObject);
            }
            setCurrentCustomDescription("");
        }
        else{
            documentsTemp.forEach((doc, i, arr) => {
                if(doc.documentType.key === currentDocType) {
                    arr[i]["_id"] = obj.id;
                    arr[i]["name"] = obj.name;
                }
            });
        }
        setDocuments([...documentsTemp]);
        setCurrentDocType(null);
        setBranch({
            ...branch,
            documents: documents,
        });
    };

    const handleCustomDocumentDescription = (index, description) => {
        const documentsTemp = documents;
        documentsTemp[index].documentType.description = description;
        setDocuments([...documentsTemp]);
        // setBranch({
        //     ...branch,
        //     documents: documents,
        // });
    };

    const deleteDoc = (index) => {
        let documentsTemp = documents;
        if(documentsTemp[index].documentType.key === "custom"){
            if (index > -1) {
                documentsTemp.splice(index, 1);
            }
        }
        else {
            delete documentsTemp[index]._id;
            delete documentsTemp[index].name;
        }
        setDocuments(documentsTemp);
        // setBranch({
        //     ...branch,
        //     documents: documents,
        // });
    };

    const navigate = useNavigate();
    const PhoneMask = "+998(00)000-00-00";
    const { state } = useLocation();
    const isNewBusiness = !!state?.isNewBusiness;
    const businessID = state.business._id || null;
    const storedUserData = useSelector((state) => state.authReducer);
    const isClient = storedUserData.role === "client";
    const token = storedUserData.token;

    // IF NO BUSINESS ID PRESENT
    if (!businessID) {
        navigate("/branches/create/error");
    }

    let business;
    if (businessID) {
        const businessReqResult = useApiHook(
            `${config.API_BASE_URL}/businesses/${businessID}`,
        );
        if (businessReqResult.data?.data?.docs?.length === 1) {
            business = businessReqResult.data.data.docs[0];
        }
    }

    const phoneEmailMask = [
        {
            mask: PhoneMask,
        },
    ];

    const [branch, setBranch] = useState({
        name: undefined,
        phones: undefined,
        address: {
            state: "Uzbekistan",
            region: "",
            city: "",
            district: "",
            street: "",
            house: "",
            ZIPCode: "",
        },
        contacts: {
            responsiblePerson: {
                name: {
                    first: "",
                    last: "",
                    middle: "",
                },
                phone: undefined,
                clientID: undefined,
            },
            manager: {
                name: {
                    first: undefined,
                    last: undefined,
                    middle: undefined,
                },
                phone: undefined,
                clientID: undefined,
            },
        },
        businessID: state?.business?._id || businessID || undefined,
        documents: null,
    });


    // Manager Smart Search Logic
    const [managerSearch] = useState("");
    const [manager, setManager] = useState(null);
    const [managerSearchResults, setManagerSearchResults] = useState([]);

    useEffect(() => {
        if (managerSearch.length > 0) {
            (async () => {
                return await searchStaff(
                    businessID || state.business?._id,
                    managerSearch,
                    token,
                );
            })().then((result) => {
                setManagerSearchResults(result);
            });
        } else {
            setManagerSearchResults(null);
        }
    }, [managerSearch]);

    useEffect(() => {
        setBranch({
            ...branch,
            contacts: {
                ...branch.contacts,
                manager: {
                    name: {
                        first: manager?.name?.first,
                        last: manager?.name?.last,
                        middle: manager?.name?.middle,
                    },
                    phone: manager?.phone,
                    clientID: manager?._id,
                },
            },
        });
    }, [manager]);

    // Branch Address SearchAutocomplete Logic
    const handleLegalAddressPaste = () => {
        if (state?.business) {
            setBranch({
                ...branch,
                address: state?.business?.address?.legal,
            });
        }
    };

    // Manager & Responsible Person auto assign if business is new
    const handleBoth = (obj) => {
        setManager(obj);
        setBranch({
            ...branch,
            contacts: {
                ...branch.contacts,
                manager: {
                    first: obj?.name?.first,
                    last: obj?.name?.last,
                    middle: obj?.name?.middle,
                },
                phone: obj?.phone,
                clientID: obj?._id,
            },
        });
    };

    if (isNewBusiness && !manager) {
        let clientObj;
        if (isClient) {
            clientObj = {
                name: {
                    first: storedUserData?.profileData?.name?.first,
                    last: storedUserData?.profileData?.name?.last,
                    middle: storedUserData?.profileData?.name?.middle,
                },
                displayName: {
                    first: storedUserData?.profileData?.displayName?.first,
                    last: storedUserData?.profileData?.displayName?.last,
                    middle: storedUserData?.profileData?.displayName?.middle,
                },
                _id: storedUserData?.profileData?._id,
                phone: storedUserData?.profileData?.phone,
            };
            handleBoth(clientObj);
        } else {
            (async () => {
                return await searchClients(state?.business?.owner, token);
            })().then((result) => {
                handleBoth(result[0]);
            });
        }
    }

    const onCancelClick = function () {
        navigate("/branches");
    };

    const onSubmit = (e) => {
        e.preventDefault();
        setBranch(branch);
        const body = branch;

        if (isSubmitting) return;
        setIsSubmitting(true);
        ApiService.postEvent("/branches/create", token, body)
            .then((response) => {
                if (response.status === 201) {
                    successToast(__("Successfully created"));
                    setIsSubmitting(false);
                    navigate("/cashdesks/create", {
                        state: {
                            business: state.business || business,
                            branch: response.data,
                            isNewBusiness: !!state.isNewBusiness,
                        },
                    });
                } else {
                    errorToast(__(response.message));
                }
            })
            .catch((error) => {
                errorToast(__(error.message));
                setIsSubmitting(false);
            });
    };
    const buttons = [
        {
            name: "Back",
            link: "/branches",
            state: {},
            className: "outline",
        },
    ];
    return (
        <div>
            <BreadCrumbs
                breadcrumbs={[
                    { label: "Branches", link: "/branches" },
                    { label: "Create" },
                ]}
            />
            <div className="page component-page-branch-create">
                <form onSubmit={onSubmit} className="page-inner">
                    <PageHeader name={__("Create Branch")} buttons={buttons} />
                    <div className="cards">
                        <div className="left-side-cards">
                            <div className="component-box-card">
                                <div className="card-header">
                                    <div className="card-info">
                                        <div className="card-icon">
                                            <i className="icon-information" />
                                        </div>
                                        <div className="card-text">
                                            <p className="card-title">
                                                {__("Branch Information")}
                                            </p>
                                            <p className="card-subtitle"></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="input-item">
                                    <div className="component-input">
                                        <label
                                            className="input-label"
                                        >
                                            <input
                                                value={branch?.name || ""}
                                                onChange={(e) =>
                                                    setBranch({
                                                        ...branch,
                                                        name: e.target.value,
                                                    })
                                                }
                                                className="input"
                                                type="text"
                                                placeholder={__(
                                                    "Enter branch name",
                                                )}
                                                maxLength={30}
                                                required
                                            />
                                            <span className="input-text">
                                                {__("Branch Name")}
                                            </span>
                                        </label>
                                    </div>
                                    <div className="component-input">
                                        <label
                                            className="input-label"
                                        >
                                            <IMaskInput
                                                value={branch?.phones || ""}
                                                onAccept={(value) => {
                                                    setBranch({
                                                        ...branch,
                                                        phones: value,
                                                    });
                                                }}
                                                className="mask-on-input card-input input"
                                                type="tel"
                                                placeholder="+(998) 99 222 33 44"
                                                mask={phoneEmailMask}
                                                autoComplete="off"
                                                ruquired="true"
                                            />
                                            <span className="input-text">
                                                {__("Phone")}
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                <div className="input-item">
                                    <div className="component-input">
                                        <label
                                            className="input-label"
                                        >
                                            <input
                                                defaultValue={state?.business?.name || business?.name}
                                                disabled
                                                placeholder={__("Enter business name")}
                                                className="input"
                                                type="text"
                                            />
                                            <span className="input-text">
                                                {__("Business")}
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="component-box-card">
                                <div className="card-header">
                                    <div className="card-info">
                                        <div className="card-icon">
                                            <i className="icon-business-building-location" />
                                        </div>
                                        <div className="card-text">
                                            <p className="card-title">
                                                {__("Branch Address")}
                                            </p>
                                        </div>
                                    </div>
                                    {!!isNewBusiness && (
                                        <Button
                                            type="button"
                                            className="btn sm"
                                            text={__(
                                                "Apply Business Legal Address",
                                            )}
                                            onClick={handleLegalAddressPaste}
                                        />
                                    )}
                                </div>
                                <div style={{ marginTop: 24 }}>
                                    <TextArea
                                        label={__("Address")}
                                        value={branch?.address?.region || ""}
                                        onChange={(e) =>
                                            setBranch({
                                                ...branch,
                                                address: {
                                                    ...branch.address,
                                                    region: replaceNewlinesWithSpaces(e.target.value),
                                                },
                                            })
                                        }
                                        placeholder={__("Enter Address")}
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="component-box-card">
                        <div className="card-header">
                            <div className="card-info">
                                <div className="card-icon">
                                    <i className="icon-tie"></i>
                                </div>
                                <div className="card-text">
                                    <p className="card-title">
                                        {__("Documents")}
                                    </p>
                                    <p className="card-subtitle">{__("Upload the required documents")}</p>
                                </div>
                            </div>
                        </div>

                        <div className="files-block">
                            {Array.isArray(documents) && documents.map((doc, index) => (
                              <div className="file-row" key={doc.index}>
                                  <div className="file-card disabled">
                                      {"" &&
                                        <img src="" alt="." className="icon"/>
                                      }
                                      <p className="text">{doc.name || ""}</p>
                                      <p className="label">{__(doc.documentType.description)}</p>
                                  </div>
                                  <div className={`file-card ${(doc.documentType.key === "custom") ? "" : "disabled"}`}>
                                      <input
                                        value={__(doc.documentType.description) || ""}
                                        disabled={!(doc.documentType.key === "custom")}
                                        onChange={(e) => {
                                            handleCustomDocumentDescription(index, e.target.value);
                                        }}
                                        type="text"
                                        className="text"
                                      />
                                  </div>
                                  <div className="component-card-button">
                                      {doc?._id
                                        ? (
                                          <button
                                            onClick={(e) => {
                                                e.preventDefault();
                                                deleteDoc(index);
                                            }}
                                            className="btn squared error"
                                          >
                                              <i className="icon-delete"></i>
                                          </button>
                                        )
                                        : (
                                          <button
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setCurrentDocType(doc.documentType.key);
                                                handleDragNDropOpen();
                                            }}
                                            className="btn squared"
                                          >
                                              <i className="icon-upload"></i>
                                          </button>
                                        )
                                      }
                                  </div>
                              </div>
                            ))
                            }
                        </div>
                    </div>
                    <div className="component box-save">
                        <div className="not-save-item">
                            <div className="not-save-icon">
                                <i className="icon-saved"></i>
                            </div>
                            <div className="not-save-text">
                                <p className="save-text">
                                    {__("Not Saved Yet")}
                                </p>
                            </div>
                        </div>
                        <div className="not-save-button">
                            <Button
                              className="btn outline sm"
                              text={__("Cancel")}
                              onClick={onCancelClick}
                              type="button"
                            />
                            <Button
                              className="btn sm"
                              text={__("Save")}
                              type="submit"
                              disabled={isSubmitting}
                            />
                        </div>
                        {isDragNDropOpen && (
                          <DragAndDrop
                            setter={handleSetDocument}
                            closeDragNDrop={handleDragNDropOpen}
                            uploadType={"document"}
                            docModel={manager?._id ? "Client" : "User"}
                            ownerID={manager?._id || storedUserData._id}
                                isUpload={"true"}
                            />
                        )}
                    </div>
                </form>
            </div>
        </div>
    );
};

export default PageBranchCreate;
