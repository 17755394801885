export const RU = {
    Address: "Адрес",
    // ----------------- AUTHORIZATION PAGE WORDS ----------------------
    Welcome: "Добро пожаловать",
    "Reset your password": "Сбросить пароль",
    "Enter branch name": "Введите название филиала",
    "Enter your phone number to continue":
        "Введите свой номер телефона, чтобы продолжить",
    "Enter your phone number and we will send you temporary code to reset your password":
        "Введите свой номер телефона, и мы вышлем вам временный код для сброса пароля",
    "Enter your name": "Введите свое имя",
    "Client Search": "Поиск клиента",
    "Receipt #": "Чек #",
    "Fiscal Document": "Фискальный Документ",
    "Refunded receipt": "Возвращенный чек",
    "Create Staff Member": "Создать сотрудника",
    "Forgot your number or lost it?": "Забыли свой номер или потеряли его?",
    "This phone number is already taken": "Этот номер телефона уже занят",
    "Enter the code from SMS": "Введите код из СМС",
    "Number entered incorrectly?": "Неправильно введен номер?",
    "Incorrect length of the phone number": "Неверная длина телефонного номера",
    "Send the code again": "Отправить код повторно",
    "sum": "сум",
    "You can send the code again after":
        "Вы можете повторно отправить код после",
    "Please enter the verification code we sent to":
        "Пожалуйста, введите проверочный код, который мы отправили на",
    "Create an account": "Завести аккаунт",
    "Please fill in your personal information to sign up":
        "Пожалуйста, заполните вашу личную информацию, чтобы зарегистрироваться",
    "Both User and Client have been found.":
        "Оба Пользователь, и Клиент были найдены.",
    "Select your desired login option:": "Выберите желаемый вариант входа:",

    // -------------- ERROR PAGES WORDS -------------
    "Internal Server Error": "Внутренняя ошибка сервера",
    "Server Error 500. We apologies and we are fixing the problem":
        "Ошибка сервера 500. Приносим свои извинения и устраняем проблему",
    "Page Not Found": "Страница не найдена",
    "The page you are looking for does not exist!":
        "Страница, которую вы ищете, не существует!",
    "You will receive a tracking link as soon as your order ships.":
        "Вы получите ссылку для отслеживания, как только ваш заказ будет отправлен.",

    // -------------- BUTTON WORDS -----------------
    Back: "Назад",
    Approve: "Подтвердить",
    "Sign in": "Войти",
    Cancel: "Отмена",
    Confirm: "Подтверждать",
    Delete: "Удалить",
    Save: "Сохранить",
    Submit: "Отправить",
    Generate: "Сгенерировать",
    Clear: "Очистить",
    "Add Unit": "Добавить единицу",
    "Add Staff Member": "Добавить сотрудника",
    "Add Fiscal": "Добавить модуль",
    "Add Role": "Добавить Роль",
    "Add Branch": "Добавить филиал",
    "Sign up": "Зарегистрироваться",
    "Add Client": "Добавить клиента",
    "Add Cashdesk": "Добавить кассу",
    "Add User": "Добавить пользователя",
    "Back to home": "Вернуться на главную",
    "Add Business": "Добавить предприятие",
    "Edit Profile": "Редактировать профиль",
    "Sign up new": "Зарегистрироваться",
    "Apply Business Legal Address": "Применить юридический деловой адрес",

    // ----------------------- INPUT LABELS --------------------
    "Child Categories": "Дочерние Категории",
    "Enter your company name": "Введите имя вашей компании",
    "DD/MM/YYYY": "ДД/ММ/ГГГГ",
    "dd/mm/yyyy": "дд/мм/гггг",
    TIN: "ИНН",
    "TIN / PINFL": "ИНН / ПИНФЛ",
    Key: "Ключ",
    Code: "Код",
    House: "Дом",
    City: "Город",
    Here: "Здесь",
    PINFL: "ПИНФЛ",
    State: "Страна",
    Street: "Улица",
    Status: "Статус",
    Phone: "Телефон",
    Owner: "Владелец",
    Select: "Выбрать",
    Region: "Область",
    District: "Район",
    Password: "Пароль",
    "First Name": "Имя",
    Position: "Должность",
    "Last Name": "Фамилия",
    Comment: "Комментарий",
    "OS Version": "Версия ОС",
    "Expire Date": "Годен до",
    "Middle Name": "Отчество",
    "MAC Address": "MAC-адрес",
    Email: "Электронная почта",
    ZIPCode: "Почтовый индекс",
    "Fiscal ID": "Фискальный ID",
    "Virtual Number": "Виртуальный номер",
    "Expires At": "Срок истечения",
    "Block Date": "Дата блокировки",
    "Type of App": "Тип приложения",
    "Date of Birth": "Дата рождения",
    "Your Picture": "Ваша фотография",
    "Branch Name": "Название филиала",
    "Branch Count": "В филиале",
    "App Version": "Версия приложения",
    "Bit Depth": "Разрядность системы",
    "Business Type": "Вид предприятия",
    "Enter you name": "Введите свое имя",
    "Fiscal Module": "Фискальный модуль",
    "Old Module": "Старый модуль",
    "New Module": "Новый модуль",
    "Old Fiscal ID": "Старый Фискальный ID",
    "New Fiscal ID": "Новый Фискальный ID",
    "Confirm Password": "Подтвердите пароль",
    "Repeat Password": "Повторите пароль",
    "Business Name": "Наименование фирмы",
    "Choose User": "Выберите пользователя",
    "Choose fiscal letter": "Выберите серию фискального модуля",
    "Version Checked At": "Версия проверена в",
    "Deactivation Reason": "Причина деактивации",
    "Manager Information": "Информация о менеджере",
    "Reason of Deactivation": "Причина деактивации",
    "Device Information": "Информация об устройстве",
    "Passport Series and Number": "Серия и номер паспорта",
    "Upload Your Picture": "Загрузить свою фотографию",
    "I don't have a Fiscal Module": "У меня нет фискального модуля",
    "The entered passwords do not match": "Введенные пароли не совпадают",
    "Enter your first name": "Введите ваше имя",
    "Enter your last name": "Введите вашу фамилию",
    "Enter your middle name": "Введите свое отчество",
    "Enter your email": "Введите адрес электронной почты",
    "Enter your birth date": "Введите дату своего рождения",
    "Enter business name": "Введите название своего бизнеса",
    "Enter state": "Введите страну",
    "Enter region": "Введите регион",
    "Enter city": "Введите город",
    "Enter district": "Введите район",
    "Enter street": "Введите улицу",
    "Enter house": "Введите дом",
    "Enter ZIPCode": "Введите почтовый индекс",
    "Start typing...": "Начните писать...",
    "Enter title": "Введите название",
    "Enter title in Russian": "Введите название на Русском",
    "Enter title in Uzbek": "Введите название на Узбекском",
    "Enter title in English": "Введите название на Английском",
    "Enter description": "Введите описание",
    "Enter description in Russian": "Введите описание на Русском",
    "Enter description in Uzbek": "Введите описание на Узбекском",
    "Enter description in English": "Введите описание на Английском",
    "Enter ICPS": "Введите ИКПУ",
    "Enter International Code": "Введите международный код",
    "Enter Article": "Введите артикул",
    "Enter VAT": "Введите НДС",
    "Enter Purchase Price": "Введите цену покупки",
    "Enter in stock": "В наличии",
    "Enter price": "Введите цену",
    // headings
    "Search Product in Tasnif": "Поиск товара в Тасниф",
    "Search for ICPS, International Code or Title in Russian or Uzbek":
        "Для поиска введите ИКПУ, штрих-код или название товара",
    "Tasnif Product Info": "Информация о товаре в Тасниф",
    "Marking": "Маркировка",
    "Not required": "Не требуется",
    "Required": "Требуется",
    "Product Information": "Информация о товаре",
    "Title (Russian)": "Название на Русском",
    Title: "Название",
    "Title (English)": "Название на Английском",
    "Title (Uzbek)": "Название на Узбекском",
    "Description (Russian)": "Описание на Русском",
    "Description (Uzbek)": "Описание на Узбекском",
    "Description (English)": "Описание на Английском",
    "Tax Information": "Налоговая информация",
    "No VAT": "Без НДС",
    ICPS: "ИКПУ",
    "Owner Type": "Тип владения",
    "(zero) – 'buying and selling'": " 0 (ноль) – 'покупает и продает'",
    "1 – (one) - 'self-made'": "1 (один) – 'произвел сам'",
    "2 (two) – 'service provider'": "2 (два) - 'обслуживающий'",
    "Int. Code": "Штрих-код",
    "Intenational Code": "Штрих-код",
    "Article": "Артикул",
    "Supply Information": "Информация о поставщике",
    "Commitent TIN/PINFL": "Комитент ИНН/ПИНФЛ",
    "Price Information": "Информация о цене",
    " Price": "Цена",
    soums: "сум",
    "Discount Start Date": "Дата начала скидки",
    "Discount End Date": "Дата конца скидки",
    "Search Categories": "Поиск категорий ",
    Categories: "Категории",
    "Versions": "Версия",
    // old

    "Code is Required": "Требуется код",
    "Title is Required": "Требуется заголовок",

    View: "Вид",
    Create: "Создать",
    Edit: "Редактировать",
    History: "История",

    "Enter a comment": "Введите комментарий",

    // ----------------- BLOCKS HEADINGS -------------------------
    Filter: "Фильтр",
    "Create Product for": "Создать товар для",
    Import: "Импорт",
    "Import of the Product": "Импорт Товаров",
    "Import of the Fiscal Modules": "Импорт Модулей",
    "Export of the Products": "Экспорт товаров",
    of: "из",
    "Reason:": "Причина:",
    "Number of failed Products:": "Количество не сохраненных товаров:",
    "fiscal modules imported successfully": "фискальных модулей сохранены успешно",
    "Picture successfully deleted": "Изображение успешно удалено",
    "products imported successfully": "товаров успешно сохранены",
    "was not found in Tasnif Products Database": "не найден в базе данных товаров Тасниф",
    "was not found in the catalog": "не найден в каталоге",
    "List of failed products:": "Список не сохраненных товаров:",
    "List of failed modules:": "Список не сохраненных модулей:",
    "fiscalID or virtualID is not unique in the database / FiscalID is not 14 charecters": "Фискальный ID или Виртуальный ID не уникален / Фискальный ID не содержит 14 символов",
    "Fiscal IDs and Virtual IDs should be unique": "Фискальный ID и Виртуальный ID должен быть уникальным",
    "Download the example of the Excel file for understanding the format of data to be imported":
        "Скачать пример Excel файла",
    "Import Products": "Импорт товаров",
    "Import Fiscal": "Импорт Модулей",
    "Export Products": "Экспорт товаров",
    "Upload the required documents": "Загрузите необходимые документы",
    "Are you sure you want to permanently delete this staff member?": "Вы уверены, что хотите навсегда удалить этого сотрудника?",
    "Are you sure you want to permanently delete this product?": "Вы уверены, что хотите навсегда удалить этот продукт?",
    "Are you sure you want to permanently delete this picture?": "Вы уверены, что хотите навсегда удалить эту фотографию?",
    "You are trying to delete a Products Category. When deleting a category all products within will be permanently deleted too. Are you sure you want to proceed?"
        : "Вы хотите удалить категорию товаров. При удалении категории товары внутр и нее так же безвозвратно удалятся. Вы уверены что хотите продолжить?",
    "Owner's Passport Scan": "Копия паспорта владельца",
    "Your Company Certificate": "Сертификат Вашей Фирмы",
    "Rent Contract": "Договор аренды",
    "Rent Contract (will be used for the first Branch as well)":
        "Договор аренды (будет также использован для первого филиала)",
    "Custom Document": "Дополнительный документ",
    "Enter description for your document": "Добавьте описание документу",
    File: "Файл",
    "Upload a file": "Загрузите файл",
    "Drag & Drop or": "Перетащите или",
    "Choose file": "Выберите файл",
    "Choose a manager!": "Выберите менеджера!",
    "to upload": "для загрузки",
    "You can upload only one document.":
        "Вы можете загрузить только один документ.",
    "Cashdesk EPS": "EPS Кассы",
    "Cashdesk Key": "Ключ от кассы",
    "Merchant ID": "Merchant ID",
    "Service ID": "Service ID",
    "Merchant User ID": "Merchant User ID",
    "Secret Key": "Secret Key",
    "Export Receipts": "Экспорт Чеков",
    "Branch ID": "Branch ID",
    "User Name": "Имя Пользователя",
    "Dealer": "Дилер",
    "Edit Role": "Изменить Роль",
    "Add Product Category": "Добавить Категорию",
    "Add Product": "Добавить товар",
    "Category Information": "Данные о Категории",
    "Category Settings": "Настройки Категории",
    "Parent category": "Родительская категория",
    "View Category": "Просмотр Категории",
    Documents: "Документы",
    Category: "Категория",
    "Create User": "Создать Пользователя",
    Fiscal: "Фискальные модули",
    Menu: "Меню",
    Block: " Блок",
    Shift: "Смена",
    Cheques: "Чеки",
    Report: "Отчет",
    Search: "Поиск",
    "Log out": "Выйти",
    Profile: "Профиль",
    Products: "Товары",
    Product: "Товар",
    Service: "Услуга",
    "Price Range": "Ценовой Диапазон",
    Settings: "Настройки",
    Description: "Описание",
    Permissions: "Разрешения",
    "Create Role": "Создать роль",
    Roles: "Роли",
    "Role Title": "Название роли",
    "Role Information": "Информация о роли",
    UserModelPermissions: "Разрешения для пользователя",
    ClientModelPermissions: "Разрешения для клиента",
    "Current Report Number": "Номер текущего отчета",
    "Report Info": "Информация для отчета",
    "Opened At" : "Открылся в",
    "Closed At" : "Закрылся в",
    "Sales": "Продажи",
    "Print": "Печать",
    "Total Sale": "Общая сумма продаж",
    "Total Sale Quantity": "Общее количество продаж",
    "Total Sale Card": "Общая сумма продаж по карте",
    "Total Sale VAT": "Общая сумма НДС с продажи",
    "Total Sale Cash": "Общая сумма денежных средств от продажи",
    "Refunds": "Возвраты",
    "Total Refund": "Общая сумма возвратов",
    "Total Refund Quantity": "Общее количество возвратов",
    "Total Refund Card": "Общая сумма возвратов по карте",
    "Total Refund VAT": "Общая сумма НДС с возвратов",
    "Total Refund Cash": "Полная сумма возврата наличными",
    "Reports": "Отчеты",
    "Report ID": "Идентификатор отчета",
    "Report Number": "Номер отчета",
    "Fiscal Sign": "Фискальный знак",
    "View Cart": "Посмотреть корзину",
    "View Withdrawal": "Просмотр списания",
    "View Arrival": "Просмотр прихода",
    "View Transfer": "Просмотр трансфера",
    "View Report": "Посмотреть отчет",
    "Display Name": "Отображаемое имя",
    "Create Product": "Создать товара",
    "Create Arrival": "Создать приход",
    "Create Withdrawal": "Создать списание",
    "Create Transfer": "Создать трансфер",
    "View Product": "Отображение товара",
    "Edit Product": "Редактирование товара",
    "Legal Address": "Юридический адрес",
    "Actual Address": "Фактический адрес",
    "Child Category": "Младшая категория",
    "Cheques Quantity": "Количество чеков",
    "Business Address": "Адрес предприятия",
    "Cashdesk License": "Лицензия Кассы",
    "Total Information": "Суммарная информация",
    "Parent Category": "Родительская категория",
    "Client Information": "Информация о клиенте",
    "Number of Products": "Количество товаров",
    "Fiscal Information": "Фискальные данные",
    "Applet Information": "Информация об апплете",
    "Passport Information": "Паспортные данные",
    "Is Oldest Category": "Самая верхняя категория",
    "User Information": "Данные пользователя",
    "Fiscal Module Number": "Номер фискального модуля",
    "Business Information": "Информация о предприятии",
    "Create Users Info": "Создать информацию о пользователе",
    "Delete Users Info": "Удалить информацию о пользователе",
    "Update Users Info": "Обновить информацию о пользователе",
    "Personal Status Information": "Информация о личном статусе",
    "Retrieve Users Info": "Получить информацию о пользователе",
    "Create Product Category for": "Создать категорию товаров для",
    "Create Fiscal Module": "Создать фискальный модуль",
    "View Fiscal Module": "Просмотр фискального модуля",
    "Edit Fiscal Module": "Изменить фискальный модуль",
    "Branch Information": "Информация о филиале",
    "Cashdesk Information": "Информация о кассе",
    "View User": "Просмотр пользователя",
    "Edit User": "Изменить пользователя",
    "Create Business": "Создать бизнес",
    "View Cashdesk": "Просмотреть кассу",
    "View Business": "Просмотр бизнеса",
    "Edit Business": "Изменить бизнес",
    "Create Cashdesk": "Создать кассу",
    "Create Client": "Создать Клиента",
    "Create Branch": "Создать филиал",
    "Branch Address": "Адрес филиала",
    "View Client": "Просмотр клиента",
    "Edit Client": "Изменить клиента",
    "Edit Cashdesk": "Изменить кассу",
    "Edit Category": "Изменить Категорию",
    "View Branch": "Посмотр филиала",
    "Edit Branch": "Изменить филиал",
    Uzbek: "Узбекский",
    English: "Английский",
    Russian: "Русский",
    Preferences: "Предпочтения",
    User: "Пользователь",
    Client: "Клиент",
    Exit: "Выйти",
    "Users information": "Информация о пользователе",

    // ------------------ SIDEBAR WORDS ----------------------
    Information: "Информация",
    Home: "Главная",
    Clients: "Клиенты",
    Cashdesks: "Кассы",
    Branches: "Филиалы",
    Users: "Пользователи",
    Businesses: "Предприятия",
    Staff: "Сотрудники",
    "View All": "Показать все",
    "Fiscal Modules": "Фискальные модули",
    "Fiscal Modules ID": "ID фискальных модулей",
    "Personal Information": "Персональная информация",
    "Users credentials": "Учетные данные пользователей",
    "Fiscal Module Information": "Информация о фискальном модуле",

    Loading: "Загрузка",
    "Something Went Wrong": "Что-то пошло не так",
    "The Republic of Uzbekistan": "Республика Узбекистан",

    // ------------------------ TABLE WORDS ------------------------------
    "Filter value" : "Значение фильтра",
    "Columns" : "Столбцы",
    "Operator" : "Оператор",
    "Value" : "Значение",
    "contains" : "содержит",
    "equals" : "равно",
    "start with" : "начинается",
    "ends with" : "заканчивается",
    "is empty" : "пусто",
    "is any of" : "любой",
    "is not empty" : "не пусто",
    "Unsort": "Сбросить сортировку",
    "Sort by ASC": "Сортировать по возрастанию",
    "Sort by DESC": "Сортировать по убыванию",
    "Hide": "Скрыть",
    "Show columns": "Показать столбцы",
    "Find column": "Найти столбец",
    "Column title" : "Заголовок столбца",
    "Hide all": "СКРЫТЬ ВСЕ",
    "Show all": "ПОКАЗАТЬ ВСЕ",
    "rows selected": "выбранные строки",
    "row selected": "выбранная строка",
    "No rows": "Нет данных",
    "No results found.": "Нет данных",
    "No additional phones": "Дополнительных телефонов нет",
    "Rows per page": "Строк на страницу",
    Communicator: "Коммуникатор",
    Mobile: "Мобильное",
    "soum": "сум",
    IE: "ИП",
    LLC: "ООО",
    JSC: "ОАО",
    PC: "ЧП",
    SUE: "ГУП",
    "Z-Report": "Z-Отчет",
    VAT: "НДС",
    Name: "Имя",
    Type: "Тип",
    Test: "Тест",
    Role: "Роль",
    Card: "Карта",
    Credit: "Кредит",
    Price: "Цена",
    Total: "Всего",
    Cash: "Наличные",
    Branch: "Филиал",
    Expired: "Истёк",
    Cashdesk: "Касса",
    Refund: "Возврат",
    Free: "Свободен",
    Debited: "Списано",
    Saved: "Сохранено",
    Active: "Активный",
    Discount: "Скидка",
    Sale: "Продажа",
    sale: "Продажа",
    Question: "Вопрос",
    Action: "Действие",
    Deleted: "Удалено",
    Resolved: "Закрыт",
    SubTotal: "Подытог",
    Expires: "Истекает",
    Manager: "Менеджер",
    Cashier: "Кассир",
    Image: "Изображение",
    Received: "Получено",
    "Receipt Number": "Номер Чека",
    Receipts: "Чеки",
    product: "товар",
    service: "сервис",
    "Created At": "Cозданный в",
    Approved: "Одобрено",
    Defected: "Дефективный",
    Amounts: "Количество",
    amount: "(сумы)",
    percentage: "(проценты)",
    Percentage: "Процент",
    Preload: "Аванс",
    Completed: "Завершено",
    Business: "Предприятие",
    "In Stock": "На складе",
    Director: "Руководитель",
    Bookkeeper: "Бухгалтер",
    "Support Head": "Глава поддержки",
    "Support Assistant": "Ассистент службы поддержки",
    "Project Manager": "Проект Менеджер",
    Developer: "Разработчик",
    Blocked: "Заблокировано",
    "Cashdesk ID": "ID кассы",
    "Updated At": "Обновлено в",
    "Approved By": "Утверждено",
    "In Progress": "В процессе",
    Quantity: "Количество",
    Reserved: "Зарезервированный",
    "Not Approved": "Не одобрено",
    "Not approved": "Не одобрено",
    "Nothing found": "Нечего не найдено",
    "Not Found": "Не найдено",
    "Add a new product": "Добавить новый товар",
    "E-Cash": "Электронные деньги",
    Notifications: "Уведомления",
    Date: "Дата",
    Amount: "Количество",
    Labeling: "Маркировка",
    Barcode: "Штрих-код",
    Source: "Источник",
    Receiver: "Принимающий",
    Sold: "Продано",
    Utilized: "Утилизированный",
    "Update Package Code": "Обновить код упаковки",
    "Source Branch": "Исходный филиал",
    "Receiver Branch": "Приемный филиал",
    "Production Date": "Дата производства",
    "Expiry Date": "Срок годности",
    "Selling Price": "Цена продажи",
    "Total Sum": "Общая сумма",
    "Total Number of Items": "Количество предметов",
    "Number of Items": "Количество наименований",
    "Invoice Number": "Номер счета-фактуры",
    "Supplier's INN": "ИНН поставщика",
    "Product Type": "Тип товара",
    "Not Assigned": "Не назначено",
    "Not assigned": "Не назначено",
    Assigned: "Назначено",
    Warehouse: "Cклад",
    Withdrawals: "Списания",
    Arrivals: "Приходы",
    Transfers: "Трансферы",
    "Add Arrival": "Добавить приход",
    "Add Withdrawal": "Добавить списание",
    "Transfer": "Трансфер",
    "Liquidation Number": "Номер списания",
    "Total Count": "Общее количество",
    "The same branches have been selected": "Выбран один и тот же филиал",
    "Add new phone number": "Добавить новый номер телефона",
    "Edit phone number": "Изменить номер телефона",
    "Phone Number": "Номер телефона",
    "New Update": "Новое обновление",
    "New Row": "Новая строка",
    "Purchase Price": "Цена покупки",
    "Owner Search": "Поиск владельца",
    "Dealer Search": "Поиск дилера",
    "Overall Discount": "Общая скидка",
    "Manual Discount": "Ручная скидка",
    "Not Saved Yet": "Еще не сохранено",
    "Not saved yet": "Еще не сохранено",
    "Manager Search": "Поиск менеджера",
    "Approved Date": "Утвержденная дата",
    "Total Quantity": "Общая численность",
    "Page Dashboard": "Панель инструментов",
    "First Cheque Number": "Первый чек номер",
    "Product Quantity": "Количество товара",
    "Last Cheque Number": "Последний чек номер",
    "Responsible Person": "Ответственное лицо",
    "Create New Password": "Создать новый пароль",
    "Create To Continue": "Создайте, чтобы продолжить",
    Continue: "Продолжить",
    "Responsible Person Search": "Поиск ответственного лица",
    "The product is not selected or not all fields are filled in": "Mahsulot tanlanmagan yoki barcha maydonlar to'ldirilmagan",
    "Are you sure?": "Вы уверены?",
    "Are you sure you want to permanently delete this user?":
        "Вы уверены, что хотите навсегда удалить этого пользователя?",
    "Are you sure you want to permanently delete this fiscal module?":
        "Вы уверены, что хотите навсегда удалить этот фискальный модуль?",
    "Are you sure you want to permanently delete this client?":
        "Вы уверены, что хотите навсегда удалить этого клиента?",
    "Are you sure you want to permanently delete this business?":
        "Вы уверены, что хотите навсегда удалить это предприятие?",
    "Are you sure you want to permanently delete this branch?":
        "Вы уверены, что хотите навсегда удалить этот филиал?",
    "Are you sure you want to permanently delete this cashdesk?":
        "Вы уверены, что хотите навсегда удалить эту кассу?",
    "Are you sure you want to permanently delete this unit?":
        "Вы уверены, что хотите навсегда удалить эту единицу измерения?",
    "Are you sure you want to permanently delete this report?":
        "Вы уверены, что хотите навсегда удалить этот отчёт?",
    "Are you sure you want to permanently delete this cheque?":
        "Вы уверены, что хотите навсегда удалить этот чек?",
    "Are you sure you want to permanently delete this category?":
        "Вы уверены, что хотите навсегда удалить эту категорию?",
    "Are you sure you want to permanently delete this cart?":
        "Вы уверены, что хотите навсегда удалить эту корзину?",
    "Are you sure you want to export your products into Excel file?":
        "Вы уверены, что хотите экспортировать весь список товаров?",

    // -------------------- USER CREATE -> PASSWORD ----------------------------
    "Password change information": "Информация о смене пароля",
    "For your security, we recommend that you change your password every 3 months at most.":
        "В целях вашей безопасности мы рекомендуем менять пароль не чаще, чем каждые 3 месяца.",

    // --------------------NOTIFICATION WORDS--------------------------------------
    "min ago": "минут назад",

    // PROFILE DROPDOWN
    Theme: "Тема",

    // FILTERS
    Reset: "Сбросить",
    Apply: "Применить",
    "Select All": "Выбрать Все",
    "Clear All": "Очистить Всё",

    Unit: "Единица измерения",

    // PRODUCT UNITS
    Kilo: "Килограмм",
    Gram: "Грамм",
    Ton: "Тонна",
    Centimeter: "Сантиметр",
    Meter: "Метр",
    Liter: "Литр",
    Milliliter: "Миллилитр",
    "Cubic meter": "Кубический метр",
    "Square meter": "Квадратный метр",
    Piece: "Штука",
    Pack: "Блок",

    // PERMISSIONS
    "Retrieve Users info": "Получение информации о пользователях",
    "Delete User": "Удалить пользователя",
    "Update User Role info": "Обновление информации о роли пользователя",
    "Retrieve Permission info": "Получение информации о разрешении",
    "Create Log": "Создать журнал",
    "Retrieve IP address": "Получение IP-адреса",
    "Create Ticket": "Создать билет",
    "Post Message to Ticket": "Отправить сообщение в тикет",
    "Retrieve Upload": "Получить загрузку",
    "Retrieve Notifications": "Получение уведомлений",
    "Update Fiscal Module": "Обновление фискального модуля",
    "Create Employee": "Создать сотрудника",
    "Delete Employee": "Удалить сотрудника",
    "Update Employee Position info": "Обновление информации о должности сотрудника",
    "Retrieve Page": "Получить страницу",
    "Delete Client": "Удалить клиента",
    "Update Business": "Обновление бизнеса",
    "Retrieve Business Branch": "Получить филиал предприятия",
    "Create Cashdesks": "Создание кассовых аппаратов",
    "Delete Cashdesks": "Удалить кассовые аппараты",
    "Update Users info": "Обновление информации о пользователях",
    "Create User Role": "Создание роли пользователя",
    "Delete User Role": "Удаление роли пользователя",
    "Update Permission info": "Обновление информации о разрешении",
    "Retrieve Log info": "Получение информации из журнала",
    "Update IP address": "Обновление IP-адреса",
    "Retrieve Ticket": "Получить билет",
    "Post Comment to Ticket": "Отправить комментарий к билету",
    "Update Upload": "Обновление Загрузки",
    "Retrieve Tasnif Product": "Получить товар Tasnif",
    "Retrieve Employee info": "Получение информации о сотрудниках",
    "Create Employee Position": "Создание должности сотрудника",
    "Delete Employee Position": "Удалить должность сотрудника",
    "Update Page": "Обновление страницы",
    "Retrieve Client info": "Получение информации о клиенте",
    "Delete Business": "Удалить бизнес",
    "Update Business Branch": "Обновление бизнес-филиала",
    "Retrieve Cashdesks": "Получение кассовых аппаратов",
    "Retrieve User Role": "Получение роли пользователя",
    "Create Permission": "Создать разрешение",
    "Delete Permission": "Удалить разрешение",
    "Create IP address": "Создание IP-адреса",
    "Delete IP address": "Удалить IP-адрес",
    "Update Ticket": "Обновлять Билет",
    "Create Uploads": "Создание загрузок",
    "Create Notifications": "Создание уведомлений",
    "Retrieve Fiscal Module": "Получение фискального модуля",
    "Update Tasnif Product": "Обновление товара Tasnif",
    "Update Employee info": "Обновление информации о сотрудниках",
    "Retrieve Employee Position info": "Получение информации о должности сотрудника",
    "Create Page": "Создать страницу",
    "Delete Page": "Удалить страницу",
    "Update Client info": "Обновление информации о клиенте",
    "Client's Passport Scan": "Скан паспорта клиента",
    "Retrieve Business": "Извлечь бизнес",
    "Create Business Branch": "Создание филиала",
    "Delete Business Branch": "Удалить филиал",
    "Update Cashdesks": "Обновление кассовых аппаратов",
    "Create Cart": "Создать корзину",
    "Update Cart": "Обновление корзины",
    "Retrieve Cheques": "Получение чеков",
    "Retrieve Reports": "Получение отчетов",
    "Delete Unit": "Удалить устройство",
    "Update Product": "Обновление товара",
    "Retrieve Product Category": "Получить категорию товара",
    "Delete Client Permission": "Удаление клиентского разрешения",
    "Create Client Role": "Создание роли клиента",
    "Update Client Role": "Обновление роли клиента",
    "Delete Cart": "Удалить корзину",
    "Create Cheque": "Создать чек",
    "Update Cheque": "Обновление чека",
    "Update Report": "Отчет об обновлении",
    "Retrieve Unit": "Извлечь устройство",
    "Delete Product": "Удалить товар",
    "Create Product Category": "Создать категорию товара",
    "Update Product Category": "Обновление категории товара",
    "Retrieve Client Permission": "Получение разрешений клиента",
    "Delete Client Role": "Удалить роль клиента",
    "Initialize Cashdesk Application": "Инициализация приложения Cashdesk",
    "Retrieve Cart": "Извлечь корзину",
    "Delete Cheque": "Удалить чек",
    "Create Report": "Создать отчет",
    "Create Unit": "Создать подразделение",
    "Update Unit": "Обновлять устройство",
    "Retrieve Product": "Извлечь товар",
    "Delete Product Category": "Удалить категорию товара",
    "Create Client Permission": "Создание разрешений для клиентов",
    "Update Client Permission": "Обновление разрешений клиента",
    "Retrieve Client Roles": "Получение ролей клиентов",

    // TOASTS

    "ValidationError - Role validation failed: title: Error, expected `title` to be unique. Value: `dee`": "Ошибка проверки роли: {{field}}: Ошибка, ожидалось, что {{field}} будет уникальным. Значение: `{{value}}`",
    "Invoice successfully created": "Счет успешно создан",
    "Business validation failed: businessType: Business type is required": "Проверка бизнеса не удалась: businessType: требуется тип бизнеса",
    "The provided phone number is invalid": "Указанный номер телефона недействителен",
    "It looks like you don't have access to this request. Please contact support.": "Похоже, у вас нет доступа к этому запросу. Пожалуйста, свяжитесь со службой поддержки.",
    "Password should be at least 8 characters long.": "Длина пароля должна составлять не менее 8 символов",
    "Password should contain at least one alphabetic character.": "Пароль должен содержать хотя бы один буквенный символ",
    "Password should contain at least one numeric character.": "Пароль должен содержать хотя бы один цифровой символ",
    "Some error occurred": "Произошла какая-то ошибка",
    "User successfully updated": "Пользователь успешно обновлен",
    "User successfully created": "Пользователь успешно создан",
    "Successfully updated": "Успешно обновлено",
    "Successfully created": "Успешно создано",
    "File was successfully uploaded": "Файл успешно загружен",
    "File was partially uploaded": "Файл частично загружен",
    "An error occurred importing products. ": "Произошла ошибка при импорте продуктов. ",
    "Something went wrong. ": "Что-то пошло не так. ",
    "Successfully deleted": "Успешно удалено",
    "Please, choose a  unit": "Пожалуйста, выберите единицу измерения",
    "Please check some product to delete": "Выберите продукт, который хотите удалить",
    "Please check some category to delete": "Выберите категорию, которую хотите удалить",
    "Please, choose a product from Tasnif Database": "Выберите продукт с базы данных Tasnif",
    "You should first create a category": "Для начала создайте категорию",
    "Please, choose Start Date and End Date of discount": "Выберите дату начала и окончания скидки",
    "Please, choose at least one category": "Выберите хотя бы одну категорию",
    "Code sent to your phone number": "Мы отправили код вам на телефон",
    "New password was successfully applied": "Новый пароль успешно применен",
    "Delete your file and try again": "Удалите файл и попробуйте еще раз",
    "You did not provide a proper fiscal module": "Вы предоставили неверный фискальный модуль",
    "You are required to upload an image here. Available formats: .jpg, .png, .webp, .svg":
        "Здесь необходимо загрузить изображение. Доступные форматы: .jpg, .png, .webp, .svg.",
    "User or Client does not exist": "Пользователя с таким номером не существует",
    "Select an application type": "Выберите тип приложения",
    "Select the type of business": "Выберите тип предприятия",
    "Multiple files are not allowed!": "Вы можете загрузить только один файл",
    "You pasted wrong OTP format": "Вы вставили неправильный формат OTP",
    "Export to PDF": "Экспортировать в PDF"
};
