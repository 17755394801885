import React, { useState } from "react";
import { useSelector } from "react-redux";
import { ApiService } from "../../../utils/ApiService";
import { successToast, errorToast } from "../../../utils/toast";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { translate } from "../../../translations/translate";

import Button from "../../../components/global/Button";
import PageHeader from "../../../components/PageHeader";
import cleanseObject from "../../../utils/cleanseObject";
import BreadCrumbs from "../../../components/BreadCrumbs";

import "./styles.sass";

const PageCategoryCreate = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { businessID } = useParams();
    const storedUserData = useSelector((state) => state.authReducer);
    const token = storedUserData.token;
    const lang = useSelector((state) => state.languageReducer.lang);

    const [isSubmitting, setIsSubmitting] = useState(false);

    const __ = (key) => translate(lang, key);

    const [category, setCategory] = useState({
        title: {
            uz: undefined,
            ru: undefined,
            en: undefined,
        },
        description: {
            uz: undefined,
            ru: undefined,
            en: undefined,
        },
        parentCategory: undefined,
    });

    const onCancelClick = function() {
        navigate(`/businesses/${state?.business?.slug || state?.business?._id || businessID}/categories`, {state: {
            business: {
                id: state?.business?._id || businessID,
                name: state?.business.name,
                slug: state?.business?.slug
            }
        }});
    };

    const onSubmit = (e) => {
        e.preventDefault();
        const body = cleanseObject(category);
        if (isSubmitting) return;
        setIsSubmitting(true);
        ApiService.postEvent(
            `/businesses/${
                state?.business?._id || businessID
            }/product-categories`,
            token,
            body,
        )
            .then((response) => {
                setIsSubmitting(false);
                if (response) {
                    successToast(__("Successfully created"));
                    navigate(
                        `/businesses/${
                            state?.business?.slug ||
                            state?.business?._id ||
                            businessID
                        }/categories`,
                        {
                            state: {
                                business: state?.business,
                            },
                        },
                    );
                } else {
                    errorToast(__(response.message));
                }
            })
            .catch((error) => {
                setIsSubmitting(false);

                errorToast(__(error.message));
            });
    };
    const buttons = [
        {
            name: "Back",
            link: `/businesses/${
                state?.business?._id || businessID
            }/categories`,
            state: {
                business: {
                    _id: state?.business?._id || businessID,
                    name: state?.business?.name,
                },
            },
            className: "outline",
        },
    ];
    return (
        <div>
            <BreadCrumbs
                breadcrumbs={[
                    { label: "Categories", link: "/categories" },
                    { label: "Create" },
                ]}
            />
            <div className="page component-page-business-create">
                <form onSubmit={onSubmit} className="page-inner">
                    <PageHeader
                        buttons={buttons}
                        name={`${__("Create Product Category for")} "${
                            state?.business?.name
                        }"`}
                    />
                    <div className="cards">
                        <div className="left-side-cards">
                            <div className="component-box-card">
                                <div className="card-header">
                                    <div className="card-info">
                                        <div className="card-icon">
                                            <i className="icon-information" />
                                        </div>
                                        <div className="card-text">
                                            <p className="card-title">
                                                {__("Category Information")}
                                            </p>
                                            <p className="card-subtitle" />
                                        </div>
                                    </div>
                                </div>
                                <div className="input-item">
                                    <div className="component-input full">
                                        <label
                                            className="input-label"
                                        >
                                            <input
                                                onChange={(e) =>
                                                    setCategory({
                                                        ...category,
                                                        title: {
                                                            uz: e.target.value,
                                                            ru: e.target.value,
                                                            en: e.target.value,
                                                        },
                                                    })
                                                }
                                                placeholder={__(
                                                    "Enter title",
                                                )}
                                                className="input"
                                                type="text"
                                                required={true}
                                            />
                                            <span className="input-text">
                                                {__("Title")}
                                            </span>
                                        </label>
                                    </div>
                                    {/* Other titles*/}
                                   {/* <div className="component-input">
                                        <label
                                            className="input-label"
                                            htmlFor="titleRu"
                                        >
                                            <input
                                                onChange={(e) =>
                                                    setCategory({
                                                        ...category,
                                                        title: {
                                                            ...category.title,
                                                            ru: e.target.value,
                                                        },
                                                    })
                                                }
                                                id="titleRu"
                                                placeholder={__(
                                                    "Enter title in Russian",
                                                )}
                                                className="input"
                                                type="text"
                                                required={true}
                                            />
                                            <span className="input-text">
                                                {__("Title")} ({__("Russian")})
                                            </span>
                                        </label>
                                    </div>
                                    <div className="component-input">
                                        <label
                                            className="input-label"
                                            htmlFor="titleEn"
                                        >
                                            <input
                                                onChange={(e) =>
                                                    setCategory({
                                                        ...category,
                                                        title: {
                                                            ...category.title,
                                                            en: e.target.value,
                                                        },
                                                    })
                                                }
                                                placeholder={__(
                                                    "Enter title in English",
                                                )}
                                                id="titleEn"
                                                className="input"
                                                type="text"
                                                required={true}
                                            />
                                            <span className="input-text">
                                                {__("Title")} ({__("English")})
                                            </span>
                                        </label>
                                    </div> */}
                                </div>
                                <div className="input-item">
                                    {/* <div className="component-input full">
                                        <label
                                            className="input-label"
                                        >
                                            <textarea
                                                onChange={(e) =>
                                                    setCategory({
                                                        ...category,
                                                        description: {
                                                            ...category.description,
                                                            uz: e.target.value,
                                                        },
                                                    })
                                                }
                                                placeholder={__(
                                                    "Enter description",
                                                )}
                                                className="input"
                                                rows={3}
                                            />
                                            <span className="input-text">
                                                {__("Description")}
                                            </span>
                                        </label>
                                    </div> */}
                                    {/* Other descriptions */}
                                   {/* <div className="component-input">
                                        <label
                                            className="input-label"
                                            htmlFor="descriptionRu"
                                        >
                                            <textarea
                                                onChange={(e) =>
                                                    setCategory({
                                                        ...category,
                                                        description: {
                                                            ...category.description,
                                                            ru: e.target.value,
                                                        },
                                                    })
                                                }
                                                placeholder={__(
                                                    "Enter description in Russian",
                                                )}
                                                id="descriptionRu"
                                                className="input"
                                                rows={3}
                                            />
                                            <span className="input-text">
                                                {__("Description")} (
                                                {__("Russian")})
                                            </span>
                                        </label>
                                    </div>
                                    <div className="component-input">
                                        <label
                                            className="input-label"
                                            htmlFor="descriptionEn"
                                        >
                                            <textarea
                                                onChange={(e) =>
                                                    setCategory({
                                                        ...category,
                                                        description: {
                                                            ...category.description,
                                                            en: e.target.value,
                                                        },
                                                    })
                                                }
                                                placeholder={__(
                                                    "Enter description in English",
                                                )}
                                                id="descriptionEn"
                                                className="input"
                                                rows={3}
                                            />
                                            <span className="input-text">
                                                {__("Description")} (
                                                {__("English")})
                                            </span>
                                        </label>
                                    </div> */}
                                </div>
                            </div>
                           {/* <div className="component-box-card">
                                <div className="card-header">
                                    <div className="card-info">
                                        <div className="card-icon">
                                            <i className="icon-information" />
                                        </div>
                                        <div className="card-text">
                                            <p className="card-title">
                                                {__("Category Settings")}
                                            </p>
                                            <p className="card-subtitle">
                                                {__("Legal Address")}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="input-item">
                                    <div className="component-input">
                                        <label
                                            className="input-label"
                                            htmlFor="BusinessType"
                                        >
                                            <span className="input-text">
                                                {__("Parent category")}
                                            </span>

                                            <SelectComponent
                                                options={categories}
                                                onChange={(e) =>
                                                    setCategory({
                                                        ...category,
                                                        parentCategory: e.value,
                                                    })
                                                }
                                            />
                                        </label>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div className="component box-save">
                        <div className="not-save-item">
                            <div className="not-save-icon">
                                <i className="icon-not-save" />
                            </div>
                            <div className="not-save-text">
                                <p className="save-text">
                                    {__("Not saved yet")}
                                </p>
                            </div>
                        </div>
                        <div className="not-save-button">
                            <Button
                                className="btn outline sm"
                                text={__("Cancel")}
                                onClick={onCancelClick}
                                type="button"
                            />
                            <Button
                                className="btn sm"
                                text={__("Save")}
                                type="submit"
                                disabled={isSubmitting}
                            />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default PageCategoryCreate;
