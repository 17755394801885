import React, { useState } from "react";
import { useSelector } from "react-redux";
import Input from "../../../components/LayoutBasic/Input";
import Button from "../../../components/global/Button";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { translate } from "../../../translations/translate";
import Header from "../../../components/LayoutBasic/Header";
import "./styles.sass";
import CardVerificationInput from "../../../components/LayoutBasic/CardVerificationInput";
import { ApiService } from "../../../utils/ApiService";
import { successToast, errorToast } from "../../../utils/toast";

const PageNewPassword = ({ ...props }) => {
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [otp, setOtp] = useState(new Array(6).fill(""));
    const [filled, setFilled] = useState(0);

    // USE LOCATION
    const { state } = useLocation();

    // USE NAVIGATE
    const navigate = useNavigate();

    const [isError, setError] = useState(false);
    const lang = useSelector(state => state.languageReducer.lang);
    const __ = key => translate(lang, key);

    const validatePassword = (password) => {
        if (password.length < 8) {
            errorToast(__("Password should be at least 8 characters long."));
            return false;
        }
        if (!/\d/.test(password)) {
            errorToast(__("Password should contain at least one numeric character."));
            return false;
        }
        if (!/[A-Za-z]/.test(password)) {
            errorToast(__("Password should contain at least one alphabetic character."));
            return false;
        }
        return true;
    };

    const handlePassword = (event) => {
        setPassword(event.target.value);
    };

    const handleConfirmPassword = (event) => {
        setConfirmPassword(event.target.value);
    };

    const resetPassword = (event) => {
        event.preventDefault();

        // Validate the password
        if (!validatePassword(password)) {
            // Password is not valid, display an error message to the user.
            return;
        }

        if (password !== confirmPassword) {
            setError(true);
        } else {
            setError(false);
            const body = {
                phone: state.phone.replace(/[^+\d]/g, ""),
                verificationToken: state.verificationToken,
                otp: otp.join(""),
                repeatedNewPassword: confirmPassword,
                newPassword: password,
            };
            ApiService.patchEvent(`/${state.role}/password/recover`, null, body)
                .then(response => {
                    if(response.status === 200) {
                        successToast(__("New password was successfully applied"));
                        navigate("/auth");
                    }
                });
        }
    };

    return (
        <div {...props} className="page page-authorization-new-password">
            <Header />
            <div className="component-card-new-password">
                <div className="card-inner">
                    <h1 className="component-heading">{__("Create New Password")}</h1>
                    <div className="component-card-text">
                        <p className="component-text">{__("Create To Continue")}</p>
                    </div>
                    <form className="card-form" action="">
                        <div className="inputs-block">
                            <Input
                                className={isError ? "input-error" : "card-input"}
                                htmlFor="userPassword"
                                id="userPassword"
                                type="password"
                                placeholder={__("Password")}
                                onChange={handlePassword}
                            />
                            <Input
                                className={isError ? "input-error" : "card-input"}
                                htmlFor="userPassword"
                                id="userConfirmPassword"
                                type="password"
                                placeholder={__("Confirm Password")}
                                onChange={handleConfirmPassword}
                                text={isError ? __("The entered passwords do not match") : ""}
                            />
                            <div className="component-card-text">
                                <p className="component-text">Please enter the verification code we sent to your phone number</p>
                            </div>
                            <CardVerificationInput
                                otp={otp}
                                setOtp={setOtp}
                                setFilled={setFilled}
                            />
                        </div>
                        <Link to="/auth/newPassword">
                            <Button className="btn" onClick={resetPassword} text={__("Reset Password")} />
                        </Link>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default PageNewPassword;
