import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { translate } from "../../../translations/translate";
import { useLocation, useNavigate, useParams } from "react-router";
import { ApiService } from "../../../utils/ApiService";
import { errorToast, successToast } from "../../../utils/toast";

import Button from "../../../components/global/Button";
import PageHeader from "../../../components/PageHeader";
import BreadCrumbs from "../../../components/BreadCrumbs";
import config from "../../../config";
import useApiHook from "../../../hooks/useApiHook";
import Preloader from "../../../components/Preloader";

import "./styles.sass";
import { StyledEngineProvider } from "@mui/styled-engine-sc";
import BasicDatePicker from "../../../components/DatePicker";
import { formatDate } from "../../../utils/formatDate";
import SelectComponent from "../../../components/Select";
import getStatuses from "../../../utils/globalAPIRequests/getStatuses";
import {capitalize} from "../../../utils/capitalize";

const PageCashDeskEdit = ({ ...props }) => {

    const lang = useSelector((state) => state.languageReducer.lang);
    const __ = (key) => translate(lang, key);

    // CASHDESK TYPES
    const applicationTypes = [
        { label: __("Cashdesk"), value: "cashdesk" },
        { label: __("Communicator"), value: "communicator" },
        { label: __("Mobile"), value: "mobile" },
    ];

    const navigate = useNavigate();
    const [showNotSaved, setShowNotSaved] = useState(true);
    const [expiryDate, setExpiryDate] = useState("");
    const [blockDate, setBlockDate] = useState("");
    const [approved, setApproved] = useState();
    const [status, setStatus] = useState("");
    const [statuses, setStatuses] = useState([]);
    const [cashdeskData, setCashdeskData] = useState({});
    const [applicationType, setApplicationType] = useState("");
    const [fiscalModule , setfiscalModule] = useState("");
    const { state } = useLocation();
    const { id } = useParams();
    const { token, role, profileData: profile} = useSelector((state) => state.authReducer);
    const url = `${config.API_BASE_URL}/cashdesks/${state?.cashdesk?.id || id}`;
    const { data, loading, error } = useApiHook(url);
    const cashdesk = data?.data?.docs?.[0];
    const epsData = cashdesk?.EPS;
    useEffect(() => {
        setExpiryDate(cashdesk?.licence?.expiresAt);
        setBlockDate(cashdesk?.licence?.blockDate);
        setStatus(cashdesk?.status);
        setApproved(cashdesk?.approved?.isApproved);
        setApplicationType(cashdesk?.applicationType);
        setCashdeskData({
            fiscalID: cashdesk?.fiscalModule?.fiscalID,
        });
    }, [data]);

    const statusesAsync = getStatuses("cashdesk");
    useEffect(() => {
        if (statusesAsync?.length) {
            setStatuses(statusesAsync);
        }
    }, [statusesAsync?.length]);

    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm();

    const onCancelClick = function () {
        navigate("/cashdesks");
    };

    const onSubmit = (e) => {
        const body = {
            licence: {
                expiresAt: expiryDate ? formatDate(expiryDate) : "",
                blockDate: blockDate ? formatDate(blockDate) : "",
            },
            fiscalModule,
            status,
            applicationType,
            // approved: {
            //     isApproved: approved,
            // },
        };
        ApiService.patchEvent(`/cashdesks/${cashdesk?._id || id}`, token, body)
            .then((response) => {
                if (response.status === 200) {
                    successToast(__("Successfully updated"));
                    navigate(`/cashdesks/${state?.cashdesk?._id || id}`);
                }
            })
            .catch((error) => {
                errorToast(__(error?.message));
            });
    };

    const BusinessName = [
        { value: "active", label: __("Active") },
        { value: "blocked", label: __("Blocked") },
        { value: "approved", label: __("Approved") },
    ];
    const buttons = [
        {
            name: "Back",
            link: "/cashdesks",
            className: "outline",
        },
        {
            name: "EPS",
            link: `/cashdesks/${cashdesk?._id || id
                }/eps`,
            state: {
                cashdesk: {
                    uniqueID: cashdesk?.uniqueID,
                    _id: cashdesk?._id,
                },
                eps: epsData,
            },
            className: "outline",
        },
        ...(!approved
            ? [
                {
                    name: __("Approve"),
                    state: {},
                    onClick: (e) => {
                        e.preventDefault();
                        ApiService.patchEvent(`/cashdesks/${cashdesk?._id || state?.cashdesk?.id || id}`, token, {
                            approved: {
                                isApproved: true,
                                date: new Date(),
                                user: profile._id,
                            }
                        })
                            .then((response) => {
                                if (response.status === 200) {
                                    successToast(__("Successfully updated"));
                                }
                            })
                            .catch((error) => {
                                errorToast(__(error?.message));
                            });
                    },
                },
            ]
            : []),
    ];
    if (loading)
        return (
            <div>
                <Preloader />
            </div>
        );
    if (error) return <div>{error}</div>;
    return (
        <div>
            <BreadCrumbs
                breadcrumbs={[
                    { label: "Cashdesks", link: "/cashdesks" },
                    { label: "Edit" },
                ]}
            />
            <div {...props} className="page component-page-cashdesk-edit">
                <form onSubmit={handleSubmit(onSubmit)} className="page-inner">
                    <PageHeader
                        name={`${__("Edit Cashdesk")} ${cashdesk?.uniqueID ? `"${cashdesk?.uniqueID}"` : ""}`}
                        buttons={buttons}
                    />
                    <div className="cards">
                        <div className="left-side-cards">
                            <div className="component-box-card">
                                <div className="card-header">
                                    <div className="card-info">
                                        <div className="card-icon">
                                            <i className="icon-information" />
                                        </div>
                                        <div className="card-text">
                                            <p className="card-title">
                                                {__("Cashdesk Information")}
                                            </p>
                                            <p className="card-subtitle" />
                                        </div>
                                    </div>
                                </div>
                                <div className="input-item">
                                    <div className="component-input">
                                        <label
                                            className="input-label"
                                        >
                                            <input
                                                defaultValue={`${cashdesk?.businessID
                                                    ?.name || "-"
                                                    }`}
                                                className={`input ${role === "client" &&
                                                    "input-disabled"
                                                    }`}
                                                type="text"
                                                disabled
                                            />
                                            <span className="input-text">
                                                {__("Business")}
                                            </span>
                                        </label>
                                    </div>
                                    <div className="component-input">
                                        <label
                                            className="input-label"
                                        >
                                            <input
                                                defaultValue={`${cashdesk?.branchID
                                                    ?.name || "-"
                                                    }`}
                                                className={`input ${role === "client" &&
                                                    "input-disabled"
                                                    }`}
                                                type="text"
                                                disabled
                                            />
                                            <span className="input-text">
                                                {__("Branch")}
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                <div className="input-item">
                                    <div className="component-input">
                                        <label
                                            className="input-label"
                                        >
                                            <input
                                                defaultValue={`${cashdesk?.fiscalModule
                                                    ?.fiscalID || "-"
                                                    }`}
                                                onChange={(e)=>setfiscalModule(e.target.value)}
                                                className={`input ${role === "client" &&
                                                    "input-disabled"
                                                    }`}
                                                type="text"
                                            />
                                            <span className="input-text">
                                                {__("Fiscal ID")}
                                            </span>
                                        </label>
                                    </div>
                                    <div className="component-input">
                                        <label className="input-label">
                                            <span className="input-text">{__("Type")}</span>
                                            { role === "client" ? (
                                              <input
                                                defaultValue={capitalize(applicationType)}
                                                className="input input-disabled"
                                                type="text"
                                                disabled
                                              />
                                            ) : (
                                            <SelectComponent
                                                options={applicationTypes}
                                                onChange={(e) => setApplicationType(e.value)}
                                                name="applicationType"
                                                value={applicationTypes.find((type) => type.value === applicationType)}
                                              />
                                            )}
                                        </label>
                                    </div>
                                </div>
                                <div className="input-item">
                                    <div className="component-input">
                                        <label
                                          className="input-label"
                                        >
                                            <input
                                              defaultValue={`${cashdesk?.uniqueID}`}
                                              className={`input ${role === "client" &&
                                              "input-disabled"
                                              }`}
                                              type="text"
                                              disabled
                                            />
                                            <span className="input-text">
                                                {__("Cashdesk ID")}
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="component-box-card">
                                <div className="card-header">
                                    <div className="card-info">
                                        <div className="card-icon">
                                            <i className="icon-information" />
                                        </div>
                                        <div className="card-text">
                                            <p className="card-title">
                                                {__("Cashdesk License")}
                                            </p>
                                            <p className="card-subtitle" />
                                        </div>
                                    </div>
                                </div>
                                <div className="input-item">
                                    <div className="component-input">
                                        <label
                                            className="input-label"
                                        >
                                            <span className="input-text date-picker">
                                                {__(
                                                    "Date of Approval",
                                                )}
                                            </span>
                                            <input
                                                defaultValue={`${cashdesk?.approved?.approvalDate
                                                    ? formatDate(cashdesk?.approved?.approvalDate)
                                                    : "-"}`}
                                                className={`input ${role === "client" &&
                                                    "input-disabled"
                                                    }`}
                                                type="text"
                                                disabled
                                            />
                                        </label>
                                    </div>
                                    <div className="component-input">
                                        <label
                                            className="input-label"
                                        >
                                            <span className="input-text date-picker">
                                                {__(
                                                    "Expiry Date",
                                                )}
                                            </span>
                                            { role === "client" ? (
                                              <input
                                                defaultValue={expiryDate ? formatDate(expiryDate) : ""}
                                                className="input input-disabled"
                                                type="text"
                                                disabled
                                              />
                                            ) : (
                                              <StyledEngineProvider>
                                                  <BasicDatePicker
                                                    value={expiryDate || ""}
                                                    selectedDate={expiryDate}
                                                    setSelectedDate={expiryDate}
                                                    onChange={(e) => setExpiryDate(e?.$d)}
                                                  />
                                              </StyledEngineProvider>
                                            )}
                                        </label>
                                    </div>
                                </div>
                                <div className="input-item">
                                    <div className="component-input">
                                        <label
                                            className="input-label"
                                        >
                                            <span className="input-text date-picker">
                                                {__(
                                                    "Block Date",
                                                )}
                                            </span>
                                            { role === "client" ? (
                                              <input
                                                defaultValue={blockDate ? formatDate(blockDate) : ""}
                                                className="input input-disabled"
                                                type="text"
                                                disabled
                                              />
                                            ) : (
                                              <StyledEngineProvider>
                                                  <BasicDatePicker
                                                    value={blockDate || ""}
                                                    selectedDate={blockDate}
                                                    setSelectedDate={setBlockDate}
                                                    onChange={(e) => setBlockDate(e?.$d)}
                                                  />
                                              </StyledEngineProvider>
                                            )}
                                        </label>
                                    </div>
                                </div>
                                <div className="input-item">
                                    <div className="component-input">
                                        <label
                                            className="input-label"
                                        >
                                            <span className="input-text">
                                                {__("Status")}
                                            </span>
                                            { role === "client" ? (
                                              <input
                                                defaultValue={capitalize(status)}
                                                className="input input-disabled"
                                                type="text"
                                                disabled
                                              />
                                            ) : (
                                              <SelectComponent
                                                options={statuses}
                                                onChange={(e) => setStatus(e.value)}
                                                value={statuses.find((item) => item.value === status)}
                                                name="status"
                                              />
                                            )}
                                        </label>
                                    </div>
                                    <div className="component-input">
                                        <label
                                            className="input-label"
                                        >
                                            <input
                                                defaultValue={`${cashdesk?.approved?.isApproved ? "Approved" : "Not Approved"}`}
                                                className={`input ${role === "client" &&
                                                    "input-disabled"
                                                    }`}
                                                type="text"
                                                disabled
                                            />
                                            <span className="input-text">
                                                {__("Approved")}
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="component box-save">
                        <div className="not-save-item">
                            <div className="not-save-icon">
                                <i className="icon-saved" />
                            </div>
                            <div className="not-save-text">
                                <p className="save-text">
                                    {__("Not Saved Yet")}
                                </p>
                            </div>
                        </div>
                        <div className="not-save-button">
                            <Button
                                className="btn outline sm"
                                text={__("Cancel")}
                                onClick={onCancelClick}
                                type="button"
                            />
                            <Button
                                className="btn sm"
                                text={__("Save")}
                                type="submit"
                            />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default PageCashDeskEdit;
