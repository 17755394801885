import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { NumericFormat } from "react-number-format";

import Button from "../../../components/global/Button";
import PageHeader from "../../../components/PageHeader";
import BreadCrumbs from "../../../components/BreadCrumbs";
import useApiHook from "../../../hooks/useApiHook";
import Preloader from "../../../components/Preloader";
import SelectComponent from "../../../components/Select";
import BasicDatePicker from "../../../components/DatePicker";
import StatusTag from "../../../components/StatusTag";
import { StyledEngineProvider } from "@mui/styled-engine-sc";

import { translate } from "../../../translations/translate";
import { ApiService } from "../../../utils/ApiService";
import { formatDate } from "../../../utils/formatDate";
import { errorToast, successToast } from "../../../utils/toast";
import getStatuses from "../../../utils/globalAPIRequests/getStatuses";
import {capitalize} from "../../../utils/capitalize";
import config from "../../../config";
import "./styles.sass";
import FieldContainer from "../../../components/FieldContainer";
import { months } from "../../../utils/consts";

const PageCashDeskEdit = () => {
    const lang = useSelector((state) => state.languageReducer.lang);
    const __ = (key) => translate(lang, key);

    // CASHDESK TYPES
    const applicationTypes = [
        { label: __("Cashdesk"), value: "cashdesk" },
        { label: __("Communicator"), value: "communicator" },
        { label: __("Mobile"), value: "mobile" },
    ];

    const navigate = useNavigate();
    const [expiryDate, setExpiryDate] = useState("");
    const [blockDate, setBlockDate] = useState("");
    const [_, setApproved] = useState();
    const [status, setStatus] = useState("");
    const [statuses, setStatuses] = useState([]);
    const [applicationType, setApplicationType] = useState("");
    const [fiscalModule , setFiscalModule] = useState("");
    const [monthlyPayment, setMonthlyPayment] = useState(0);
    const [purchasePrice, setPurchasePrice] = useState(0);
    const [payments, setPayments] = useState([]);
    const { state } = useLocation();
    const { id } = useParams();
    const { token, role } = useSelector((state) => state.authReducer);
    const url = `${config.API_BASE_URL}/cashdesks/${state?.cashdesk?.id || id}`;
    const { data, loading, error } = useApiHook(url);
    const paymentsUrl = `${config.API_BASE_URL}/cashdesks/payments/${state?.cashdesk?.id || id}`;
    const {data: paymentsData} = useApiHook(paymentsUrl);
    const cashdesk = data?.data?.docs?.[0];
    const epsData = cashdesk?.EPS;

    useEffect(() => {
        setExpiryDate(cashdesk?.licence?.expiresAt);
        setBlockDate(cashdesk?.licence?.blockDate);
        setStatus(cashdesk?.status);
        setApproved(cashdesk?.approved?.isApproved);
        setApplicationType(cashdesk?.applicationType);
        setMonthlyPayment(cashdesk?.monthlyPayment);
        setPurchasePrice(cashdesk?.purchasePrice);
        setPayments(paymentsData?.data);
    }, [data]);

    const statusesAsync = getStatuses("cashdesk");

    useEffect(() => {
        if (statusesAsync?.length) {
            setStatuses(statusesAsync);
        }
    }, [statusesAsync?.length]);

    const onCancelClick = function () {
        navigate("/cashdesks");
    };

    const onPaymentChange = (value, id) => {
        const newPayments = payments.map(payment => {
            if(payment._id === id) {
                return {
                    ...payment,
                    isPaid: value
                };
            }

            return payment;
        });

        setPayments(newPayments);
    };

    const onSubmit = async (e) => {
        e.preventDefault();

        const body = {
            licence: {
                expiresAt: expiryDate ? formatDate(expiryDate) : "",
                blockDate: blockDate ? formatDate(blockDate) : "",
            },
            fiscalModule,
            status,
            applicationType,
            monthlyPayment,
            purchasePrice
        };
        try {
            // First request to update cashdesk
            const response = await ApiService.patchEvent(`/cashdesks/${cashdesk?._id || id}`, token, body);

            if (response.status === 200) {
                successToast(__("Successfully updated"));

                // Second request to update payments
                await ApiService.patchEvent(`/cashdesks/payments/${cashdesk?._id || id}`, token, { payments });

                navigate(`/cashdesks/${state?.cashdesk?._id || id}`);
            }
        } catch (error) {
            errorToast(__(error?.message));
        }
    };

    const paymentStatuses = [
        { value: true, label: "Paid" },
        { value: false, label: "Not Paid" },
    ];

    const buttons = [
        {
            name: "Back",
            link: "/cashdesks",
            className: "outline",
        },
        {
            name: "EPS",
            link: `/cashdesks/${cashdesk?._id || id
                }/eps`,
            state: {
                cashdesk: {
                    uniqueID: cashdesk?.uniqueID,
                    _id: cashdesk?._id,
                },
                eps: epsData,
            },
            className: "outline",
        }
    ];

    if (loading)
        return (
            <div>
                <Preloader />
            </div>
        );

    if (error) return <div>{error}</div>;

    return (
        <div>
            <BreadCrumbs
                breadcrumbs={[
                    { label: "Cashdesks", link: "/cashdesks" },
                    { label: "Edit" },
                ]}
            />
            <div className="page component-page-cashdesk-edit">
                <form onSubmit={onSubmit} className="page-inner">
                    <PageHeader
                      name={`${__("Edit Cashdesk")} ${cashdesk?.uniqueID ? `"${cashdesk?.uniqueID}"` : ""}`}
                      buttons={buttons}
                    />
                    <div className="cards">
                        <div className="left-side-cards">
                            <div className="component-box-card">
                                <div className="card-header">
                                    <div className="card-info">
                                        <div className="card-icon">
                                            <i className="icon-information" />
                                        </div>
                                        <div className="card-text">
                                            <p className="card-title">
                                                {__("Cashdesk Information")}
                                            </p>
                                            <p className="card-subtitle" />
                                        </div>
                                    </div>
                                </div>
                                <div className="input-item">
                                    <div className="component-input">
                                        <label
                                          className="input-label"
                                        >
                                            <input
                                              defaultValue={`${cashdesk?.businessID
                                                ?.name || "-"
                                              }`}
                                              className={`input ${role === "client" &&
                                              "input-disabled"
                                              }`}
                                              type="text"
                                              disabled
                                            />
                                            <span className="input-text">
                                                {__("Business")}
                                            </span>
                                        </label>
                                    </div>
                                    <div className="component-input">
                                        <label
                                          className="input-label"
                                        >
                                            <input
                                              defaultValue={`${cashdesk?.branchID
                                                ?.name || "-"
                                              }`}
                                              className={`input ${role === "client" &&
                                              "input-disabled"
                                              }`}
                                              type="text"
                                              disabled
                                            />
                                            <span className="input-text">
                                                {__("Branch")}
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                <div className="input-item">
                                    <div className="component-input">
                                        <label
                                          className="input-label"
                                        >
                                            <input
                                              defaultValue={`${cashdesk?.fiscalModule
                                                ?.fiscalID || "-"
                                              }`}
                                              onChange={(e) => setFiscalModule(e.target.value)}
                                              className={`input ${role === "client" &&
                                              "input-disabled"
                                              }`}
                                              type="text"
                                            />
                                            <span className="input-text">
                                                {__("Fiscal ID")}
                                            </span>
                                        </label>
                                    </div>
                                    <div className="component-input">
                                        <label className="input-label">
                                            <span className="input-text">{__("Type")}</span>
                                            {role === "client" ? (
                                              <input
                                                defaultValue={capitalize(applicationType)}
                                                className="input input-disabled"
                                                type="text"
                                                disabled
                                              />
                                            ) : (
                                              <SelectComponent
                                                options={applicationTypes}
                                                onChange={(e) => setApplicationType(e.value)}
                                                name="applicationType"
                                                value={applicationTypes.find((type) => type.value === applicationType)}
                                              />
                                            )}
                                        </label>
                                    </div>
                                </div>
                                <div className="input-item">
                                    <div className="component-input">
                                        <label
                                          className="input-label"
                                        >
                                            <input
                                              defaultValue={`${cashdesk?.uniqueID}`}
                                              className={`input ${role === "client" &&
                                              "input-disabled"
                                              }`}
                                              type="text"
                                              disabled
                                            />
                                            <span className="input-text">
                                                {__("Cashdesk ID")}
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="component-box-card">
                                <div className="card-header">
                                    <div className="card-info">
                                        <div className="card-icon">
                                            <i className="icon-information" />
                                        </div>
                                        <div className="card-text">
                                            <p className="card-title">
                                                {__("Cashdesk License")}
                                            </p>
                                            <p className="card-subtitle" />
                                        </div>
                                    </div>
                                </div>
                                <div className="input-item">
                                    <div className="component-input">
                                        <label
                                          className="input-label"
                                        >
                                            <span className="input-text date-picker">
                                                {__(
                                                  "Date of Approval",
                                                )}
                                            </span>
                                            <input
                                              defaultValue={`${cashdesk?.approved?.approvalDate
                                                ? formatDate(cashdesk?.approved?.approvalDate)
                                                : "-"}`}
                                              className={`input ${role === "client" &&
                                              "input-disabled"
                                              }`}
                                              type="text"
                                              disabled
                                            />
                                        </label>
                                    </div>
                                    <div className="component-input">
                                        <label
                                          className="input-label"
                                        >
                                            <span className="input-text date-picker">
                                                {__(
                                                  "Expiry Date",
                                                )}
                                            </span>
                                            {role === "client" ? (
                                              <input
                                                defaultValue={expiryDate ? formatDate(expiryDate) : ""}
                                                className="input input-disabled"
                                                type="text"
                                                disabled
                                              />
                                            ) : (
                                              <StyledEngineProvider>
                                                  <BasicDatePicker
                                                    value={expiryDate}
                                                    selectedDate={expiryDate}
                                                    setSelectedDate={expiryDate}
                                                    onChange={(e) => setExpiryDate(e?.$d)}
                                                  />
                                              </StyledEngineProvider>
                                            )}
                                        </label>
                                    </div>
                                </div>
                                <div className="input-item">
                                    <div className="component-input">
                                        <label
                                          className="input-label"
                                        >
                                            <span className="input-text date-picker">
                                                {__(
                                                  "Block Date",
                                                )}
                                            </span>
                                            {role === "client" ? (
                                              <input
                                                defaultValue={blockDate ? formatDate(blockDate) : ""}
                                                className="input input-disabled"
                                                type="text"
                                                disabled
                                              />
                                            ) : (
                                              <StyledEngineProvider>
                                                  <BasicDatePicker
                                                    value={blockDate}
                                                    selectedDate={blockDate}
                                                    setSelectedDate={setBlockDate}
                                                    onChange={(e) => setBlockDate(e?.$d)}
                                                  />
                                              </StyledEngineProvider>
                                            )}
                                        </label>
                                    </div>
                                </div>
                                <div className="input-item">
                                    <div className="component-input">
                                        <label
                                          className="input-label"
                                        >
                                            <span className="input-text">
                                                {__("Status")}
                                            </span>
                                            {role === "client" ? (
                                              <input
                                                defaultValue={capitalize(status)}
                                                className="input input-disabled"
                                                type="text"
                                                disabled
                                              />
                                            ) : (
                                              <SelectComponent
                                                options={statuses}
                                                onChange={(e) => setStatus(e.value)}
                                                value={statuses.find((item) => item.value === status)}
                                                name="status"
                                              />
                                            )}
                                        </label>
                                    </div>
                                    <div className="component-input">
                                        <label
                                          className="input-label"
                                        >
                                            <input
                                              defaultValue={`${cashdesk?.approved?.isApproved ? "Approved" : "Not Approved"}`}
                                              className={`input ${role === "client" &&
                                              "input-disabled"
                                              }`}
                                              type="text"
                                              disabled
                                            />
                                            <span className="input-text">
                                                {__("Approved")}
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="component-box-card">
                                <div className="card-header">
                                    <div className="card-info">
                                        <div className="card-icon">
                                            <i className="icon-information"></i>
                                        </div>
                                        <div className="card-text">
                                            <p className="card-title">
                                                {__("Payment Information")}
                                            </p>
                                            <p className="card-subtitle"></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-margin">
                                    <label className="input-label">
                                        <NumericFormat
                                          defaultValue={cashdesk?.purchasePrice / 100 || 0}
                                          className="input"
                                          onValueChange={(values) => {
                                              const { value } = values;
                                              setPurchasePrice(+value.replace(".", ""));
                                          }}
                                          placeholder={__(
                                            "Enter Purchase Price",
                                          )}
                                          thousandSeparator={" "}
                                          decimalSeparator={"."}
                                          inputMode={"numeric"}
                                          decimalScale={2}
                                          fixedDecimalScale={true}
                                          disabled={role !== "user"}
                                        />
                                        <span className="input-text">
                                            {__("Purchase Price")}
                                        </span>
                                    </label>
                                </div>
                                <div className="card-margin">
                                    <label className="input-label">
                                        <NumericFormat
                                          className="input"
                                          defaultValue={cashdesk?.monthlyPayment / 100 || 0}
                                          onValueChange={(values) => {
                                              const { value } = values;
                                              setMonthlyPayment(+value.replace(".", ""));
                                          }}
                                          placeholder={__(
                                            "Enter Monthly Subscription Price",
                                          )}
                                          thousandSeparator={" "}
                                          decimalSeparator={"."}
                                          inputMode={"numeric"}
                                          decimalScale={2}
                                          fixedDecimalScale={true}
                                          disabled={role !== "user"}
                                        />
                                        <span className="input-text">
                                            {__("Monthly Subscription Price")}
                                        </span>
                                    </label>
                                </div>
                                <div className="flex-wrap">
                                    {payments?.map(({ isPaid, month, _id }) => (
                                      role === "client" ? (
                                        <FieldContainer
                                          key={_id}
                                          className="card-margin flex-three-col"
                                          title={__(months[month])}
                                        >
                                            <StatusTag text={isPaid ? "paid" : "not paid"} />
                                        </FieldContainer>
                                      ) : (
                                        <div className="card-margin flex-three-col" key={_id}>
                                            <label className="input-label">
                                                <span className="input-text">
                                                    {__(months[month])}
                                                </span>
                                                <SelectComponent
                                                  options={paymentStatuses}
                                                  onChange={(e) => onPaymentChange(e.value, _id)}
                                                  value={paymentStatuses.find((item) => item.value === isPaid)}
                                                  name="status"
                                                />
                                            </label>
                                        </div>
                                      )
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="component box-save">
                        <div className="not-save-item">
                            <div className="not-save-icon">
                                <i className="icon-saved" />
                            </div>
                            <div className="not-save-text">
                                <p className="save-text">
                                    {__("Not Saved Yet")}
                                </p>
                            </div>
                        </div>
                        <div className="not-save-button">
                            <Button
                              className="btn outline sm"
                              text={__("Cancel")}
                              onClick={onCancelClick}
                              type="button"
                            />
                            <Button
                              className="btn sm"
                              text={__("Save")}
                              type="submit"
                            />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default PageCashDeskEdit;
